import { Location } from '@angular/common';
import {
    Component,
    EventEmitter,
    HostListener,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { DialogService } from '@fundamental-ngx/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { BehaviorSubject } from 'rxjs';
import { keyCodeEnum } from '../enums/keyCode.enum';

@Component({
    selector: 'erp-key-event',
    templateUrl: './key-event.component.html',
    styleUrls: ['./key-event.component.css']
})
export class KeyEventComponent extends AppComponentBase implements OnInit, OnDestroy {
    @Input() id: number;
    @Output() closeEvent = new EventEmitter<boolean>();
    @Output() saveEvent = new EventEmitter<boolean>();
    @Output() deletEvent = new EventEmitter<number>();
    @Output() addEvent = new EventEmitter<boolean>();
    @Output() advanceFilter = new EventEmitter<boolean>();
    @ViewChild('keyDialog', { read: TemplateRef })
    dialogContent: TemplateRef<any>;
    dialogTrigger$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(injector: Injector, private _location: Location, private _dialogService: DialogService) {
        super(injector);
    }
    @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
        if (event.escKey || event.keyCode === keyCodeEnum.ESCAPE_KEYCODE) {
            event.preventDefault();
            this.closeEvent.emit(true);
        }
        if (event.altKey && event.keyCode === keyCodeEnum.KEY_S) {
            event.preventDefault();
            this.saveEvent.emit(true);
        }
        if (event.altKey && event.keyCode === keyCodeEnum.KEY_D) {
            event.preventDefault();
            if (this.id !== null) {
                this.deletEvent.emit(this.id);
            } else {
                this.notify.warn('You cannot delete here', 'Denied!!');
            }
        }
        if (event.altKey && event.keyCode === keyCodeEnum.KEY_A) {
            event.preventDefault();
            this.addEvent.emit(true);
        }
        if (event.altKey && event.keyCode === keyCodeEnum.KEY_H) {
            event.preventDefault();
            this.advanceFilter.emit(true);
        }
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.defaultPrevented) {
            return; // Should do nothing if the default action has been cancelled
        }
        let handled = false;
        if (event.key !== undefined) {
            handled = true;
            // Handle the event with KeyboardEvent.key and set handled true.
        } else if (event.code !== undefined) {
            handled = true;
            // Handle the event with KeyboardEvent.keyCode and set handled true.
        }

        if (handled) {
            // Suppress "double action" if event handled
            event.preventDefault();
            if (event.key === ' ' && event.shiftKey) {
                if (this.dialogTrigger$.value === false) {
                    this.openDialog(this.dialogContent);
                } else {
                    this._dialogService.dismissAll();
                    this.dialogTrigger$.next(false);
                }
            }
        }
    }
    ngOnInit(): void {
    }

    openDialog(dialog: TemplateRef<any>): void {
        const dialogRef = this._dialogService.open(dialog, {
            responsivePadding: true,
            ariaLabelledBy: 'fd-dialog-header-10',
            ariaDescribedBy: 'fd-dialog-body-10',
            focusTrapped: true
        });
        const hasOpen = this._dialogService.hasOpenDialogs();
        this.dialogTrigger$.next(hasOpen);
        setTimeout(() => dialogRef.close(), 4000);
    }

    ngOnDestroy(): void {
        this.dialogTrigger$.unsubscribe();
    }
}
