<div>
<div *ngIf="isSimpleHeader; else simpleHead"
     [ngStyle]="{ 'margin-top.px': currentTheme.baseSettings.theme === 'default' ? -51 : 0 }"
     class="suktas-subheader"
>
    <!-- new added code responsive header -->

    <div class="mb-2 mZero subheader-header">
        <div>
            <div class="row align-items-center py-22px">
                <fd-layout-grid class="align-items-center gap-10px suktas-row">
                    <div colLg="5" colMd="4" colXl="5" fdLayoutGridCol="12" id="title">
                        {{ title }}
                    </div>
                    <div colLg="3" colMd="4" colXl="4" fdLayoutGridCol="12">
                        <div class="form-group  align-items-center mb-0">
                            <fd-input-group
                                    (keyup)="searchFilter($event.target.value)"
                                    (keyup.enter)="searchFilerOnEnter($event.target.value)"
                                    [(ngModel)]="filterText"
                                    [button]="true"
                                    [disabled]="false"
                                    fdCompact
                                    [ngModelOptions]="{ standalone: true }"
                                    ariaLabelledby="fd-input-group-search-label-2"
                                    glyph="search"
                                    glyphAriaLabel="Search"
                                    placeholder="Search"
                                    type="search"
                            >
                            </fd-input-group>
                        </div>
                    </div>
                    <div class="justify-content-end" colLg="4" colMd="4" colXl="3" fdLayoutGridCol="12">
                        <button *ngIf="export" [compact]="true" [fdMenuTrigger]="menu" [fdType]="'transparent'"
                                fd-button>
                            <i class="fa-duotone fa-sliders"></i>
                        </button>

                        <fd-split-button *ngIf="isDualTable" [compact]="true">
                            <fd-menu>
                                <li (click)="openSuktasTable()" fd-menu-item>
                                    <div fd-menu-interactive>
                                            <span fd-menu-title>
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <div class="col-8">Suktas Table</div>
                                                        <div class="col-4">
                                                            <i class="bi bi-kanban text_view_"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                    </div>
                                </li>
                                <li (click)="openSimpleTable()" fd-menu-item>
                                    <div fd-menu-interactive>
                                            <span fd-menu-title>
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <div class="col-8">Simple Table</div>
                                                        <div class="col-4">
                                                            <i class="bi bi-list text_view_"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                    </div>
                                </li>
                            </fd-menu>
                        </fd-split-button>
                        <button
                                (click)="routeToAdd()"
                                *ngIf="isAdd"
                                [compact]="true"
                                [fdType]="'emphasized'"
                                [glyph]="'add'"
                                class="ml-2"
                                fd-button
                                label="Add"
                        ></button>
                        <div>
                            <button
                                    (click)="showCards()"
                                    *ngIf="showCard && isDesktop"
                                    [compact]="true"
                                    [fdType]="'emphasized'"
                                    [glyph]="'add'"
                                    class="ml-2"
                                    fd-button
                                    label="Add"
                            ></button>
                        </div>

                        <button
                                (click)="routeToAddMultiple()"
                                *ngIf="isMultiple && isDesktop"
                                [compact]="true"
                                [fdType]="'emphasized'"
                                [glyph]="'add'"
                                class="ml-2"
                                fd-button
                                label="Multiple"
                        ></button>
                        <button
                                (click)="openFilter()"
                                *ngIf="!advancedFiltersAreShown && filter"
                                [fdType]="'transparent'"
                                class="ml-2"
                                fd-button
                                id="filter"
                        >
                            <i class="fa-duotone fa-down mb-3"></i>
                        </button>
                        <button
                                (click)="closeFilter()"
                                *ngIf="advancedFiltersAreShown && filter"
                                [fdType]="'transparent'"
                                class="ml-2"
                                fd-button
                                id="filter"
                        >
                            <i class="fa-duotone fa-up mb-3"></i>
                        </button>
                        <fd-menu #menu (activePath)="activePath = $event" style="left: -150px;">
                            <!-- <li [submenu]="export" fd-menu-item>
                                <div fd-menu-interactive>
                                    <span fd-menu-title>Export</span>
                                    <fd-menu-addon [submenuIndicator]="true"></fd-menu-addon>
                                </div>
                            </li>
                            <li [submenu]="excelOperations" fd-menu-item>
                                <div fd-menu-interactive>
                                    <span fd-menu-title>Excel Operations</span>
                                    <fd-menu-addon [submenuIndicator]="true"></fd-menu-addon>
                                </div>
                            </li> -->

                            <!-- <li [submenu]="dataGrid" fd-menu-item>
                                <div fd-menu-interactive>
                                    <span fd-menu-title>Data-grid</span>
                                    <fd-menu-addon [submenuIndicator]="true"></fd-menu-addon>
                                </div>
                            </li> -->
                            <li fd-menu-item style="border-bottom: 1px solid black;">
                                <a (click)="exportToExcel($event)" fd-menu-interactive>
                                    <span fd-menu-title>Export To Excel</span>
                                </a>
                            </li>
                            <li fd-menu-item>
                                <a *ngIf="'Pages.Administration.Users.Create' | permission" href="javascript:"
                                   fd-menu-interactive>
                                        <span>
                                            <p-fileUpload
                                                #ExcelFileUpload
                                                (onError)="onUploadExcelError()"
                                                (uploadHandler)="importToExcelOperations($event)"
                                                accept=".csv,.xls,.xlsx"
                                                auto="auto"
                                                chooseLabel="{{ 'Import From Excel' | localize }}"
                                                customUpload="true"
                                                maxFileSize="10000000"
                                                name="ExcelFileUpload"
                                            >
                                            </p-fileUpload>
                                        </span>
                                </a>
                            </li>
                        </fd-menu>

                        <fd-submenu #export>
                            <li fd-menu-item>
                                <a (click)="exportToExcel($event)" fd-menu-interactive>
                                    <span fd-menu-title>Excel</span>
                                </a>
                            </li>

                            <li fd-menu-item>
                                <a (click)="getPdf($event)" fd-menu-interactive>
                                    <span fd-menu-title>Pdf</span>
                                </a>
                            </li>
                        </fd-submenu>

                        <fd-submenu #excelOperations>
                            <li fd-menu-item>
                                <a (click)="exportToExcelOperations($event)" fd-menu-interactive>
                                    <span fd-menu-title>Export</span>
                                </a>
                                <a *ngIf="'Pages.Administration.Users.Create' | permission" class="no-padding"
                                   href="javascript:">
                                        <span class="fileinput-button">
                                            <p-fileUpload
                                                #ExcelFileUpload
                                                (onError)="onUploadExcelError()"
                                                (uploadHandler)="importToExcelOperations($event)"
                                                accept=".csv,.xls,.xlsx"
                                                auto="auto"
                                                chooseLabel="{{ 'Import' | localize }}"
                                                customUpload="true"
                                                maxFileSize="10000000"
                                                name="ExcelFileUpload"
                                            >
                                            </p-fileUpload>
                                        </span>
                                </a>
                            </li>
                        </fd-submenu>

                        <fd-submenu #dataGrid>
                            <li fd-menu-item>
                                <a fd-menu-interactive href="#">
                                    <span fd-menu-title>Customize</span>
                                </a>
                            </li>

                            <li fd-menu-item>
                                <a fd-menu-interactive href="#">
                                    <span fd-menu-title>Delete</span>
                                </a>
                            </li>
                        </fd-submenu>
                    </div>
                </fd-layout-grid>
            </div>
        </div>
    </div>
</div>

<ng-template #simpleHead>
    <!-- new added code responsive header -->
    <div [hidden]="isSimpleHeader" class="subheader-header mZero">
        <div class="row align-items-center py-22px">
            <fd-layout-grid class="align-items-center gap-10px">
                <div colLg="8" colMd="8" colXl="8" fdLayoutGridCol="12" id="title">
                    {{ description }}
                </div>
                <div class="d-flex flexH-end" colLg="4" colMd="4" colXl="4" fdLayoutGridCol="12">
                    <div>
                        <ng-content select="div[role=actions]"></ng-content>
                    </div>
                </div>
            </fd-layout-grid>
        </div>


    </div>
</ng-template>
</div>
<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>


