import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppLocalizationService} from '@app/shared/common/localization/app-localization.service';
import {AppNavigationService} from '@app/shared/layout/nav/app-navigation.service';
import {ERPCommonModule} from '@shared/common/common.module';
import {UtilsModule} from '@shared/utils/utils.module';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {BsDatepickerConfig, BsDatepickerModule, BsDaterangepickerConfig} from 'ngx-bootstrap/datepicker';
import {PaginatorModule} from 'primeng/paginator';
import {TableModule} from 'primeng/table';
import {AppAuthService} from './auth/app-auth.service';
import {AppRouteGuard} from './auth/auth-route-guard';
import {CommonLookupModalComponent} from './lookup/common-lookup-modal.component';
import {EntityTypeHistoryModalComponent} from './entityHistory/entity-type-history-modal.component';
import {EntityChangeDetailModalComponent} from './entityHistory/entity-change-detail-modal.component';
import {DateRangePickerInitialValueSetterDirective} from './timing/date-range-picker-initial-value.directive';
import {DatePickerInitialValueSetterDirective} from './timing/date-picker-initial-value.directive';
import {DateTimeService} from './timing/date-time.service';
import {TimeZoneComboComponent} from './timing/timezone-combo.component';
import {CustomizableDashboardComponent} from './customizable-dashboard/customizable-dashboard.component';
import {
    WidgetGeneralStatsComponent
} from './customizable-dashboard/widgets/widget-general-stats/widget-general-stats.component';
import {DashboardViewConfigurationService} from './customizable-dashboard/dashboard-view-configuration.service';
import {GridsterModule} from 'angular-gridster2';
import {
    WidgetDailySalesComponent
} from './customizable-dashboard/widgets/widget-daily-sales/widget-daily-sales.component';
import {
    WidgetEditionStatisticsComponent
} from './customizable-dashboard/widgets/widget-edition-statistics/widget-edition-statistics.component';
import {
    WidgetHostTopStatsComponent
} from './customizable-dashboard/widgets/widget-host-top-stats/widget-host-top-stats.component';
import {
    WidgetIncomeStatisticsComponent
} from './customizable-dashboard/widgets/widget-income-statistics/widget-income-statistics.component';
import {
    WidgetMemberActivityComponent
} from './customizable-dashboard/widgets/widget-member-activity/widget-member-activity.component';
import {
    WidgetProfitShareComponent
} from './customizable-dashboard/widgets/widget-profit-share/widget-profit-share.component';
import {
    WidgetRecentTenantsComponent
} from './customizable-dashboard/widgets/widget-recent-tenants/widget-recent-tenants.component';
import {
    WidgetRegionalStatsComponent
} from './customizable-dashboard/widgets/widget-regional-stats/widget-regional-stats.component';
import {
    WidgetSalesSummaryComponent
} from './customizable-dashboard/widgets/widget-sales-summary/widget-sales-summary.component';
import {
    WidgetSubscriptionExpiringTenantsComponent
} from './customizable-dashboard/widgets/widget-subscription-expiring-tenants/widget-subscription-expiring-tenants.component';
import {WidgetTopStatsComponent} from './customizable-dashboard/widgets/widget-top-stats/widget-top-stats.component';
import {
    FilterDateRangePickerComponent
} from './customizable-dashboard/filters/filter-date-range-picker/filter-date-range-picker.component';
import {AddWidgetModalComponent} from './customizable-dashboard/add-widget-modal/add-widget-modal.component';
import {AreaChartModule, BarChartModule, LineChartModule, NgxChartsModule, PieChartModule} from '@swimlane/ngx-charts';
import {NgxBootstrapDatePickerConfigService} from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {CountoModule} from 'angular2-counto';
import {AppBsModalModule} from '@shared/common/appBsModal/app-bs-modal.module';
import {
    SingleLineStringInputTypeComponent
} from './input-types/single-line-string-input-type/single-line-string-input-type.component';
import {ComboboxInputTypeComponent} from './input-types/combobox-input-type/combobox-input-type.component';
import {CheckboxInputTypeComponent} from './input-types/checkbox-input-type/checkbox-input-type.component';
import {
    MultipleSelectComboboxInputTypeComponent
} from './input-types/multiple-select-combobox-input-type/multiple-select-combobox-input-type.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {
    PasswordInputWithShowButtonComponent
} from './password-input-with-show-button/password-input-with-show-button.component';
import {KeyValueListManagerComponent} from './key-value-list-manager/key-value-list-manager.component';
// import {SubHeaderComponent} from '@app/shared/common/sub-header/sub-header.component';
import {ActionBarModule, ButtonModule, IconModule} from '@fundamental-ngx/core';
import {WidgetsInterestComponent} from './customizable-dashboard/widgets/widgets-interest/widgets-interest.component';
import {PdfViewerComponent} from './pdf-viewer/pdf-viewer.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {
    WidgetTodayPaymentComponent
} from './customizable-dashboard/widgets/widget-today-payment/widget-today-payment.component';
import {
    WidgetInventoryValueComponent
} from './customizable-dashboard/widgets/widget-inventory-value/widget-inventory-value.component';
import {
    WidgetOpportunitiesInleadStatusComponent
} from './customizable-dashboard/widgets/widget-opportunities-inlead-status/widget-opportunities-inlead-status.component';
import {
    WidgetPurchaseSummaryComponent
} from './customizable-dashboard/widgets/widget-purchase-summary/widget-purchase-summary.component';
import {
    WidgetTodayReceiveComponent
} from './customizable-dashboard/widgets/widget-today-receive/widget-today-receive.component';
import {
    WidgetCompanyFinanceComponent
} from './customizable-dashboard/widgets/widget-company-finance/widget-company-finance.component';
import {
    WidgetServicePendingComponent
} from './customizable-dashboard/widgets/widget-service-pending/widget-service-pending.component';
import {
    WidgetStockMomentComponent
} from './customizable-dashboard/widgets/widget-stock-moment/widget-stock-moment.component';
import {
    WidgetSoftwareExpiredComponent
} from './customizable-dashboard/widgets/widget-software-expired/widget-software-expired.component';
import {
    WidgetMeetingScheduleComponent
} from './customizable-dashboard/widgets/widget-meeting-schedule/widget-meeting-schedule.component';
import {
    WidgetTodayReceiptComponent
} from './customizable-dashboard/widgets/widget-today-receipt/widget-today-receipt.component';
import {
    WidgetTopFiveSalesComponent
} from './customizable-dashboard/widgets/widget-top-five-sales/widget-top-five-sales.component';
import {SharedModuleModule} from '@app/shared-module/shared-module.module';
import {UrlService} from './url.service';
import {
    SapDateRangePickerComponent
} from './customizable-dashboard/filters/sap-date-range-picker/sap-date-range-picker.component';
import {KeyEventComponent} from './key-event/key-event.component';
import {PaginatorComponent} from './paginator/paginator.component';
import {FileUploadModule} from 'primeng/fileupload';
import {UploaderExpandComponent} from './uploader-expand/uploader-expand.component';
import {
    WidgetTodayServiceComponent
} from './customizable-dashboard/widgets/widget-today-service/widget-today-service.component';
import {
    WidgetTodayDeliverynoteComponent
} from './customizable-dashboard/widgets/widget-today-deliverynote/widget-today-deliverynote.component';
import {WidgetTodayTaskComponent} from './customizable-dashboard/widgets/widget-today-task/widget-today-task.component';
import { SubheaderModule } from './sub-header/subheader.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        ERPCommonModule,
        NgxChartsModule,
        TableModule,
        PaginatorModule,
        GridsterModule,
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        PieChartModule,
        AreaChartModule,
        LineChartModule,
        BarChartModule,
        BsDatepickerModule.forRoot(),
        PerfectScrollbarModule,
        CountoModule,
        AppBsModalModule,
        AutoCompleteModule,
        ButtonModule,
        ActionBarModule,
        IconModule,
        NgxExtendedPdfViewerModule,
        SharedModuleModule,
        FileUploadModule,
        SubheaderModule

    ],
    declarations: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        UploaderExpandComponent,
        CustomizableDashboardComponent,
        WidgetGeneralStatsComponent,
        WidgetDailySalesComponent,
        WidgetEditionStatisticsComponent,
        WidgetHostTopStatsComponent,
        WidgetIncomeStatisticsComponent,
        WidgetInventoryValueComponent,
        WidgetOpportunitiesInleadStatusComponent,
        WidgetTodayPaymentComponent,
        WidgetMemberActivityComponent,
        WidgetProfitShareComponent,
        WidgetRecentTenantsComponent,
        WidgetRegionalStatsComponent,
        WidgetSalesSummaryComponent,
        WidgetSubscriptionExpiringTenantsComponent,
        WidgetTopStatsComponent,
        FilterDateRangePickerComponent,
        AddWidgetModalComponent,
        SingleLineStringInputTypeComponent,
        ComboboxInputTypeComponent,
        CheckboxInputTypeComponent,
        MultipleSelectComboboxInputTypeComponent,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        PdfViewerComponent,
        WidgetsInterestComponent,
        WidgetPurchaseSummaryComponent,
        WidgetTodayReceiveComponent,
        WidgetCompanyFinanceComponent,
        WidgetServicePendingComponent,
        WidgetStockMomentComponent,
        WidgetSoftwareExpiredComponent,
        WidgetMeetingScheduleComponent,
        WidgetTodayReceiptComponent,
        WidgetTodayServiceComponent,
        WidgetTodayDeliverynoteComponent,
        WidgetTodayTaskComponent,
        WidgetTopFiveSalesComponent,
        SapDateRangePickerComponent,
        KeyEventComponent,
        PaginatorComponent
    ],
    exports: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        CustomizableDashboardComponent,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        PdfViewerComponent,
        KeyEventComponent,
        PaginatorComponent,
        UploaderExpandComponent,
        SubheaderModule
    ],
    providers: [
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,
        UrlService,
        DashboardViewConfigurationService,
        {provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig},
        {provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig}
    ]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
            providers: [AppAuthService, AppRouteGuard]
        };
    }
}
