import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgOptionHighlightModule} from 'ng-option-highlight/public-api';
import {CustomDirectiveModule} from '@app/custom-directive/custom-directive.module';
import {KeyboardShortcutsModule} from 'ng-keyboard-shortcuts';
import {NpDatepickerModule} from '@app/np-datepicker/np-datepicker.module';
import {DataViewModule} from 'primeng/dataview';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';

import {
    ActionBarModule,
    ActionSheetModule,
    AlertModule,
    BarModule,
    BusyIndicatorModule,
    ButtonModule,
    CardModule,
    CheckboxModule,
    ComboboxModule,
    DatePickerModule,
    DatetimePickerModule,
    DialogModule,
    DynamicSideContentModule,
    FdDatetimeModule,
    FeedInputModule,
    FeedListItemModule,
    FileUploaderModule,
    FixedCardLayoutModule,
    FormModule,
    IconModule,
    InfiniteScrollModule,
    InfoLabelModule,
    InlineHelpModule,
    InputGroupModule,
    LayoutGridModule,
    LayoutPanelModule,
    ListModule,
    MenuModule,
    MessageStripModule,
    ObjectIdentifierModule,
    ObjectNumberModule,
    ObjectStatusModule,
    PaginationModule,
    PanelModule,
    QuickViewModule,
    RadioModule,
    SelectModule,
    SideNavigationModule,
    SplitButtonModule,
    StepInputModule,
    TableModule,
    TabsModule,
    ToolbarModule,
    TreeModule,
    WizardModule
} from '@fundamental-ngx/core';
import {TableModule as primeTable} from 'primeng/table';
import {DataTableComponent} from '@app/shared/layout/data-grid/data-table/data-table.component';
import {DataGridDialogComponent} from '@app/shared/layout/data-grid/data-grid-dialog.component';
import {FormsModule} from '@angular/forms';
import {SuktasFilterPipe} from '@app/shared/layout/data-grid/suktas-filter.pipe';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NgSelectModule} from '@ng-select/ng-select';
import {DropdownModule} from 'primeng/dropdown';
import {PaginatorModule} from 'primeng/paginator';
import {TimelineModule} from '@fundamental-ngx/core/timeline';
import {TimePickerModule} from '@fundamental-ngx/core/time-picker';


@NgModule({
    declarations: [DataTableComponent, SuktasFilterPipe, DataGridDialogComponent],
    imports: [
        CommonModule,
        NgOptionHighlightModule,
        CustomDirectiveModule,
        NgxExtendedPdfViewerModule,
        NgSelectModule,
        RadioModule,
        KeyboardShortcutsModule,
        TabsModule,
        CheckboxModule,
        ButtonModule,
        NpDatepickerModule,
        ActionBarModule,
        IconModule,
        InputGroupModule,
        SplitButtonModule,
        TimelineModule,

        MenuModule,
        FormModule,
        FormsModule,
        SelectModule,
        CardModule,
        BarModule,
        PanelModule,
        StepInputModule,
        FeedInputModule,
        LayoutPanelModule,
        InfiniteScrollModule,
        DialogModule,
        SideNavigationModule,
        ListModule,
        DatePickerModule,
        DatetimePickerModule,
        FdDatetimeModule,
        MessageStripModule,
        FileUploaderModule,
        ObjectStatusModule,

        // SapTableModule,
        ToolbarModule,
        BusyIndicatorModule,
        ActionSheetModule,
        PaginationModule,
        InfoLabelModule,
        AlertModule,
        LayoutGridModule,
        ComboboxModule,
        FixedCardLayoutModule,
        FeedListItemModule,
        InlineHelpModule,
        DynamicSideContentModule,
        QuickViewModule,
        ObjectIdentifierModule,
        ObjectNumberModule,
        TimePickerModule,
        TableModule,
        // ngxTable,
        WizardModule,
        TreeModule,
        PaginatorModule,
        DragDropModule,

        ScrollingModule,
        DataViewModule,
        DropdownModule,
        primeTable
    ],
    exports: [
        CommonModule,
        NgOptionHighlightModule,
        CustomDirectiveModule,
        NgxExtendedPdfViewerModule,
        NgSelectModule,
        RadioModule,
        KeyboardShortcutsModule,
        TabsModule,
        PaginatorModule,
        CheckboxModule,
        ButtonModule,
        NpDatepickerModule,
        TimelineModule,

        ActionBarModule,
        IconModule,
        InputGroupModule,
        SplitButtonModule,
        MenuModule,
        FormModule,
        SelectModule,
        CardModule,
        DropdownModule,
        BarModule,
        PanelModule,
        StepInputModule,
        FeedInputModule,
        LayoutPanelModule,
        InfiniteScrollModule,
        DialogModule,
        SideNavigationModule,
        ListModule,
        DatePickerModule,
        DatetimePickerModule,
        FdDatetimeModule,
        MessageStripModule,
        FileUploaderModule,
        ObjectStatusModule,

        // SapTableModule,
        ToolbarModule,
        BusyIndicatorModule,
        ActionSheetModule,
        PaginationModule,
        ScrollingModule,
        InfoLabelModule,
        AlertModule,
        LayoutGridModule,
        ComboboxModule,
        FixedCardLayoutModule,
        FeedListItemModule,
        InlineHelpModule,
        DynamicSideContentModule,
        QuickViewModule,
        ObjectIdentifierModule,
        ObjectNumberModule,
        TableModule,
        // ngxTable,
        primeTable,
        WizardModule,
        DataViewModule,
        TreeModule,
        DataTableComponent,
        DataGridDialogComponent
    ]
})
export class SharedModuleModule {
}
