<div #modal="bs-modal" [config]="{backdrop: 'static'}" appBsModal aria-hidden="true" aria-labelledby="modal"
     class="modal fade"
     role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <span>{{"YourSessionIsAboutToExpire" | localize }}</span>
                </h5>
                <button aria-hidden="true" class="close" data-dismiss="modal" type="button"></button>
            </div>
            <div class="modal-body">
                <p>{{l("SessionExpireRedirectingInXSecond", currentSecond)}}</p>
                <div class="progress">
                    <div [style.width]="progresbarWidth" class="progress-bar progress-bar-striped active"
                         class="min-width-15px;" role="progressbar">
                        <span>{{currentSecond}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
