<div [dropup]="isDropup" aria-expanded="true" class="topbar-item" container="{{isDropup ? null: 'body'}}"
     data-toggle="dropdown"
     dropdown>
    <div [class]="customStyle" dropdownToggle>
        <i class="bi bi-calendar"></i>
    </div>
    <div *dropdownMenu
         class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-lg ddown">
        <div class="popup-header">
            <h3>Calendar</h3>
        </div>
        <div>
            <form [formGroup]="form">
                <fd-layout-grid>
                    <!-- <div fdLayoutGridCol="12">
                        <div fd-form-item>
                            <np-datepicker [todayBtn]="true" [formControl]="form.controls['selectDate']"  placeholder='Select a Date'></np-datepicker>
                        </div>
                    </div> -->
                    <div fdLayoutGridCol="12">
                        <div fd-form-item>
                            <input fd-form-control type="text">
                        </div>
                    </div>
                </fd-layout-grid>
            </form>
        </div>
        <hr>
        <!-- <div class="footer">
            <button class="btn btn-primary">save</button>
        </div> -->
    </div>
</div>
