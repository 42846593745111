import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {CurrentFinancialYearDto, ReportingServiceProxy} from '@shared/service-proxies/service-proxies';
import {DateHelper} from '../Controllers/date-helper';
import {NpDate} from '../Controllers/np-date';
import {DMType, splitedDate} from '../Models/types';
import {DateModel} from '../Models/date-model';
import * as moment from 'luxon';
import {DateConverter} from '../Controllers/date-converter';
import NepaliDate from 'nepali-date-converter';
import {DatepickerService} from './datepicker.service';

@Component({
    selector: 'nepali-picker',
    templateUrl: './nepali-picker.component.html',
    styleUrls: ['./nepali-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NepaliPickerComponent),
            multi: true
        }
    ]
})
export class NepaliPickerComponent implements OnInit {


    @Output() onDateChange: EventEmitter<any> = new EventEmitter<any>();
    value = '';
    isMonth = false;
    isYear = false;
    isMain = true;
    public pickerCalenderStyle;
    financialYear: CurrentFinancialYearDto;
    @Input() inputClass: { [key: string]: boolean };
    @Input() inputStyle: { [key: string]: string };
    @Input() calenderClass: { [key: string]: boolean };
    @Input() calenderStyle: { [key: string]: string };
    @Input() todayBtn: boolean;
    @Input() dateFormat: string;
    @Input() placeholder: string;
    @Output() change = new EventEmitter();
    public selectedDate: splitedDate;
    onTouched: () => {
        onChange();
    };
    disabled: boolean;
    private monthCalanderData: any[] = [];
    private selectedDay: string;
    private selectedWeekDay: string;
    private className: any;
    private textBox: HTMLInputElement;

    constructor(private _allProxy: ReportingServiceProxy, public state: DatepickerService) {
    }

    private _selectedMonthId: number;

    public get selectedMonthId(): number {
        return this._selectedMonthId;
    }

    public set selectedMonthId(v: number) {
        this._selectedMonthId = v;
        this.setMonthCallander();
    }

    private _selectedMonth: DMType;

    public get selectedMonth(): DMType {
        return DateModel.monthDataNp.find((month) => month.id == this.selectedMonthId);
    }

    public set selectedMonth(v: DMType) {
        this._selectedMonth = v;
        this.selectedMonthId = v.id;
    }

    private _selectedYear: string;

    public get selectedYear(): string {
        return this._selectedYear;
    }

    public set selectedYear(v: string) {
        this._selectedYear = v;
        this.setMonthCallander();
    }

    public get dayData(): any {
        if (this.monthCalanderData.length <= 0) {
            this.setMonthCallander();
        }
        return this.monthCalanderData;
    }

    public get weekData(): DMType[] {
        return DateModel.weekDataNp;
    }

    public get monthData(): DMType[] {
        return DateModel.monthDataNp;
    }

    get toYear(): number {
        let data = this.financialYear?.toMiti.split('/');
        if (data) {
            return parseInt(data[0]);
        }
        return 2080;
    }

    get fromYear(): number {
        let data = this.financialYear?.fromMiti.split('/');
        if (data) {
            return parseInt(data[0]);
        } else {
            return 2079;
        }

    }

    get fromFinancialYear() {
        let data = this.financialYear?.fromMiti.split('/');
        if (data) {
            return data;
        } else {
            return ['2079', '01', '01'];
        }

    }

    get currentMonth(): string {
        return this.monthData.find((month) => month.id == this.selectedMonthId).name;
    }

    get currentYear(): string {
        return this.selectedYear;
    }

    get currentMonthById(): number {
        return this.monthData.find((month) => month.id == this.selectedMonthId).id;
    }

    get currentFinancialyYearByFromYear(): boolean {
        if (this.selectedYear === (this.fromYear).toString()) {
            return true;
        } else {
            return false;
        }
    }

    get currentFinancialyYearByToYear(): boolean {
        if (this.selectedYear === (this.toYear).toString()) {
            return true;
        } else {
            return false;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: string | any): void {
        // let newValue = DateConverter.nepDateFormat(value);
        let newValue = new NpDate(value);
        let day = new NepaliDate(value);
        let weekDay = day.getDay() + 1;
        let date = day.format('D');
        // this.value = value ? newValue : "";
        if (newValue !== null && newValue !== undefined) {
            this.selectedYear = newValue.yearBs;
            this.selectedMonthId = newValue.monthBs.id;
            this.selectDay(date.toString(), weekDay.toString());
        }
        if (newValue === undefined) {
            this.selectTodayDate();
        }
    }

    public selectTodayDate() {
        let npDateNow = DateConverter.ADtoBS(new Date());

        this.selectedYear = npDateNow.yearBs;
        this.selectedMonth = npDateNow.monthBs;
        this.selectDay(npDateNow.dayBs.day.toString(), npDateNow.dayBs.weekDay.id.toString());

        this.selectedDate = {
            year: parseInt(this.selectedYear),
            month: this.selectedMonth.id,
            dayOfMonth: parseInt(this.selectedDay)
        };
    }

    ngOnInit(): void {
        this._allProxy.getFinancialYears().subscribe(x => {

            this.financialYear = x;
        });
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    public onChange(value: any): void {
        let splitDate = DateHelper.splitDate(value.toString());
        if (splitDate) {
            this.selectedYear = splitDate.year.toString();
            this.selectedMonthId = splitDate.month;
            this.selectedDay = splitDate.dayOfMonth ? splitDate.dayOfMonth.toString() : '';
            this.selectedDate = splitDate;
            if (this.selectedDay) {
                this.change.emit('new NpDate({date: splitDate})');
            }
        }
    }

    /**
     * Rupak [s1]
     * @param {Event} Input
     * @param {Object} date.value
     * @method [(s2)]
     */
    selectDate(e, value) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.setValue(value);
        }
    }

    /**
     * Rupak [s2]
     * @param {Object} date.value
     * @method [(s21)+(s22)]
     */
    setValue(value: string | any) {
        // initial value
        const init_Value = value;
        if (init_Value.length < 8 || null) {
            alert('Please enter date in proper format (yyyy/mm/dd) [i.e: 2077/08/12]');
            this.setToToday();
        } else {
            if (this.checkFormat(this.reFormat(init_Value))) {
                this.patchValue(this.reFormat(init_Value));
            } else {
                alert('Please enter date in proper format (yyyy/mm/dd) [i.e: 2077/08/12]');
                this.setToToday();
            }
        }
    }

    /**
     * Rupak [s22] | [s2*]
     * @param {String} date.value
     * @param {MethodDecorator} [findParams.callback]
     * @return {DateConverter} format exactly in [yyyy/mm/dd]
     * [i.e: (2077/1/8) = (2077/01/08)]
     */
    reFormat(value) {
        let temp = value.split('/');
        // month
        if (temp[1].length == 1) {
            temp[1] = this.padLeadingZeros(temp[1], 2);
        }
        // day
        if (temp[2].length == 1) {
            temp[2] = this.padLeadingZeros(temp[2], 2);
        }
        // console.log(temp[0]+'/'+temp[1]+'/'+temp[2])
        return temp[0] + '/' + temp[1] + '/' + temp[2]; // return in yyyy/mm/dd
    }

    /**
     * Rupak [s221] | [s22*]
     * @param {String} date.value
     * @param {Number} size
     * @return {string} prefixing value with 0
     */
    padLeadingZeros(num: any, size: any) {
        let s = num + '';
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    }

    checkFormat(value) {
        // moment module(3rd Party) is used to validate.
        return moment.DateTime.fromFormat(value, 'YYYY/MM/DD');
    }

    /**
     * Rupak [s3]
     * @param {String} date.value
     * final method
     */
    patchValue(value: string | any): void {
        value = this.validatedDate(value, this.dayData);
        this.value = value ? value : '';
        this.selectedYear = value.yearBs;
        this.selectedMonthId = value.monthBs.id;
        this.selectDay(value.dayBs.day.toString(), value.dayBs.weekDay.id.toString());
    }

    public validatedDate(value: string, arr: any[]): NpDate {
        if (this.returnInputDay(value) > this.getHighestDay(arr)) {
            alert(this.returnInputDay(value) + ' दिन यो महिनामा उपलब्ध छैन ।');
            return DateConverter.nepDateFormat(value);
        } else {
            return new NpDate(value);
        }
    }

    /**
     * Rupak [s4]
     * public method to set current date
     */
    public setToToday() {
        let npDateNow = DateConverter.ADtoBS(new Date());
        this.selectedYear = npDateNow.yearBs;
        this.selectedMonth = npDateNow.monthBs;
        this.selectDay(npDateNow.dayBs.day.toString(), npDateNow.dayBs.weekDay.id.toString());
        this.selectedDate = {
            year: parseInt(this.selectedYear),
            month: this.selectedMonth.id,
            dayOfMonth: parseInt(this.selectedDay)
        };
    }

    selectDay(day: string, weekDay: string) {
        if (day && weekDay) {
            this.selectedDay = day;
            this.selectedWeekDay = weekDay;

            this.selectedDate = {
                year: parseInt(this.selectedYear),
                month: this.selectedMonth.id,
                dayOfMonth: parseInt(this.selectedDay)
            };

            let selectedDate = new NpDate({
                date: this.selectedYear + '/' + this.selectedMonth.id + '/' + this.selectedDay,
                dateFormat: this.dateFormat
            });
            this.value = selectedDate.toString();
            let formDate = this.selectedYear + '/' + this.selectedMonth.id + '/' + this.selectedDay;

            if (this.onChange) {
                this.onChange(formDate);
            }
        }
    }

    incrementMonth() {
        if (this.selectedMonth && this.selectedYear && parseInt(this.selectedYear) <= 2099) {
            if (this.selectedMonth.id == 12) {
                if (parseInt(this.selectedYear) < 2099) {
                    this.selectedYear = (parseInt(this.selectedYear) + 1).toString();
                    this.selectedMonth = DateModel.monthDataNp.find((month) => month.id == this.selectedMonth.id - 11);
                }
            } else {
                this.selectedMonth = DateModel.monthDataNp.find((month) => month.id == this.selectedMonth.id + 1);
            }
            let totalDaysInMonth = DateModel.nepaliDateData[parseInt(this.selectedYear) % 2000][this.selectedMonth.id - 1];
            if (parseInt(this.selectedDay) > totalDaysInMonth) {
                this.selectedDay = totalDaysInMonth.toString();
            }
        }
    }

    changeByYear() {
        if (this.selectedYear) {
            let totalDaysInMonth = DateModel.nepaliDateData[parseInt(this.selectedYear) % 2000][this.selectedMonth.id - 1];
            if (parseInt(this.selectedDay) > totalDaysInMonth) {
                this.selectedDay = totalDaysInMonth.toString();
            }
        }
    }

    decrementMonth() {
        if (this.selectedMonth && this.selectedYear && parseInt(this.selectedYear) >= 2000) {
            if (this.selectedMonth.id == 1) {
                if (parseInt(this.selectedYear) > 2000) {
                    this.selectedYear = (parseInt(this.selectedYear) - 1).toString();
                    this.selectedMonth = DateModel.monthDataNp.find((month) => month.id == this.selectedMonth.id + 11);
                }
            } else {
                this.selectedMonth = DateModel.monthDataNp.find((month) => month.id == this.selectedMonth.id - 1);
            }
            let totalDaysInMonth = DateModel.nepaliDateData[parseInt(this.selectedYear) % 2000][this.selectedMonth.id - 1];
            if (parseInt(this.selectedDay) > totalDaysInMonth) {
                this.selectedDay = totalDaysInMonth.toString();
            }
        }
    }

    arrayOne(n: number): any[] {
        return Array(n);
    }

    public openMiti() {
        this.onTouched();
    }

    openYearPicker() {
        document.getElementById('fd-calendar-1').style.display = 'block';
        document.getElementById('fd-calendar-0').style.display = 'none';
        document.getElementById('fd-calendar-2').style.display = 'none';

    }

    openMonthPicker() {
        document.getElementById('fd-calendar-1').style.display = 'none';
        document.getElementById('fd-calendar-0').style.display = 'none';
        document.getElementById('fd-calendar-2').style.display = 'block';

    }

    openDatePicker() {
        document.getElementById('fd-calendar-1').style.display = 'none';
        document.getElementById('fd-calendar-0').style.display = 'block';
        document.getElementById('fd-calendar-2').style.display = 'none';

    }

    changeByMonth(id: number) {
        this.selectedMonthId = id;
        this.openDatePicker();
    }

    changeByYearView(year: number) {
        this.selectedYear = year.toString();
        this.openMonthPicker();
    }

    private setMonthCallander() {
        let selectedYear: number;
        let selectedMonth: number;

        if (this.selectedYear) {
            selectedYear = parseInt(this.selectedYear);
        } else {
            selectedYear = 2000;
        }

        if (this.selectedMonth) {
            selectedMonth = this.selectedMonth.id;
        } else {
            selectedMonth = 1;
        }

        let startWeekDay = DateHelper.getNepaliWeekDay(selectedYear, selectedMonth, 1);
        let totalDays = DateModel.nepaliDateData[selectedYear % 2000][selectedMonth - 1];


        let daysData: any[] = [];
        for (let i = 1; i < startWeekDay; i++) {
            daysData.push({day: '', dayOfWeek: '', enabled: false});
        }
        let weekTrack = startWeekDay;
        for (let i = 0; i < totalDays; i++) {
            let day = {
                day: i + 1,
                dayOfWeek: weekTrack,
                enabled: true
            };
            if (weekTrack < 7) {
                weekTrack++;
            } else {
                weekTrack = 1;
            }
            daysData.push(day);
        }
        let lastRowCellCount = daysData.length % 7;
        let remaningEmptyCells = 7 - (lastRowCellCount == 0 ? 7 : lastRowCellCount);
        for (let i = 0; i < remaningEmptyCells; i++) {
            daysData.push({day: '', dayOfWeek: '', enabled: false});
        }
        // setTimeout(() => {console.log(this.fromFinancialYear),100})
        // let fromYear: any = this.fromFinancialYear;

        const fromMiti: string[] = this.state.fromMitiSubject$.value.split('/');
        const toMiti: string[] = this.state.toMitiSubject$.value.split('/');
        const fromMonth: number = parseInt(fromMiti[1]);
        const toMonth: number = parseInt(toMiti[1]);
        const fromDay: number = parseInt(fromMiti[2]);
        const toDay: number = parseInt(toMiti[2]);
        const fromYear: number = parseInt(fromMiti[0]);
        const toYear: number = parseInt(toMiti[0]);
        // if(fromYear == selectedYear){
        //     if(fromMonth == selectedMonth){
        //         for (let i = 0; i < daysData.length; i++) {
        //             if (daysData[i].day < fromDay) {
        //                 daysData[i].enabled = false;
        //             }
        //         }
        //     }
        //     if( fromMonth > selectedMonth){
        //         for (let i = 0; i < daysData.length; i++) {
        //             daysData[i].enabled = false;
        //         }
        //     }

        // }
        // if(toYear == selectedYear){
        //     if(toMonth == selectedMonth){
        //         for (let i = 0; i < daysData.length; i++) {
        //             if (daysData[i].day > toDay) {
        //                 daysData[i].enabled = false;
        //             }
        //         }
        //     }
        //     if( toMonth < selectedMonth){
        //         for (let i = 0; i < daysData.length; i++) {
        //             daysData[i].enabled = false;
        //         }
        //     }

        // }

        if (fromYear >= selectedYear) {
            if (fromYear === selectedYear) {
                if (fromMonth == selectedMonth) {
                    for (let i = 0; i < daysData.length; i++) {
                        if (daysData[i].day < fromDay) {
                            daysData[i].enabled = false;
                        }
                    }
                }
                if (fromMonth > selectedMonth) {
                    for (let i = 0; i < daysData.length; i++) {
                        daysData[i].enabled = false;
                    }
                }
            } else {
                for (let i = 0; i < daysData.length; i++) {
                    daysData[i].enabled = false;
                }
            }
        }
        if (toYear <= selectedYear) {
            if (toYear === selectedYear) {
                if (toMonth == selectedMonth) {
                    for (let i = 0; i < daysData.length; i++) {
                        if (daysData[i].day > toDay) {
                            daysData[i].enabled = false;
                        }
                    }
                }
                if (toMonth < selectedMonth) {
                    for (let i = 0; i < daysData.length; i++) {
                        daysData[i].enabled = false;
                    }
                }
            } else {
                for (let i = 0; i < daysData.length; i++) {
                    daysData[i].enabled = false;
                }
            }
        }

        this.monthCalanderData = daysData;
    }

    /**
     * Rupak [s5]
     *  method to return highest days in present month
     */
    private getHighestDay(arr: any[]): number {
        let days: number[] = [];
        arr.map((m) => {
            if (m.enabled === true) {
                days.push(m.day);
            }
        });
        return Math.max(...days);
    }

    /**
     *  Rupak [s6]
     * method to validate np date
     */
    private returnInputDay(value: string): number {
        let input = value.split('/');
        return parseInt(input[2]);
    }
}
