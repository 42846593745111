import {Component, Injector, OnInit} from '@angular/core';
import {ReportingServiceProxy, TenantDashboardServiceProxy} from '@shared/service-proxies/service-proxies';
import {DashboardChartBase} from '../dashboard-chart-base';
import {WidgetComponentBaseComponent} from '../widget-component-base';

class TodayReceiptTable extends DashboardChartBase {
    todayReceipt: any;

    constructor(private _dashboardService: TenantDashboardServiceProxy, private _proxy: ReportingServiceProxy) {
        super();
    }

    reload() {
        this.showLoading();
        this._proxy.getFinancialYears().subscribe(data => {
            this._dashboardService.getRealDashboardData(
                data.toDate, data.fromDate
            ).subscribe(data => {

                this.todayReceipt = data.todayReceipt;
                this.hideLoading();
            });
        });


        // this._dashboardService.getRealDashboardData(null, null).subscribe((result) => {
        //     this.todayReceipt = result.todayReceipt;
        //     this.hideLoading();
        // });
    }

    init() {
        this.reload();
    }

}

@Component({
    selector: 'app-widget-today-receipt',
    templateUrl: './widget-today-receipt.component.html',
    styleUrls: ['./widget-today-receipt.component.css']
})
export class WidgetTodayReceiptComponent extends WidgetComponentBaseComponent implements OnInit {
    todayReceiptTable: TodayReceiptTable;

    constructor(injector: Injector, private _dashboardService: TenantDashboardServiceProxy, private _proxy: ReportingServiceProxy) {
        super(injector);
        this.todayReceiptTable = new TodayReceiptTable(this._dashboardService, this._proxy);
    }

    ngOnInit() {
        this.todayReceiptTable.init();
    }
}
