import {Component, Injector, OnInit} from '@angular/core';
import {TenantDashboardServiceProxy} from '@shared/service-proxies/service-proxies';
import {DashboardChartBase} from '../dashboard-chart-base';

class ProductMovementLineChart extends DashboardChartBase {


    chartData: any[];
    scheme: any = {
        name: 'green',
        selectable: true,
        group: 'Ordinal',
        domain: [
            '#34bfa3'
        ]
    };

    constructor(private _dashboardService: TenantDashboardServiceProxy) {
        super();
    }

    reload() {
        this.showLoading();
        this._dashboardService;
        // .getTodayPayment()
        //   .subscribe(result => {
        //     this.init(result.todayPayment);
        //     this.hideLoading();
        //   });
    }

    init(productMovement: any) {

    }

}

@Component({
    selector: 'app-widgets-product-movement',
    templateUrl: './widgets-product-movement.component.html',
    styleUrls: ['./widgets-product-movement.component.css']
})
export class WidgetsProductMovementComponent implements OnInit {

    productMovementLineChart: ProductMovementLineChart;


    constructor(injector: Injector,
                private _tenantdashboardService: TenantDashboardServiceProxy) {

        this.productMovementLineChart = new ProductMovementLineChart(this._tenantdashboardService);
    }


    ngOnInit(): void {
        this.productMovementLineChart.reload();
    }

}
