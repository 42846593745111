<div class="card card-custom">
    <div class="card-header align-items-center border-0">
        <h3 class="card-title align-items-start fx-column">
          <span class="fw-bolder text-dark">
              Today Task
          </span>
        </h3>
        <div class="card-toolbar">
            <button (click)="todayTaskTable.reload()" class="btn btn-primary"><i
                    class="pi pi-refresh"></i> Refresh
            </button>
        </div>
    </div>
    <div [perfectScrollbar] class="card-body">
        <div class="table-scrollable table-scrollable-borderless">
            <table class="table table-hover table-light">
                <thead>
                <tr class="uppercase">
                    <th>
                        S.N.
                    </th>
                    <th colspan="2">
                        BRANCH NAME
                    </th>
                    <th>
                        DATE
                    </th>
                    <th>
                        TASK NAME
                    </th>
                    <th>
                        PRIORITY
                    </th>
                    <th>
                        CONTACT NAME
                    </th>
                    <th>
                        STATUS
                    </th>
                </tr>
                </thead>
                <tr *ngFor="let m of todayTaskTable.todayTask; let index = index">
                    <td class="pl-0">
                        <div class="symbol symbol-50 symbol-light mt-2 ml-3">
                            {{m.sn}}
                        </div>
                    </td>
                    <td class="pl-0">
                        <a class="text-dark fw-bolder text-hover-primary mb-1 font-size-lg"
                           href="javascript:">{{m.name}}</a>
                    </td>

                    <td>
                        {{m.branchName}}
                    </td>
                    <td>
                        {{m.dateMiti| luxonFormat:'yyyy-LL-dd'}}
                    </td>
                    <td>
                        {{m.taskName}}
                    </td>
                    <td>
                        {{m.priority}}
                    </td>
                    <td>
                        {{m.contactName}}
                    </td>
                    <td>
                        {{m.status}}
                    </td>


                </tr>
            </table>
        </div>
    </div>
</div>
