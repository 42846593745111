import {Component, Injector, OnInit} from '@angular/core';
import {DashboardRealDataGeneratorServiceProxy} from '@shared/service-proxies/service-proxies';
import {DashboardChartBase} from '../dashboard-chart-base';
import {WidgetComponentBaseComponent} from '../widget-component-base';


class InterestTable extends DashboardChartBase {

    interest: any;

    constructor(private _dashboardService: DashboardRealDataGeneratorServiceProxy) {
        super();
    }

    init() {
        this.reload();
    }

    reload() {
        this.showLoading();
        this._dashboardService
            .getDashboardSummary('2075/10/10', '2079/12/30')
            .subscribe(result => {
                this.interest = result.interest;
                this.hideLoading();

            });
    }
}

@Component({
    selector: 'app-widgets-interest',
    templateUrl: './widgets-interest.component.html',
    styleUrls: ['./widgets-interest.component.css']
})
export class WidgetsInterestComponent extends WidgetComponentBaseComponent implements OnInit {
    interestTable: InterestTable;

    constructor(injector: Injector,
                private _tenantdashboardService: DashboardRealDataGeneratorServiceProxy) {
        super(injector);
        this.interestTable = new InterestTable(this._tenantdashboardService);
    }

    ngOnInit(): void {
        this.interestTable.reload();
    }

}
