<div [@routerTransition] [class]="defaultContainer">
    <div class="content display-flex fx-column">
        <sub-header [title]="'Notifications' | localize">
            <div role="actions">
                <button (click)="openNotificationSettingsModal()" class="btn btn-sm btn-success">
                    <!-- <i
                    class="fa-duotone fa-gear"></i> -->
                    <i class="bi bi-gear-fill"></i>
                    {{"NotificationSettings" | localize}}</button>
                <button (click)="setAllNotificationsAsRead()" class="btn btn-sm btn-primary">
                    <!-- <i
                        class="pi pi-check"></i> -->
                    <i class="bi bi-patch-check-fill"></i>
                    {{"SetAllAsRead" | localize}}</button>
            </div>
        </sub-header>
        <div [class]="containerClass">
            <div class="card card-custom">
                <div class="card-body">
                    <div class="form">
                        <div class="row mb-4">
                            <div class="col-xl-6">
                                <input #drp="bsDaterangepicker" [(date)]="dateRange" bsDaterangepicker
                                       class="form-control" dateRangePickerLuxonModifier id="StartEndRange"
                                       name="StartEndRange" readonly="readonly">
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <select #TargetValueFilterSelectionCombobox [(ngModel)]="readStateFilter"
                                            class="form-control" name="readStateFilter">
                                        <option value="ALL">{{"All" | localize}}</option>
                                        <option value="UNREAD">{{"Unread" | localize}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 text-right">
                                <div class="form-group">
                                    <button (click)="deleteNotifications()" class="btn btn-sm btn-danger">
                                        <i class="bi bi-trash"></i>{{"DeleteListedNotifications" | localize}}</button>
                                    <button (click)="getNotifications()" class="btn btn-sm btn-primary"><i
                                            class="bi bi-arrow-clockwise"></i>
                                        {{"Refresh" | localize}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <!--<Primeng-TurboTable-Start>-->
                        <div [busyIf]="primengTableHelper.isLoading" class="col primeng-datatable-container">
                            <p-table #dataTable (onLazyLoad)="getNotifications($event)"
                                     [lazy]="true"
                                     [paginator]="false" [responsive]="primengTableHelper.isResponsive"
                                     [value]="primengTableHelper.records"
                                     rows="{{primengTableHelper.defaultRecordsCountPerPage}}">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="width-100px text-center">
                                            {{'Actions' | localize}}
                                        </th>
                                        <th class="width-80px text-center">{{'Severity' | localize}}</th>
                                        <th>
                                            {{'Notification' | localize}}
                                        </th>
                                        <th class="width-200px text-center">
                                            {{'CreationTime' | localize}}
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template let-record="$implicit" pTemplate="body">
                                    <tr>
                                        <td class="width-130px">
                                            <button (click)="setAsRead(record)"
                                                    [attr.title]="!isRead(record) ? l('SetAsRead') : ''"
                                                    [disabled]="isRead(record)" class="btn btn-sm btn-icon btn-primary">
                                                <i *ngIf="isRead(record)" [attr.aria-label]="l('Read')"
                                                   class="pi pi-check"></i>
                                                <i *ngIf="!isRead(record)" [attr.aria-label]="l('Unread')"
                                                   class="pi pi-circle-notch"></i>
                                            </button>
                                            <button (click)="deleteNotification(record)" [attr.title]="l('Delete')"
                                                    class="btn btn-sm btn-icon btn-danger">
                                                <i [attr.aria-label]="l('Delete')" class="pi pi-times"></i>
                                            </button>
                                        </td>
                                        <td class="text-center;">
                                            <i
                                                    class="{{record.formattedNotification.icon}} {{record.formattedNotification.iconFontClass}} fa-2x"></i>
                                            <br>
                                            {{getNotificationTextBySeverity(record.formattedNotification.severity)}}
                                        </td>
                                        <td>
                                            <span class="p-column-title">{{'Notification' | localize}}</span>
                                            <a *ngIf="record.formattedNotification.url"
                                               class="{{getRowClass(record)}}"
                                               href="{{record.formattedNotification.url}}">{{truncateString(record.formattedNotification.text, 120)}}</a>
                                            <span *ngIf="!record.formattedNotification.url"
                                                  class="{{getRowClass(record)}}"
                                                  title="{{record.formattedNotification.text}}">{{truncateString(record.formattedNotification.text, 120)}}</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="{{getRowClass(record)}}"
                                                  title="{{record.notification.creationTime | luxonFormat:'DDDD t'}}">
                                                {{fromNow(record.notification.creationTime)}}
                                            </span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div *ngIf="primengTableHelper.totalRecordsCount == 0" class="primeng-no-data">
                                {{'NoData' | localize}}
                            </div>
                            <div class="primeng-paging-container">
                                <p-paginator #paginator (onPageChange)="getNotifications($event)"
                                             [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                                             [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                             [totalRecords]="primengTableHelper.totalRecordsCount">
                                </p-paginator>
                                <span class="total-records-count">
                                    {{'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount}}
                                </span>
                            </div>
                        </div>
                        <!--<Primeng-TurboTable-End>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
