<div [perfectScrollbar] class="row row-no-padding row-col-separator-xl h-100">

    <div class="col pr-0">
        <div class="card card1 card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <a class="text-left fw-bolder text-dark font-size-h6 text-hover-state-dark d-block"
                   href="javascript:">
                    <i class="fa-duotone fa-hand-holding-dollar"></i>
                    TODAY COLLECTION
                </a>
                <span class="text-muted font-weight-bold text-left float-left col-12 p-0 mb-4"></span>
                <div *ngIf="allData" class="text-left text-primary fw-bolder font-size-h2 mr-2">
                    {{purchaseData}}
                    <!-- <span counto [step]="30" [duration]="1"
                           [countFrom]="0" [countTo]="dashboardTopStats.totalPurchase"
                           (countoChange)="dashboardTopStats.totalPurchaseCounter = $event">
                                {{dashboardTopStats.totalPurchaseCounter.toFixed(0)}}
                            </span> -->

                    <!-- <ngx-charts-line-chart [results]="dashboardTopStats.totalPurchase.change"
                    [showGridLines]="true" [animations]="true"
                    [tooltipDisabled]="true"
                    [customColors]="customColors"
                    [curve]="curve"></ngx-charts-line-chart> -->


                    <!-- <div class="progress progress-xs bg-primary-o-60">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 76%;" aria-valuenow="76"
                             aria-valuemin="0" aria-valuemax="100"></div>
                    </div> -->

                    <div class="d-flex align-items-center justify-content-between mb-2">
                        <!-- <span class="text-muted mr-2 font-size-sm font-weight-bold">
                            Change
                        </span> -->
                        <!-- <span class="text-muted font-size-sm font-weight-bold">
                            <span class="counterup">{{dashboardTopStats.totalPurchaseChange}}</span>%
                        </span> -->
                    </div>


                </div>
            </div>
        </div>
    </div>

    <div class="col pr-0">
        <div class="card card4 card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <a class="text-left fw-bolder text-dark font-size-h6 text-hover-state-dark d-block"
                   href="javascript:">
                    <i class="fa-duotone fa-calendar-exclamation"></i>
                    TOTAL FEE DUE
                </a>
                <span class="text-muted font-weight-bold text-left float-left col-12 p-0 mb-4"></span>
                <div class="text-left text-success fw-bolder font-size-h2 mr-2">
                    {{salesData}}
                    <!-- <div class="progress progress-xs bg-success-o-60">
                        <div class="progress-bar bg-success" role="progressbar" style="width: 57%;" aria-valuenow="57"
                             aria-valuemin="0" aria-valuemax="100"></div>
                    </div> -->
                </div>

                <!-- <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.totalSalesChange}}</span>%
                    </span>
                </div> -->
            </div>
        </div>
    </div>

    <div class="col pr-0">
        <div class="card card5 card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <a class="text-left fw-bolder text-dark font-size-h6 text-hover-state-dark d-block"
                   href="javascript:">
                    <i class="pi pi-wallet"></i>
                    Expenses
                </a>
                <span class="text-muted font-weight-bold text-left float-left col-12 p-0 mb-4"></span>
                <div class="text-left text-warning fw-bolder font-size-h2 mr-2">
                    {{expenseData}}
                    <!-- <div class="progress progress-xs bg-success-o-60">
                        <div class="progress-bar bg-success" role="progressbar" style="width: 57%;" aria-valuenow="57"
                             aria-valuemin="0" aria-valuemax="100"></div>
                    </div> -->
                </div>

                <!-- <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.totalSalesChange}}</span>%
                    </span>
                </div> -->
            </div>
        </div>
    </div>

    <div class="col pr-0">
        <div class="card card2 card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <a class="text-left fw-bolder text-dark font-size-h6 text-hover-state-dark d-block"
                   href="javascript:">
                    <i class="fa-duotone fa-money-bills"></i>
                    TOTAL CASH
                </a>
                <span class="text-muted font-weight-bold text-left float-left col-12 p-0 mb-4"></span>
                <div class="text-left text-info fw-bolder font-size-h2 mr-2">
                    <!-- {{dashboardTopStats.totalCash.name}} -->
                    {{cashData}}
                </div>
                <!-- <div class="progress progress-xs bg-info-o-60">
                    <div class="progress-bar bg-info" role="progressbar" style="width: 85%;" aria-valuenow="85"
                         aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.totalCash}}</span>%
                    </span>
                </div> -->
            </div>
        </div>
    </div>
    <div class="col pr-0">
        <div class="card card3 card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <a class="text-left fw-bolder text-dark font-size-h6 text-hover-state-dark d-block"
                   href="javascript:">
                    <i class="fa-duotone fa-building-columns"></i>
                    TOTAL BANK
                </a>
                <span class="text-muted font-weight-bold text-left float-left col-12 p-0 mb-4"></span>
                <div class="text-left text-danger fw-bolder font-size-h2 mr-2">
                    {{bankData}}
                </div>
                <!-- <div class="progress progress-xs bg-danger-o-60">
                    <div class="progress-bar bg-danger" role="progressbar" style="width: 45%;" aria-valuenow="45"
                         aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.totalBankChange}}</span>%
                    </span>
                </div> -->
            </div>
        </div>
    </div>

</div>
