<div class="card card-custom h-100">
    <div class="card-header align-items-center border-0">
        <div class="card-title">
            <span class="card-icon">
                <i class="far fa-clock text-danger"></i>
            </span>
            <h3 class="card-label text-danger">
                {{ "SubscriptionExpiringTenants" | localize }}
                <small *ngIf="expiringTenantsData"
                       class="text-muted">
                    {{
                    "ExpiringTenantsHelpText"
                        | localize
                        : expiringTenantsData.subscriptionEndAlertDayCount
                            : expiringTenantsData.maxExpiringTenantsShownCount
                    }}
                </small>
            </h3>
        </div>
    </div>
    <div [perfectScrollbar]
         class="card-body">
        <div class="scroller chart">
            <div *ngIf="!dataLoading"
                 [busyIf]="primengTableHelper.isLoading"
                 class="primeng-datatable-container expiring-tenants-table">
                <p-table #ExpiringTenantsTable
                         [lazy]="false"
                         [paginator]="false"
                         [responsive]="primengTableHelper.isResponsive"
                         [value]="expiringTenantsData.expiringTenants"
                         rows="{{ primengTableHelper.defaultRecordsCountPerPage }}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{ "TenantName" | localize }}</th>
                            <th>{{ "RemainingDay" | localize }}</th>
                        </tr>
                    </ng-template>
                    <ng-template let-record="$implicit"
                                 pTemplate="body">
                        <tr>
                            <td>
                                <span class="p-column-title">{{
                                    "TenantName" | localize
                                    }}</span>
                                {{ record.tenantName }}
                            </td>
                            <td>
                                <span class="p-column-title">{{
                                    "RemainingDay" | localize
                                    }}</span>
                                {{ record.remainingDayCount }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div *ngIf="expiringTenantsData?.expiringTenants?.length == 0"
                     class="primeng-no-data">
                    {{ "NoData" | localize }}
                </div>
            </div>
        </div>
        <div class="scroller-footer">
            <div class="btn-arrow-link pull-right">
                <a (click)="gotoAllExpiringTenants()"
                   class="see-all-expiring-tenants"
                   href="javascript:">
                    {{ "SeeAllRecords" | localize }}
                </a>
                <i class="icon-arrow-right"></i>
            </div>
        </div>
    </div>
</div>
