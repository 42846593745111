import {ContentChild, Directive, HostListener} from '@angular/core';
import {NgSelectComponent} from 'ng-select/lib/ng-select.component';
import {BackSpaceService} from '@app/shared/back-space.service';

export enum KEY_CODE {
    RIGHT_ARROW = 39,
    LEFT_ARROW = 37,
    ENTER = 13,
    BACK_SPACE = 8,
}

@Directive({
    selector: '[appCustomEnter]'
})
export class CustomEnterDirective {
    pValue: any;
    @ContentChild(NgSelectComponent) select: NgSelectComponent;

    constructor(private backSpaceService: BackSpaceService) {
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: any) {
        if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
            let pageElems = document.querySelectorAll('input, select, ng-select, textarea');
            let ele = document.forms[1].elements;

            for (let i = 0; i < ele.length; i++) {
                let q = (i == ele.length - 1) ? 0 : i + 1; // if last element : if any other
                const obj = event.srcElement || event.target;
                if (obj == ele[i]) {
                    const data = <HTMLInputElement>ele[q];
                    data.focus();
                    break;
                }
            }
            return false;
        }


        if (event.keyCode === KEY_CODE.BACK_SPACE) {
            event.preventDefault();
            this.backSpaceService.pValue$.subscribe(data => {

                this.pValue = data;
            });
            if (event.target.value === '' && this.pValue === '') {
                let ele = document.forms[1].elements;
                for (let i = 0; i < ele.length; i++) {
                    let q = (i == ele.length - 1) ? 0 : i - 1; // if last element : if any other
                    const obj = event.srcElement || event.target;
                    if (obj == ele[i]) {
                        const data = <HTMLInputElement>ele[q];
                        if (data) {
                            data.focus();
                        }
                        break;
                    }
                }
            }
            this.backSpaceService.setPreviousValue(event.target.value);
            return false;
        }

        if (event.keyCode === KEY_CODE.ENTER) {
            let pageElems = document.querySelectorAll('input, select, ng-select, textarea');
            let ele = document.forms[1].elements;
            for (let i = 0; i < ele.length; i++) {
                let q = (i == ele.length - 1) ? 0 : i + 1; // if last element : if any other
                const obj = event.srcElement || event.target;
                if (obj == ele[i]) {
                    const data = <HTMLInputElement>ele[q];
                    data.focus();
                    break;
                }
            }
            return false;
        }
    }

}
