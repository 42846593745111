<div *ngIf="(isHost || ('App.ChatFeature' | checkFeature)) && !chatConnected" class="topbar-item"
     id="chat_is_connecting_icon">
    <button [class]="customStyle">
        <img *ngIf="!chatConnected" class="width-23px;" placement="left"
             src="./assets/common/images/loading.gif" tooltip="{{'ChatIsConnecting' | localize}}"/>
    </button>
</div>
<div [hidden]="!(isHost || ('App.ChatFeature' | checkFeature)) || !chatConnected" class="topbar-item "
     id="kt_quick_sidebar_toggle">
    <button class="mt-2" fd-button fdType="transparent">
        <div *ngIf="unreadChatMessageCount" id="chatIconUnRead">
            <i class="fa-duotone fa-messages unread-notification"></i>
            <span class="label label-warning unread-chat-message-count">
                {{unreadChatMessageCount}}
            </span>
        </div>
        <div *ngIf="!unreadChatMessageCount" id="chatIcon">
            <i class="fa-duotone fa-messages"></i>
        </div>
    </button>
</div>
