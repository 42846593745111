<div class="card card-custom h-100">
    <div class="card-header align-items-center border-0">
        <h3 class="card-title align-items-start fx-column">
            <span class="fw-bolder text-dark"> Regional Stats </span>
        </h3>
    </div>
    <div [perfectScrollbar] class="card-body">
        <div class="table-responsive">
            <!--begin::Table-->
            <table class="table">
                <!--begin::Thead-->
                <thead>
                <tr>
                    <td class="m-widget11__label">#</td>
                    <td class="m-widget11__app">Country</td>
                    <td class="m-widget11__sales">Sales</td>
                    <td class="m-widget11__change" class="widget-regional-relative">Change</td>
                    <td class="m-widget11__price">Avg Price</td>
                    <td class="m-widget11__total">Total</td>
                </tr>
                </thead>
                <!--end::Thead-->
                <!--begin::Tbody-->
                <tbody>
                <tr *ngFor="let stat of regionalStatsTable.stats; let index = index">
                    <td>
                        <label class="checkbox">
                            <input class="form-control" type="checkbox"/>
                            <span> </span>
                        </label>
                    </td>
                    <td>{{ stat.countryName }}</td>
                    <td>{{ stat.sales | currency }}</td>
                    <td>
                        <div class="m-widget11__chart" class="height-50px width-100px">
                            <ngx-charts-line-chart
                                    [animations]="false"
                                    [curve]="regionalStatsTable.curve"
                                    [customColors]="regionalStatsTable.customColors"
                                    [results]="stat.changeData"
                                    [showGridLines]="false"
                                    [tooltipDisabled]="true"
                            ></ngx-charts-line-chart>
                        </div>
                    </td>
                    <td>{{ stat.averagePrice | currency }}</td>
                    <td>{{ stat.totalPrice | currency }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
