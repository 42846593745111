import {Component, Injector, OnInit} from '@angular/core';
import {ReportingServiceProxy, TenantDashboardServiceProxy} from '@shared/service-proxies/service-proxies';
import {DashboardChartBase} from '../dashboard-chart-base';
import {WidgetComponentBaseComponent} from '../widget-component-base';

class TodayServiceTable extends DashboardChartBase {

    todayService: Array<any>;

    constructor(private _dashboardService: TenantDashboardServiceProxy, private _proxy: ReportingServiceProxy) {
        super();
    }

    init() {
        this.reload();
    }

    reload() {
        this.showLoading();
        this._proxy.getFinancialYears().subscribe(data => {
            this._dashboardService.getRealDashboardData(
                data.toDate, data.fromDate
            ).subscribe(data => {

                this.todayService = data.todayService;
                this.hideLoading();
            });
        });

    }
}

@Component({
    selector: 'app-widget-today-service',
    templateUrl: './widget-today-service.component.html',
    styleUrls: ['./widget-today-service.component.css']
})


export class WidgetTodayServiceComponent extends WidgetComponentBaseComponent implements OnInit {

    todayServiceTable: TodayServiceTable;

    constructor(injector: Injector,
                private _dashboardService: TenantDashboardServiceProxy, private _proxy: ReportingServiceProxy) {
        super(injector);
        this.todayServiceTable = new TodayServiceTable(this._dashboardService, this._proxy);
    }

    ngOnInit() {
        this.todayServiceTable.init();
    }
}

