// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
    production: false,
    hmr: false,
    appConfig: 'appconfig.json',
    firebase: {
        apiKey: 'AIzaSyC-z41a7HIjCXAXZtrgMVW0QPFZd2qH6zk',
        authDomain: 'suktascampus.firebaseapp.com',
        projectId: 'suktascampus',
        storageBucket: 'suktascampus.appspot.com',
        messagingSenderId: '736711280199',
        appId: '1:736711280199:web:10d60e750dcfc62c6e5362',
        measurementId: 'G-EZP2PGC3EZ'
        // vapidKey:"BG9N3uCpp9oxMSe0UICZc2dEGJScub_r4_y57fFXw9ug7NRfp_RSdZXPnDdoxbMHmivp9hG-Y715Id42bALnVIw"
    }
};
