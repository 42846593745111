<div #modal="bs-modal" (onShown)="shown()" [config]="{ backdrop: 'static' }" appBsModal aria-hidden="true"
     aria-labelledby="modal"
     class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    {{ options.title }}
                </h4>
                <i (click)="close()" class="bi bi-x-lg cursor-pointer"></i>
                <!-- <button type="button" class="btn-close" (click)="close()" [attr.aria-label]="l('Close')">
                </button> -->
            </div>
            <div class="modal-body">
                <form (ngSubmit)="refreshTable()" autocomplete="new-password" novalidate>
                    <div *ngIf="options.isFilterEnabled" class="input-group mb-4  gap-5px">
                        <div class="min-width-93">
                            <input [(ngModel)]="filterText" autoFocus class="form-control" name="filterText"
                                   placeholder="{{ 'SearchWithThreeDot' | localize }}" type="text"/>
                        </div>

                        <div class="input-group-append">
                            <button class="btn btn-primary jusV-center min-height-40px" type="submit">
                                <i aria-label="Search" class="pi pi-search"></i>

                            </button>
                        </div>
                    </div>

                    <!--<Primeng-TurboTable-Start>-->
                    <div [busyIf]="primengTableHelper.isLoading" class="primeng-datatable-container">
                        <p-table #dataTable (onLazyLoad)="getRecordsIfNeeds($event)"
                                 [lazy]="true"
                                 [paginator]="false" [responsive]="primengTableHelper.isResponsive"
                                 [value]="primengTableHelper.records"
                                 rows="{{ primengTableHelper.defaultRecordsCountPerPage }}">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 15%">
                                        {{ 'Select' | localize }}
                                    </th>
                                    <th>
                                        {{ 'Name' | localize }}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template let-record="$implicit" pTemplate="body">
                                <tr>
                                    <td class="tenant-relative">
                                        <button
                                                (click)="selectItem(record)"
                                                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm tenant-pos-center"
                                                title="{{ 'Select' | localize }}">
                                            <i class="fa-duotone fa-circle-check color-white"></i>

                                        </button>
                                    </td>
                                    <td>
                                        <span class="p-column-title">{{ 'Name' | localize }}</span>
                                        {{ record.name }}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div *ngIf="primengTableHelper.totalRecordsCount == 0" class="primeng-no-data">
                            {{ 'NoData' | localize }}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator #paginator (onPageChange)="getRecordsIfNeeds($event)"
                                         [currentPageReportTemplate]="
                                    'TotalRecordsCount' | localize: primengTableHelper.totalRecordsCount
                                "
                                         [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                                         [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                         [showCurrentPageReport]="true"
                                         [totalRecords]="primengTableHelper.totalRecordsCount"></p-paginator>
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </form>
            </div>

            <div class="modal-footer">
                <button (click)="close()" class="btn btn-light-primary font-weight-bold" type="button">
                    {{ 'Cancel' | localize }}
                </button>
            </div>
        </div>
    </div>
</div>
