<div class="card card-custom">
    <div class="card-header align-items-center border-0">
        <h3 class="card-title align-items-start fx-column">
            <span class="fw-bolder text-dark">
                 Today Payment
            </span>
        </h3>
        <div class="card-toolbar">
            <button (click)="todayPaymentTable.reload()" class="btn btn-primary"><i
                    class="pi pi-refresh"></i> Refresh
            </button>
        </div>
    </div>
    <div [perfectScrollbar] class="card-body">
        <div class="table-scrollable table-scrollable-borderless">
            <table class="table table-hover table-light">
                <thead>
                <tr class="uppercase">
                    <th colspan="2">
                        S.N.
                    </th>
                    <th>
                        DATE MITI
                    </th>
                    <th>
                        LEDGER NAME
                    </th>
                    <th>
                        VOUCHER NO
                    </th>
                    <th>
                        AMOUNT
                    </th>

                </tr>
                </thead>
                <tr *ngFor="let m of todayPaymentTable.todayPayment; let index = index">
                    <td class="pl-0">
                        <div class="symbol symbol-50 symbol-light mt-2 mr-2">
                            {{m.sn}}
                        </div>
                    </td>
                    <td class="pl-0">
                        <a class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                           href="javascript:">{{m.name}}</a>
                    </td>
                    <td>
                        {{m.dateMiti| luxonFormat:'yyyy-LL-dd'}}
                    </td>
                    <td>
                        {{m.ledgerName}}
                    </td>
                    <td>
                        {{m.voucherNo}}
                    </td>
                    <td>
                        {{m.amount}}
                    </td>


                </tr>
            </table>
        </div>
    </div>
</div>
