import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DMType, splitedDate } from './Models/types';
import { DateModel } from './Models/date-model';
import { DateHelper } from './Controllers/date-helper';
import { NpDate } from './Controllers/np-date';
import { DateConverter } from './Controllers/date-converter';
import * as moment from 'luxon';
import NepaliDate from 'nepali-date-converter';

@Component({
    selector: 'np-datepicker',
    templateUrl: './np-datepicker.component.html',
    styleUrls: ['./np-datepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NpDatepickerComponent),
            multi: true
        }
    ]
})
export class NpDatepickerComponent implements OnInit, ControlValueAccessor {
    @Input() inputClass: { [key: string]: boolean };
    @Input() inputStyle: { [key: string]: string };
    @Input() calenderClass: { [key: string]: boolean };
    @Input() calenderStyle: { [key: string]: string };
    @Input() todayBtn: boolean;
    @Input() dateFormat: string;
    @Input() placeholder: string;
    @Output() change = new EventEmitter();
    @Output() onDateChange: EventEmitter<any> = new EventEmitter<any>();
    onTouched: () => {
        onChange();
    };
    disabled: boolean;
    value = '';

    public selectedDate: splitedDate;
    public pickerCalenderStyle;
    private monthCalanderData: any[] = [];
    private selectedDay: string;

    //Properties Start
    private selectedWeekDay: string;
    private className: any;
    private textBox: HTMLInputElement;
    private _selectedMonthId: number;
    private _selectedMonth: DMType;
    private _selectedYear: string;


    constructor() {
    }

    public get selectedMonth(): DMType {
        return DateModel.monthDataNp.find(month => month.id === this.selectedMonthId);
    }

    public get selectedYear(): string {
        return this._selectedYear;
    }

    public get dayData(): any {
        if (this.monthCalanderData.length <= 0) {
            this.setMonthCallander();
        }
        return this.monthCalanderData;
    }

    public get weekData(): DMType[] {
        return DateModel.weekDataNp;
    }

    public get monthData(): DMType[] {
        return DateModel.monthDataNp;
    }




    public get selectedMonthId(): number {
        return this._selectedMonthId;
    }

    public set selectedMonthId(v: number) {
        this._selectedMonthId = v;
        this.setMonthCallander();
    }

    ;



    public set selectedMonth(v: DMType) {
        this._selectedMonth = v;
        this.selectedMonthId = v.id;
    }



    public set selectedYear(v: string) {
        this._selectedYear = v;
        this.setMonthCallander();
    }





    public onChange(value: any): void {

        let splitDate = DateHelper.splitDate(value.toString());
        if (splitDate) {
            this.selectedYear = splitDate.year.toString();
            this.selectedMonthId = splitDate.month;
            this.selectedDay = splitDate.dayOfMonth ? splitDate.dayOfMonth.toString() : '';
            this.selectedDate = splitDate;
            if (this.selectedDay) {
                this.change.emit('new NpDate({date: splitDate})');
            }
        }
    }

    /**
     * Rupak [s1]
     * @param {Event} Input
     * @param {Object} date.value
     * @method [(s2)]
     */
    selectDate(e, value) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.setValue(value);
        }
    }

    /**
     * Rupak [s2]
     * @param {Object} date.value
     * @method [(s21)+(s22)]
     */
    setValue(value: string | any) {
        // initial value
        const init_Value = value;
        if (init_Value.length < 8 || null) {
            alert('Please enter date in proper format (yyyy/mm/dd) [i.e: 2077/08/12]');
            this.setToToday();
        } else {

            if (this.checkFormat(this.reFormat(init_Value))) {
                this.patchValue(this.reFormat(init_Value));
            } else {
                alert('Please enter date in proper format (yyyy/mm/dd) [i.e: 2077/08/12]');
                this.setToToday();

            }
        }
    }

    /**
     * Rupak [s22] | [s2*]
     * @param {String} date.value
     * @param {MethodDecorator} [findParams.callback]
     * @return {DateConverter} format exactly in [yyyy/mm/dd]
     * [i.e: (2077/1/8) = (2077/01/08)]
     */
    reFormat(value) {
        let temp = value.split('/');
        // month
        if (temp[1].length === 1) {
            temp[1] = this.padLeadingZeros(temp[1], 2);
        }
        // day
        if (temp[2].length === 1) {
            temp[2] = this.padLeadingZeros(temp[2], 2);
        }
        // console.log(temp[0]+'/'+temp[1]+'/'+temp[2])
        return temp[0] + '/' + temp[1] + '/' + temp[2]; // return in yyyy/mm/dd
    }

    /**
     * Rupak [s221] | [s22*]
     * @param {String} date.value
     * @param {Number} size
     * @return {string} prefixing value with 0
     */
    padLeadingZeros(num: any, size: any) {
        let s = num + '';
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    }

    /**
     * Rupak [s21]  | [s2*]
     * @param {String} date.value
     * @return {DateConverter} format exactly in [yyyy/mm/dd]
     * forbid format like (ssss/12/12), (102/122/122) etc
     */
    checkFormat(value) {
        // moment module(3rd Party) is used to validate.
        return moment.DateTime.fromFormat(value, 'YYYY/MM/DD');
    }

    /**
     * Rupak [s3]
     * @param {String} date.value
     * final method
     */
    patchValue(value: string | any): void {
        value = DateConverter.nepDateFormat(value);
        this.value = value ? value : '';
        this.selectedYear = value.yearBs;
        this.selectedMonthId = value.monthBs.id;
        // console.log(this.selectedMonthId)
        this.selectDay(value.dayBs.day.toString(), value.dayBs.weekDay.id.toString());
    }

    /**
     * Rupak [s4]
     * public method to set current date
     */
    public setToToday() {
        let npDateNow = DateConverter.ADtoBS(new Date());
        this.selectedYear = npDateNow.yearBs;
        this.selectedMonth = npDateNow.monthBs;
        this.selectDay(npDateNow.dayBs.day.toString(), npDateNow.dayBs.weekDay.id.toString());
        this.selectedDate = {
            year: parseInt(this.selectedYear),
            month: this.selectedMonth.id,
            dayOfMonth: parseInt(this.selectedDay)
        };
    }

    writeValue(value: string | any): void {
        //
        let newValue = DateConverter.nepDateFormat(value);
        let day = new NepaliDate(value);
        let weekDay = day.getDay() + 1;
        let date = day.format('D');
        // console.log(day.format('d'))
        // console.log(day.getDay())
        // console.log(day.format('D'))
        // console.log(newValue)
        this.value = value ? newValue : '';
        if (newValue !== null && newValue !== undefined) {
            this.selectedYear = newValue.yearBs;
            this.selectedMonthId = newValue.monthBs.id;
            // console.log(this.selectedMonthId)
            this.selectDay(date.toString(), weekDay.toString());
        }
        if (newValue === undefined) {

            this.selectTodayDate();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    //Properties End

    ngOnInit() {
        this.textBox = <HTMLInputElement>document.getElementById('npDatePicker');
        // this.textBox.autocomplete = "off";
        let textBoxValue = this.textBox.value;
        if (textBoxValue) {
            let splitedDate: splitedDate = DateHelper.splitDate(textBoxValue);
            this.selectedYear = splitedDate.year.toString();
            this.selectedMonthId = splitedDate.month;
            this.selectedDay = splitedDate.dayOfMonth.toString();
        } else {
            let enDateNow = new Date();
            let npDateNow: NpDate = DateConverter.ADtoBS(enDateNow);

            this.selectedYear = npDateNow.yearBs;
            this.selectedMonth = npDateNow.monthBs;
        }
        this.selectedDate = {
            year: parseInt(this.selectedYear),
            month: this.selectedMonth.id,
            dayOfMonth: parseInt(this.selectedDay)
        };

    }

    public selectTodayDate() {
        let npDateNow = DateConverter.ADtoBS(new Date());

        this.selectedYear = npDateNow.yearBs;
        this.selectedMonth = npDateNow.monthBs;
        this.selectDay(npDateNow.dayBs.day.toString(), npDateNow.dayBs.weekDay.id.toString());

        this.selectedDate = {
            year: parseInt(this.selectedYear),
            month: this.selectedMonth.id,
            dayOfMonth: parseInt(this.selectedDay)
        };
    }

    selectDay(day: string, weekDay: string) {
        if (day && weekDay) {
            this.selectedDay = day;
            this.selectedWeekDay = weekDay;

            this.selectedDate = {
                year: parseInt(this.selectedYear),
                month: this.selectedMonth.id,
                dayOfMonth: parseInt(this.selectedDay)
            };

            let selectedDate = new NpDate({
                date: this.selectedYear + '/' + this.selectedMonth.id + '/' + this.selectedDay,
                dateFormat: this.dateFormat
            });
            this.value = selectedDate.toString();
            let formDate = this.selectedYear + '/' + this.selectedMonth.id + '/' + this.selectedDay; //added by dilip
            if (this.onChange) {
                this.onChange(formDate);
            }
            let ad = DateConverter.BStoAD(this.selectedYear + '/' + this.selectedMonth.id + '/' + this.selectedDay);
            // let bs = DateConverter.ADtoBS(ad);
            let bs = selectedDate;
            // this.change.emit(bs);
        }
    }

    incrementMonth() {
        if (this.selectedMonth && this.selectedYear && parseInt(this.selectedYear) <= 2099) {
            if (this.selectedMonth.id === 12) {
                if (parseInt(this.selectedYear) < 2099) {
                    this.selectedYear = (parseInt(this.selectedYear) + 1).toString();
                    this.selectedMonth = DateModel.monthDataNp.find(month => month.id === this.selectedMonth.id - 11);
                }
            } else {
                this.selectedMonth = DateModel.monthDataNp.find(month => month.id === this.selectedMonth.id + 1);
            }
            let totalDaysInMonth = DateModel.nepaliDateData[parseInt(this.selectedYear) % 2000][this.selectedMonth.id - 1];
            if (parseInt(this.selectedDay) > totalDaysInMonth) {
                this.selectedDay = totalDaysInMonth.toString();
            }
            // this.setMonthCallander();

        }
    }

    decrementMonth() {
        if (this.selectedMonth && this.selectedYear && parseInt(this.selectedYear) >= 2000) {
            if (this.selectedMonth.id === 1) {
                if (parseInt(this.selectedYear) > 2000) {
                    this.selectedYear = (parseInt(this.selectedYear) - 1).toString();
                    this.selectedMonth = DateModel.monthDataNp.find(month => month.id === this.selectedMonth.id + 11);
                }
            } else {
                this.selectedMonth = DateModel.monthDataNp.find(month => month.id === this.selectedMonth.id - 1);
            }
            let totalDaysInMonth = DateModel.nepaliDateData[parseInt(this.selectedYear) % 2000][this.selectedMonth.id - 1];
            if (parseInt(this.selectedDay) > totalDaysInMonth) {
                this.selectedDay = totalDaysInMonth.toString();
            }
            // this.setMonthCallander();

        }
    }

    arrayOne(n: number): any[] {
        return Array(n);
    }

    // }
    public openMiti() {
        this.onTouched();
        // document.getElementById('npDatePicker').click()
    }

    //   @HostListener("document:click", ["$event"]) clickout(event) {
    //     console.log(event)
    //     if (event.target.id !== "keyword") {
    //         // this.suggestionsIsOpen = false;
    //
    //         DateConverter.nepDateFormat(this.value);
    //         let year = this.value.substring(0, 4);
    //         let months = this.value.substring(5, 6);
    //         let day = this.value.substring(7, 9)
    //         this.selectedDate = {
    //           year: parseInt(year),
    //           month: 1,
    //           dayOfMonth: parseInt(day)
    //         };
    //     }

    private setMonthCallander() {
        let selectedYear: number;
        let selectedMonth: number;

        if (this.selectedYear) {
            selectedYear = parseInt(this.selectedYear);
        } else {
            selectedYear = 2000;
        }

        if (this.selectedMonth) {
            selectedMonth = this.selectedMonth.id;
        } else {
            selectedMonth = 1;
        }

        let startWeekDay = DateHelper.getNepaliWeekDay(selectedYear, selectedMonth, 1);
        let totalDays = DateModel.nepaliDateData[selectedYear % 2000][selectedMonth - 1];

        let daysData: any[] = [];
        for (let i = 1; i < startWeekDay; i++) {
            daysData.push({ day: '', dayOfWeek: '', enabled: false });
        }
        let weekTrack = startWeekDay;
        for (let i = 0; i < totalDays; i++) {
            let day = {
                day: (i + 1),
                dayOfWeek: weekTrack,
                enabled: true
            };
            if (weekTrack < 7) {
                weekTrack++;
            } else {
                weekTrack = 1;
            }
            daysData.push(day);
        }
        let lastRowCellCount = (daysData.length % 7);
        let remaningEmptyCells = 7 - (lastRowCellCount === 0
            ? 7
            : lastRowCellCount);
        for (let i = 0; i < remaningEmptyCells; i++) {
            daysData.push({ day: '', dayOfWeek: '', enabled: false });
        }
        this.monthCalanderData = daysData;
    }


}
