import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DateTimeService} from '@app/shared/common/timing/date-time.service';
import {DateSelectsServiceProxy} from '@shared/service-proxies/service-proxies';
import {ThemesLayoutBaseComponent} from '../themes/themes-layout-base.component';

@Component({
    selector: 'calendar-button',
    templateUrl: './calendar-button.component.html',
    styleUrls: ['./calendae-button.component.css']
})

export class CalendarButtonComponent extends ThemesLayoutBaseComponent implements OnInit {
    @Input() customStyle = 'btn btn-icon btn-dropdown btn-clean btn-lg mr-1';
    @Input() isDropup = false;
    form: FormGroup;

    public constructor(
        injector: Injector,
        _dateTimeService: DateTimeService,
        private _proxy: DateSelectsServiceProxy,
        private _fb: FormBuilder
    ) {
        super(injector, _dateTimeService);
    }

    ngOnInit() {
        this.form = this._fb.group({
            selectDate: ['']
        });
    }

    select() {

    }
}
