<ng-template #keyDialog let-dialog let-dialogConfig="dialogConfig">
    <fd-dialog [dialogConfig]="dialogConfig" [dialogRef]="dialog">
        <fd-dialog-header>
            <h1 fd-title id="fd-dialog-header-10">Shortcut Keys</h1>
        </fd-dialog-header>

        <fd-dialog-body class="grid-container">
            <div class="grid-item">
                <p class="key-leval" id="fd-dialog-body-10" role="dialog">Cancel</p>
            </div>
            <div class="grid-item key-position" class="position-item">
                <button class="key-btn">
                    <span class="key-shadow"></span>
                    <span class="key-edge"></span>
                    <span class="key-front text"> Esc </span>
                </button>
            </div>
            <div class="grid-item">
                <p class="key-leval" id="fd-dialog-body-10" role="dialog">Create</p>
            </div>
            <div class="grid-item key-position">
                <button class="key-btn">
                    <span class="key-shadow"></span>
                    <span class="key-edge"></span>
                    <span class="key-front text"> Alt </span>
                </button>
                <span class="key-plus">+</span>
                <button class="key-btn">
                    <span class="key-shadow"></span>
                    <span class="key-edge"></span>
                    <span class="key-front text"> C </span>
                </button>
            </div>

            <div class="grid-item">
                <p class="key-leval" id="fd-dialog-body-10" role="dialog">Delete</p>
            </div>
            <div class="grid-item key-position">
                <button class="key-btn">
                    <span class="key-shadow"></span>
                    <span class="key-edge"></span>
                    <span class="key-front text"> Alt </span>
                </button>
                <span class="key-plus">+</span>
                <button class="key-btn">
                    <span class="key-shadow"></span>
                    <span class="key-edge"></span>
                    <span class="key-front text"> D </span>
                </button>
            </div>

            <div class="grid-item">
                <p class="key-leval" id="fd-dialog-body-10" role="dialog">Pdf</p>
            </div>
            <div class="grid-item key-position">
                <button class="key-btn">
                    <span class="key-shadow"></span>
                    <span class="key-edge"></span>
                    <span class="key-front text"> Alt </span>
                </button>
                <span class="key-plus">+</span>
                <button class="key-btn">
                    <span class="key-shadow"></span>
                    <span class="key-edge"></span>
                    <span class="key-front text"> P </span>
                </button>
            </div>

            <div class="grid-item">
                <p class="key-leval" id="fd-dialog-body-10" role="dialog">Save</p>
            </div>
            <div class="grid-item key-position">
                <button class="key-btn">
                    <span class="key-shadow"></span>
                    <span class="key-edge"></span>
                    <span class="key-front text"> Alt </span>
                </button>
                <span class="key-plus">+</span>
                <button class="key-btn">
                    <span class="key-shadow"></span>
                    <span class="key-edge"></span>
                    <span class="key-front text"> S </span>
                </button>
            </div>
        </fd-dialog-body>
    </fd-dialog>
</ng-template>
