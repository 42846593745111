<div class="card card-custom">
    <div class="card-header align-items-center border-0">
        <h3 class="card-title align-items-start fx-column">
            <span class="fw-bolder text-dark">
                Interest
            </span>
        </h3>
        <div class="card-toolbar">
            <button (click)="interestTable.reload()" class="btn btn-primary"><i
                    class="pi pi-refresh"></i> Refresh
            </button>
        </div>
    </div>
    <div [perfectScrollbar] class="card-body">
        <div class="table-scrollable table-scrollable-borderless">
            <table class="table table-hover table-light">
                <thead>
                <tr class="uppercase">
                    <th colspan="2">
                        MEMBER
                    </th>
                    <th>
                        NAME
                    </th>
                    <th>
                        RATE
                    </th>
                    <th>
                        INTEREST RATE
                    </th>

                </tr>
                </thead>
                <tr *ngFor="let m of interestTable.interest; let index = index">
                    <td class="pl-0">
                        <div class="symbol symbol-50 symbol-light mt-2 mr-2">
                            <div class="symbol-label">
                                <img alt="photo"
                                     class="h-75 align-self-end"
                                     src="/assets/metronic/assets/media/svg/avatars/00{{index + 1}}.svg">
                            </div>
                        </div>
                    </td>
                    <td class="pl-0">
                        <a class="text-dark fw-bolder text-hover-primary mb-1 font-size-lg"
                           href="javascript:">{{m.name}}</a>
                    </td>
                    <td>
                        {{m.name}}
                    </td>
                    <td>
                        {{m.rate}}
                    </td>
                    <td>
                        {{m.interestRate}}
                    </td>

                </tr>
            </table>
        </div>
    </div>
</div>
