export enum keyCodeEnum {
    ESCAPE_KEYCODE = 27,
    CONTROL_KEYCODE = 17,
    ALT_KEYCODE = 18,
    TAB_KEYCODE = 9,
    SHIFT_KEYCODE = 16,
    BACKSPACE_KEYCODE = 8,
    ARROW_UP = 38,
    ARROW_LEFT = 39,
    ARROW_RIGHT = 37,
    ARROW_DOWN = 40,
    SPACE_BAR = 32,
    ENTER_KEY = 13,

    KEY_Q = 81,
    KEY_W = 87,
    KEY_A = 65,
    KEY_B = 66,
    KEY_C = 67,
    KEY_F = 70,
    KEY_G = 71,
    KEY_H = 72,
    KEY_I = 73,
    KEY_J = 74,
    KEY_K = 75,
    KEY_L = 76,
    KEY_M = 77,
    KEY_N = 78,
    KEY_O = 79,
    KEY_P = 80,
    KEY_R = 82,
    KEY_T = 84,
    KEY_S = 83,
    KEY_D = 68,
    KEY_U = 85,
    KEY_V = 86,
    KEY_X = 88,
    KEY_Y = 89,
    KEY_Z = 90,
}
