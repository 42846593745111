<a *ngIf="chatMessageType === 'image'" [href]="chatMessage" target="_blank">
    <img [src]="chatMessage" class="chat-image-preview"/>
</a>
<a *ngIf="chatMessageType === 'file'" [href]="chatMessage" class="chat-file-preview" target="_blank">
    <i class="pi pi-file"></i>
    {{fileName}}
    <i class="pi pi-download pull-right"></i>
</a>
<a *ngIf="chatMessageType === 'link'" [href]="chatMessage" class="chat-link-message" target="_blank">
    <i class="pi pi-link"></i>
    {{chatMessage}}
</a>
<span *ngIf="chatMessageType === 'text'" [innerHTML]="chatMessage">
</span>
