<div class="card card-custom">
    <div class="card-header align-items-center border-0">
        <h3 class="card-title align-items-start fx-column">
            <span class="font-weight-bolder text-dark">
                Top Vendor By Sales Amount
            </span>
        </h3>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col" class="max-height-200px">

            </div>
        </div>
    </div>
</div>
