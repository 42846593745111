import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UrlService {

    constructor() {
    }

    route(url) {
        return '/';
    }
}
