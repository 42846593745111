<!-- <div dropdown class="dropdown dropdown-language" aria-haspopup="true"  [dropup]="isDropup">
    <div dropdownToggle class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" aria-expanded="false">
        <div [class]="customStyle">
            <i
            class="pi pi-calendar"></i>
        </div>
    </div>
    <div *dropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right" [attr.aria-label]="l('Languages')">
        <perfect-scrollbar>
            <form [formGroup]="form" autocomplete="off">
                <div >
                    <div class="container p-5">

                        <np-datepicker [todayBtn]="true" [formControl]="form.controls['selectDate']" ngDefaultControl [dateFormat]="'yyyy/MM/dd'" [placeholder]="'yyyy/MM/dd'"></np-datepicker>

                    </div>

<div class="d-flex justify-content-center">
    <button class="btn btn-primary"  (click)="save()">Save</button>

</div>
                </div>

            </form>
    </perfect-scrollbar>
    </div>
</div> -->
<div class="topbar-item pt-2 d-none d-sm-block">
    <div class="app-header">
        <div class="app-header-left">
            <form class="search-wrapper" onsubmit="event.preventDefault();" role="search">
                <input (completeMethod)="searchMenuItem($event)" (ngModelChange)="searchNg()" [(ngModel)]="selected"
                       [minLength]="1"
                       [ngModelOptions]="{standalone: true}" [value]="selected" autofocus
                       class="search-input" placeholder="Search" required type="search">
                <span class="hide__search_icon">
               <svg enable-background="new 0 0 512.392 512.392" height="18" id="Layer_1" viewBox="0 0 512.392 512.392"
                    width="18" xmlns="http://www.w3.org/2000/svg"><g><path
                       d="m211.196 422c-116.346 0-211-94.654-211-211s94.654-211 211-211 211 94.654 211 211-94.654 211-211 211zm0-382c-94.29 0-171 76.71-171 171s76.71 171 171 171 171-76.71 171-171-76.71-171-171-171zm295.143 466.534c7.81-7.811 7.81-20.475 0-28.285l-89.5-89.5c-7.811-7.811-20.475-7.811-28.285 0s-7.81 20.475 0 28.285l89.5 89.5c3.905 3.905 9.024 5.857 14.143 5.857s10.236-1.952 14.142-5.857z"/></g></svg>
            </span>
            </form>
        </div>
        <div [class.search__dropdown--open]="isMenuSearchActive"
             [class.search_close]="!suggestionsIsOpen"
             class="list-group"
        >

            <a (click)="hideMenu()" *ngFor="let item of searchMenuResults" [routerLink]="[item.value]"
               class="list-group-item list-group-item-action" routerLinkActive="router-link-active">{{item.name}}</a>
        </div>
    </div>
</div>
<!-- <div  class="topbar-item pt-2">
    <p-autoComplete [(ngModel)]="selected" class="search-wrapper" [suggestions]="searchMenuResults" (completeMethod)="searchMenuItem($event)"
    *ngIf="isMenuSearchActive" (onSelect)="selectMenuItem($event)" field="name"
    placeholder="{{'QuickNav' | localize}}" [minLength]="1" inputStyleClass="form-control menuSearchBarInput"
    styleClass="menuSearchBarContainer"
    >
</p-autoComplete>
</div> -->
<!-- <p-autoComplete [(ngModel)]="selected" class="search-wrapper" [suggestions]="searchMenuResults" (completeMethod)="searchMenuItem($event)"
*ngIf="isMenuSearchActive" (onSelect)="selectMenuItem($event)" field="name"
placeholder="{{'QuickNav' | localize}}" [minLength]="1" inputStyleClass="form-control menuSearchBarInput"
styleClass="menuSearchBarContainer"
>
</p-autoComplete>  -->
<!-- <p-autoComplete [(ngModel)]="selected" [suggestions]="searchMenuResults" (completeMethod)="searchMenuItem($event)"
    *ngIf="isMenuSearchActive" (onSelect)="selectMenuItem($event)" field="name"
    placeholder="{{'QuickNav' | localize}}" [minLength]="1" inputStyleClass="form-control menuSearchBarInput"
    styleClass="menuSearchBarContainer">
</p-autoComplete>
{{searchMenuResults|json}} -->
