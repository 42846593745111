import {Component, Injector, OnInit} from '@angular/core';
import {ReportingServiceProxy, TenantDashboardServiceProxy} from '@shared/service-proxies/service-proxies';
import {DashboardChartBase} from '../dashboard-chart-base';
import {WidgetComponentBaseComponent} from '../widget-component-base';

class DailySalesLineChart extends DashboardChartBase {
    // chartData: any[];
    dailySales: Array<any>;

    // scheme: any = {
    //     name: "green",
    //     selectable: true,
    //     group: "Ordinal",
    //     domain: ["#34bfa3"],
    // };

    constructor(private _dashboardService: TenantDashboardServiceProxy) {
        super();
    }

    init() {
        this.reload();
    }


    reload() {

        this.showLoading();
        // this._dashboardService.getDailySales(null,null).subscribe(result => {
        //     // this.dailySales = result.dailySales;

        // this.showLoading();
        // this._dashboardService.getRealDashboardData().subscribe(result => {
        //     this.dailySales = result.dailySales;
        //     this.hideLoading();
        // });
    }
}


// export var

@Component({
    selector: 'app-widget-daily-sales',
    templateUrl: './widget-daily-sales.component.html',
    styleUrls: ['./widget-daily-sales.component.css']
})
export class WidgetDailySalesComponent extends WidgetComponentBaseComponent implements OnInit {

    dailySalesLineChart: DailySalesLineChart;

    dailySales: any;
    showSalesXAxis = true;
    showSalesYAxis = true;
    Salesgradient = false;
    showSalesLegend = true;
    showSalesXAxisLabel = true;
    animations = true;
    showGridLines = true;
    barPadding = 5;
    tooltipDisabled = false;
    xAxisSalesLabel = '';
    showSalesYAxisLabel = true;
    yAxisSalesLabel = '';
    viewSales: any[] = [900, 420];
    salesColorScheme = {
        domain: ['#5AA454', '#0a6ed1', '#ffa800', '#c9f7f5', '#800000']
    };

    constructor(injector: Injector, private _dashboardService: TenantDashboardServiceProxy, private _proxy: ReportingServiceProxy) {
        super(injector);
        this.dailySalesLineChart = new DailySalesLineChart(this._dashboardService);
    }

    ngOnInit() {
        this.dailySalesLineChart.init();
        // this._dashboardService.getDailySales("","").subscribe(data=>{
        //
        //     this.dailySales=data;
        //
        // })
        this._proxy.getFinancialYears().subscribe(data => {
            this._dashboardService.getRealDashboardData(
                data.toDate, data.fromDate
            ).subscribe(data => {



            });
        });

    }
}


