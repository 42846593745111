<div #mySettingsModal="bs-modal" (onShown)="onShown()" [config]="{backdrop: 'static'}" appBsModal aria-hidden="true"
     aria-labelledby="myLargeModalLabel"
     class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #mySettingsModalForm="ngForm" (ngSubmit)="save()" *ngIf="active">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"MySettings" | localize}}</span>
                    </h5>
                    <button (click)="close()" [attr.aria-label]="l('Close')" class="close" type="button">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div *ngIf="user" class="modal-body">
                    <tabset>
                        <tab heading="{{'Profile' | localize}}">
                            <div class="form-group">
                                <label for="Name">{{"FirstName" | localize}} *</label>
                                <input #nameInput="ngModel" [(ngModel)]="user.name" class="form-control" id="Name"
                                       maxlength="64"
                                       name="Name" required type="text">
                                <validation-messages [formCtrl]="nameInput"></validation-messages>
                            </div>

                            <div class="form-group">
                                <label for="Surname">{{"Surname" | localize}} *</label>
                                <input #surnameInput="ngModel" [(ngModel)]="user.surname" class="form-control"
                                       id="Surname"
                                       maxlength="64" name="Surname" required type="text">
                                <validation-messages [formCtrl]="surnameInput"></validation-messages>
                            </div>

                            <div class="form-group">
                                <label for="EmailAddress">{{"EmailAddress" | localize}} *</label>
                                <input #emailAddressInput="ngModel" [(ngModel)]="user.emailAddress" class="form-control"
                                       email
                                       id="EmailAddress" maxlength="256" name="EmailAddress" required
                                       type="email">
                                <validation-messages [formCtrl]="emailAddressInput"></validation-messages>
                            </div>
                            <div *ngIf="smsEnabled">
                                <div class="form-group">
                                    <label>{{"PhoneNumber" | localize}}</label>
                                    <div class="input-group">
                                        <input [(ngModel)]="user.phoneNumber" class="form-control" maxlength="32"
                                               name="PhoneNumber" type="text">

                                        <span class="input-group-btn" id="SpanSmsVerificationUnverified">
                                            <button (click)="smsVerify()"
                                                    [disabled]="isPhoneNumberConfirmed && savedPhoneNumber == user.phoneNumber"
                                                    class="btn btn-primary"
                                                    id="btnSmsVerification"
                                                    tooltip="{{'YourPhoneNumberIsNotVerified' | localize}}"
                                                    type="button">
                                                <i class="pi pi-exclamation-triangle"></i>{{'Verify' | localize}}
                                            </button>
                                        </span>

                                        <div *ngIf="isPhoneNumberConfirmed && savedPhoneNumber == user.phoneNumber"
                                             class="input-group-append" id="SpanSmsVerificationVerified">
                                            <span class="input-group-text"
                                                  tooltip="{{'YourPhoneNumberIsVerified' | localize}}">
                                                <i [attr.aria-label]="l('Verified')"
                                                   class="la la-check label-success"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="UserName">{{"UserName" | localize}} *</label>
                                <input #userNameInput="ngModel" [(ngModel)]="user.userName"
                                       [disabled]="!canChangeUserName"
                                       class="form-control" id="UserName" maxlength="256"
                                       name="UserName" required type="text">
                                <span *ngIf="!canChangeUserName"
                                      class="form-text text-muted">{{"CanNotChangeAdminUserName" | localize}}</span>
                                <validation-messages [formCtrl]="userNameInput"></validation-messages>
                            </div>

                            <div *ngIf="showTimezoneSelection" class="form-group">
                                <label for="Timezone">{{"Timezone" | localize}}</label>
                                <timezone-combo [(ngModel)]="user.timezone"
                                                defaultTimezoneScope="{{defaultTimezoneScope}}"
                                                name="Timezone"></timezone-combo>
                            </div>
                        </tab>
                        <tab *ngIf="!isMultiTenancyEnabled || isTwoFactorLoginEnabledForApplication"
                             heading="{{'TwoFactorLogin' | localize}}">
                            <div>
                                <h4>
                                    Google Authenticator
                                </h4>

                                <div [hidden]="!isGoogleAuthenticatorEnabled">
                                    <div>
                                        {{"ScanQrCodeWithYourMobileApp" | localize}}
                                    </div>
                                    <div class="text-center">
                                        <img [src]="user.qrCodeSetupImageUrl"/>
                                    </div>
                                    <small>
                                        {{"GoogleAuthenticatorReferrerLink" | localize}}: <a
                                            href="https://support.google.com/accounts/answer/1066447"
                                            rel="noopener noreferrer"
                                            target="_blank">Google Authenticator</a>
                                    </small>
                                </div>

                                <div [hidden]="isGoogleAuthenticatorEnabled">
                                    <button (click)="updateQrCodeSetupImageUrl()" class="btn btn-success"
                                            type="button">{{"Enable" | localize}}</button>
                                </div>

                                <div [hidden]="!isGoogleAuthenticatorEnabled">
                                    <button (click)="disableGoogleAuthenticator()" class="btn btn-danger"
                                            type="button">{{"Disable" | localize}}</button>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <button (click)="close()" [disabled]="saving" class="btn btn-light-primary font-weight-bold"
                            type="button">{{"Cancel" | localize}}</button>
                    <button [disabled]="!mySettingsModalForm.form.valid || saving"
                            class="btn btn-primary font-weight-bold"
                            type="submit"><i class="pi pi-save"></i>
                        <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
<smsVerificationModal #smsVerificationModal (modalSave)="changePhoneNumberToVerified()" *ngIf="user"
                      [newPhoneNumber]="user.phoneNumber"></smsVerificationModal>
