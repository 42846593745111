import {Injectable} from '@angular/core';
import {BehaviorSubject, distinctUntilChanged, map, Observable, timer} from 'rxjs';
import {ISuktasPaginator} from './paginator.component';

@Injectable({
    providedIn: 'root'
})
export class PaginatorService {
    private data: ISuktasPaginator = {
        skipCount: 0,
        itemSize: 50
    };

    private paginatorsSubject$: BehaviorSubject<ISuktasPaginator> = new BehaviorSubject(this.data);
    readonly items$: Observable<ISuktasPaginator> = this.paginatorsSubject$.asObservable().pipe(distinctUntilChanged());
    private countSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.skipCount);
    readonly skipCount$: Observable<number> = this.countSubject$.asObservable();
    private itemSizeSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.itemSize);
    readonly itemSize$: Observable<number> = this.itemSizeSubject$.asObservable();

    constructor() {
    }

    get paginator(): Readonly<ISuktasPaginator> {
        return this.data;
    }

    get skipCount(): number {
        return this.data.skipCount;
    }

    get itemSize(): number {
        return this.data.itemSize;
    }

    add(paginator: ISuktasPaginator): Observable<ISuktasPaginator> {
        return timer(350).pipe(map(() => {
            this.paginatorsSubject$.next(paginator);
            return paginator;
        }));
    }
}
