import {Component, Injector, OnInit} from '@angular/core';
import {DashboardRealDataGeneratorServiceProxy} from '@shared/service-proxies/service-proxies';
// import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import {DashboardChartBase} from '../dashboard-chart-base';
import {WidgetComponentBaseComponent} from '../widget-component-base';

class MeetingScheduleTable extends DashboardChartBase {
    meetingSchedule: any;

    constructor(private _dashboardService: DashboardRealDataGeneratorServiceProxy) {
        super();
    }

    // init() {
    //   this.reload();
    // }

    reload() {
        this.showLoading();
        this._dashboardService
            .getDashboardSummary('2075/10/10', '2079/12/30')
            .subscribe(result => {
                //
                this.meetingSchedule = result.meetingSchedule;
                this.hideLoading();
            });
    }

}

@Component({
    selector: 'app-widget-meeting-schedule',
    templateUrl: './widget-meeting-schedule.component.html',
    styleUrls: ['./widget-meeting-schedule.component.css']
})
export class WidgetMeetingScheduleComponent extends WidgetComponentBaseComponent implements OnInit {

    meetingScheduleTable: MeetingScheduleTable;

    constructor(injector: Injector,
                private _dashboard: DashboardRealDataGeneratorServiceProxy) {
        super(injector);
        this.meetingScheduleTable = new MeetingScheduleTable(this._dashboard);
    }

    ngOnInit(): void {
        this.meetingScheduleTable.reload();
    }


}
