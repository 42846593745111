<div [dropup]="isDropup" aria-expanded="true" class="topbar-item" container="{{isDropup ? null: 'body'}}"
     data-toggle="dropdown" dropdown>
    <div [class]="customStyle" dropdownToggle id="notificationIcon">
        <i *ngIf="unreadNotificationCount" class="fa-duotone fa-envelope">

        </i>
        <span *ngIf="unreadNotificationCount" class="label label-warning unread-notification-count">
            {{unreadNotificationCount}}
        </span>
        <i *ngIf="!unreadNotificationCount" class="fa-duotone fa-bell"></i>
    </div>
    <div *dropdownMenu
         class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-lg">
        <perfect-scrollbar>
            <div
                    [ngStyle]="{ 'background': 'url(' + appRootUrl() + 'assets/metronic/themes/images/bg/bg-1.jpg)', 'background-size': 'cover'}"
                    class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top">
                <h4 class="d-flex flex-center rounded-top">
                    <span class="text-white">{{"Notifications" | localize}}</span>
                    <span
                            class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">{{unreadNotificationCount}}
                        {{"New" | localize}}</span>
                </h4>
            </div>
            <div>
                <div class="row m-4">
                    <div class="text-left col-md-8">
                        <a (click)="setAllNotificationsAsRead(); $event.preventDefault(); $event.stopPropagation();"
                           *ngIf="unreadNotificationCount"
                           href="javascript:"
                           id="setAllNotificationsAsReadLink">{{"SetAllAsRead" | localize}}</a>
                    </div>
                    <div class="text-right col-md-4">
                        <a (click)="openNotificationSettingsModal()" class="text-right" href="javascript:"
                           id="openNotificationSettingsModalLink">{{"Settings" | localize}}</a>
                    </div>
                </div>
                <hr/>

                <div class="navi navi-hover my-4 ps" class="height-300px overflow-hidden;" data-height="300"
                     data-mobile-height="200"
                     data-scroll="true">

                    <a (click)="gotoUrl(notification.url)" *ngFor="let notification of notifications"
                       [ngClass]="{'user-notification-item-clickable': notification.url,'user-notification-item-unread': notification.isUnread}"
                       class="navi-item">
                        <div class="navi-link">
                            <div class="navi-icon mr-2">
                                <i class="{{notification.icon}} {{notification.iconFontClass}}"></i>
                            </div>
                            <div class="navi-text">
                                <div class="font-weight-bold">
                                    {{notification.text}}
                                </div>
                                <div class="text-muted">
                                    {{notification.time | luxonFromNow}}
                                </div>
                                <span
                                        (click)="setNotificationAsRead(notification); $event.preventDefault(); $event.stopPropagation();"
                                        *ngIf="notification.isUnread"
                                        class="btn btn-link-success set-notification-as-read">
                                    {{"SetAsRead" | localize}}
                                </span>
                            </div>
                        </div>
                    </a>
                    <span *ngIf="!notifications.length" class="notification-empty-text p-3">
                        {{"ThereAreNoNotifications" | localize}}
                    </span>
                </div>
                <hr *ngIf="notifications.length"/>
                <div *ngIf="notifications.length" class="m-4">
                    <div class="text-center"><a routerLink="notifications">{{"SeeAllNotifications" | localize}}</a>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</div>
