<div
    #entityChangeDetailModal="bs-modal"
    [config]="{ backdrop: 'static' }"
    appBsModal
    aria-hidden="true"
    aria-labelledby="entityChangeDetailModal"
    class="modal fade"
    role="dialog"
    tabindex="-1"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div *ngIf="entityChange" class="modal-header">
                <h4 class="modal-title">
                    <span>{{ "Detail" | localize }} - {{ entityChange.entityTypeFullName | localize }}</span> <br/>
                    <small [innerHTML]="l('CreatedAtByUser', entityChange.changeTime, entityChange.userName)"></small>
                </h4>
                <button (click)="close()" [attr.aria-label]="l('Close')" class="close" type="button">
                    <i aria-hidden="true" class="ki ki-close"></i>
                </button>
            </div>
            <div class="modal-body">
                <table class="display table table-striped table-bordered table-hover dt-responsive"
                       id="EntityPropertyChangesTable">
                    <thead>
                    <tr>
                        <th>{{ "Property" | localize }}</th>
                        <th>{{ "OriginalValue" | localize }}</th>
                        <th>{{ "NewValue" | localize }}</th>
                    </tr>
                    <tr *ngFor="let propertyChange of entityPropertyChanges">
                        <td>
                            <strong>{{ propertyChange.propertyName }}</strong>
                        </td>

                        <td>{{ getPropertyChangeValue(propertyChange.originalValue, propertyChange.propertyTypeFullName) }}</td>
                        <td>{{ getPropertyChangeValue(propertyChange.newValue, propertyChange.propertyTypeFullName) }}</td>
                    </tr>
                    </thead>
                </table>
            </div>
            <div class="modal-footer">
                <button (click)="close()" class="btn btn-light-primary fw-bold"
                        type="button">{{ "Close" | localize }}</button>
            </div>
        </div>
    </div>
</div>
