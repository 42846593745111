<main class="calendar-contain">
    <section class="title-bar">
       
        <span class="title-bar__date_select">
            <div class="container-fluid">
                <div class="erow">
                    <div class="col-1">
                        <i (click)="decrementMonth()" class="bi bi-chevron-double-left"></i>
                    </div>
                    <div class="col-5 pr-0 pl-0">
                        <select [(ngModel)]="selectedYear">
                            <option *ngFor="let year of arrayOne(100); let i = index"
                                    [value]="i + 2000">{{ i + 2000 }}</option>
                        </select>
                    </div>
                    <div class="col-5 pr-0 pl-0">
                        <select [(ngModel)]="selectedMonthId">
                            <option *ngFor="let month of monthData" [value]="month.id">{{ month.abbr }}</option>
                        </select>
                    </div>
                    <div class="col-1">
                        <i (click)="incrementMonth()" class="bi bi-chevron-double-right"></i>
                    </div>
                </div>
            </div>
        </span>
        <div class="title-bar__controls">
            <span class="date_text">
                {{ replaceDate(currentYear) }} {{ currentMonth }} | {{ checkEngMonth(selectedYear, selectedMonthId) }}
                {{ engYear }}
            </span>
        </div>
    </section>


    <section class="calendar__days">
        <section class="calendar__top-bar">
            <span *ngFor="let week of weekData" class="top-bar__days">
                <span class="nepali"> {{ week.name }} </span>
                <span class="english"> {{ week.englishName }} </span>
            </span>
        </section>
        <section class="calendar__week">
            <div
                    (click)="selectDay(item.day, item.dayOfWeek)"
                    *ngFor="let item of dayData | slice: 0:7"
                    [ngClass]="{
                    'calendar__date--weekend ': item.dayOfWeek == weekend,
                    'is-active':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString(),
                    'calendar__date--current':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString()
                }"
                    class="calendar__day"
            >
                <span class="calendar__date">{{ replaceDate(item.day) }}</span>
                <span class="calendar__task">{{ toEnglishDate(selectedYear, selectedMonthId, item.day) }}</span>
            </div>
        </section>
        <section class="calendar__week">
            <div
                    (click)="selectDay(item.day, item.dayOfWeek)"
                    *ngFor="let item of dayData | slice: 7:14"
                    [ngClass]="{
                    'calendar__date--weekend ': item.dayOfWeek == weekend,
                    'is-active':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString(),
                    'calendar__date--current':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString()
                }"
                    class="calendar__day"
            >
                <span class="calendar__date">{{ replaceDate(item.day) }}</span>
                <span class="calendar__task">{{ toEnglishDate(selectedYear, selectedMonthId, item.day) }}</span>
            </div>
        </section>
        <section class="calendar__week">
            <div
                    (click)="selectDay(item.day, item.dayOfWeek)"
                    *ngFor="let item of dayData | slice: 14:21"
                    [ngClass]="{
                    'calendar__date--weekend ': item.dayOfWeek == weekend,
                    'is-active':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString(),
                    'calendar__date--current':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString()
                }"
                    class="calendar__day"
            >
                <span class="calendar__date">{{ replaceDate(item.day) }}</span>
                <span class="calendar__task">{{ toEnglishDate(selectedYear, selectedMonthId, item.day) }}</span>
            </div>
        </section>
        <section class="calendar__week">
            <div
                    (click)="selectDay(item.day, item.dayOfWeek)"
                    *ngFor="let item of dayData | slice: 21:28"
                    [ngClass]="{
                    'calendar__date--weekend ': item.dayOfWeek == weekend,
                    'is-active':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString(),
                    'calendar__date--current':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString()
                }"
                    class="calendar__day"
            >
                <span class="calendar__date">{{ replaceDate(item.day) }}</span>
                <span class="calendar__task">{{ toEnglishDate(selectedYear, selectedMonthId, item.day) }}</span>
            </div>
        </section>
        <section class="calendar__week">
            <div
                    (click)="selectDay(item.day, item.dayOfWeek)"
                    *ngFor="let item of dayData | slice: 28:35"
                    [ngClass]="{
                    'calendar__date--weekend ': item.dayOfWeek == weekend,
                    'is-active':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString(),
                    'calendar__date--current':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString()
                }"
                    class="calendar__day"
            >
                <span class="calendar__date">{{ replaceDate(item.day) }}</span>
                <span class="calendar__task">{{ toEnglishDate(selectedYear, selectedMonthId, item.day) }}</span>
            </div>
        </section>
        <section *ngIf="dayData.length > 35" class="calendar__week">
            <div
                    (click)="selectDay(item.day, item.dayOfWeek)"
                    *ngFor="let item of dayData | slice: 35:42"
                    [ngClass]="{
                    'calendar__date--weekend ': item.dayOfWeek == weekend,
                    'is-active':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString(),
                    'calendar__date--current':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString()
                }"
                    class="calendar__day today"
            >
                <span class="calendar__date">{{ replaceDate(item.day) }}</span>
                <span class="calendar__task">{{ toEnglishDate(selectedYear, selectedMonthId, item.day) }}</span>
            </div>
        </section>
    </section>
</main>

