import {Component, Injector, OnInit} from '@angular/core';
import {TenantDashboardServiceProxy} from '@shared/service-proxies/service-proxies';
import {DashboardChartBase} from '../dashboard-chart-base';
import {WidgetComponentBaseComponent} from '../widget-component-base';


class TodayReceiveLineChart extends DashboardChartBase {


    chartData: any[];
    scheme: any = {
        name: 'green',
        selectable: true,
        group: 'Ordinal',
        domain: [
            '#34bfa3'
        ]
    };

    constructor(private _dashboardService: TenantDashboardServiceProxy) {
        super();
    }

    reload() {
        this.showLoading();
        this._dashboardService;
        // .getTodayPayment()
        //   .subscribe(result => {
        //     this.init(result.todayPayment);
        //     this.hideLoading();
        //   });
    }

    init(todayReceive: any) {

    }

}


@Component({
    selector: 'app-widget-today-receive',
    templateUrl: './widget-today-receive.component.html',
    styleUrls: ['./widget-today-receive.component.css']
})
export class WidgetTodayReceiveComponent extends WidgetComponentBaseComponent implements OnInit {

    todayReceiveLineChart: TodayReceiveLineChart;

    constructor(injector: Injector,
                private _tenantdashboardService: TenantDashboardServiceProxy) {
        super(injector);
        this.todayReceiveLineChart = new TodayReceiveLineChart(this._tenantdashboardService);
    }

    ngOnInit(): void {
        this.todayReceiveLineChart.reload();
    }


}
