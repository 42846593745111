import {
    AfterViewInit,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {AppComponentBase} from '@shared/common/app-component-base';
import {BreakpointObserver} from '@angular/cdk/layout';
import {DialogService, TableComponent} from '@fundamental-ngx/core';
import {AllowIn, ShortcutInput} from 'ng-keyboard-shortcuts';
import {DataGridDialogComponent} from '../data-grid-dialog.component';
import {BehaviorSubject} from 'rxjs';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ActivatedRoute, Router} from '@angular/router';
import {takeWhile} from 'rxjs/operators';

export interface DisplayedColumn {
    key: string;
    checked: boolean;
}

@Component({
    selector: 'suktas-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.css']
})
export class DataTableComponent extends AppComponentBase implements OnInit, AfterViewInit, OnDestroy {
    @Input() tableData: any;
    @Input() loading: boolean;
    @Input() displayedColumns: string[];
    @Input() originalDisplayedColumns: DisplayedColumn[];
    @Input() pdfButton: string;
    shortcuts: ShortcutInput[] = [];
    checkboxValue = false;
    extant = true; // Observable flag
    @Output() tableEvent = new EventEmitter<any>();
    tableLength: number;
    sortHeader$: BehaviorSubject<string> = new BehaviorSubject('0null');
    drag$: BehaviorSubject<number> = new BehaviorSubject(null);
    dragFlag$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    sortedColumn$ = new BehaviorSubject<string>('');
    //dialog
    @ViewChild(TableComponent)
    tableComponent: TableComponent;

    // Responsive Table
    isTabletScreen: boolean;
    isMobileScreen: boolean;
    isDesktop = window.innerWidth > 768;

    constructor(
        injector: Injector,
        private router: Router,
        private route: ActivatedRoute,
        private breakpointObserver: BreakpointObserver,
        private _dialogService: DialogService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.subscribeWhenLayoutChanges();
    }

    /**
     * @method subscribeWhenLayoutChanges
     * Layout change automatically when subscription takes place
     */
    subscribeWhenLayoutChanges(): void {
        const layoutChanges = this.breakpointObserver.observe(['(max-width: 1199.98px) and (min-width: 768px)', '(max-width: 767.98px)']);
        layoutChanges.pipe(takeWhile(() => this.extant)).subscribe((res) => {
            this.isTabletScreen = this.breakpointObserver.isMatched('(max-width: 1199.98px) and (min-width: 768px)');
            this.isMobileScreen = this.breakpointObserver.isMatched('(max-width: 767.98px)');
        });
    }

    ngAfterViewInit() {
        this.shortcuts.push(
            {
                key: 'down',
                preventDefault: true,
                label: 'Go Down',
                description: 'Drag',
                command: () => this.dragTable(1)
            },
            {
                key: 'up',
                preventDefault: true,
                label: 'Go UP',
                description: 'Drag',
                command: () => this.dragTable(0)
            },
            {
                key: 'alt + e',
                preventDefault: true,
                label: 'Export',
                description: 'AExport',
                allowIn: [AllowIn.Input, AllowIn.Textarea, AllowIn.Select],
                command: () => this.exportToExcel()
            }
        );
    }

    dragTable(key: number) {

        const idx = this.drag$.value;
        const checked = this.dragFlag$.value;

        if (idx !== null && checked == true) {


            if (key == 1) {
                this.checkDrag(idx);
            } else {
                this.checkDragUp(idx);
            }
        } else {
            return;
        }
    }

    checkDrag(index) {

        let previousIndex = index;
        let currentIndex = index + 1;
        this.drag$.next(currentIndex);

        moveItemInArray(this.tableData, previousIndex, currentIndex);
        this.tableData.forEach((item, idx) => {
            item.id = idx + 1;
        });
    }

    checkDragUp(index) {

        let previousIndex = index;
        let currentIndex = index - 1;
        this.drag$.next(currentIndex);

        moveItemInArray(this.tableData, previousIndex, currentIndex);
        this.tableData.forEach((item, idx) => {
            item.id = idx + 1;
        });
    }

    ngOnDestroy(): void {
    }

    openDialog(): void {
        const dialogRef = this._dialogService.open(DataGridDialogComponent, {
            width: '350px',
            height: '370px',
            draggable: true,
            resizable: true,
            verticalPadding: false,
            data: {
                columns: this.originalDisplayedColumns
            }
        });

        dialogRef.afterClosed.subscribe(
            (columns) => {
                this.originalDisplayedColumns = [...columns];
                this._propagateChangeToDisplayedValue();
            },
            () => {

            }
        );
    }

    deleteSelectedData() {
        this.tableEvent.emit(this.tableData);
    }

    select(index: number, checked: boolean, size: string): void {
        this._getTable(size)[index].checked = checked;
        this._setValue(size);
    }

    selectMaster(checked: boolean, size: string): void {
        if (size === 'cozy') {
            this.checkboxValue = checked;
        }
        if (checked) {
            this._selectAll(size);
            this.dragFlag$.next(true);
        } else {
            this._deselectAll(size);
            this.dragFlag$.next(false);
        }
    }

    checkRow(i, c, s, event) {
        this.drag$.next(i);

        if (c == false) {
            this.dragFlag$.next(true);
            this.select(i, true, s);
        } else {
            this.dragFlag$.next(false);
            this.select(i, false, s);
        }
    }

    filterItems(arr, query) {
        return arr.filter(function (el) {
            return el.checked == query;
        });
    }

    exportToExcel() {

        const checkedItems = this.filterItems(this.tableData, true);
        this.exportAsXLSX(checkedItems);
    }

    onDrop(event: CdkDragDrop<string[]>) {

        moveItemInArray(this.tableData, event.previousIndex, event.currentIndex);
        this.tableData.forEach((item, idx) => {
            item.id = idx + 1;
        });
    }

    routeToEdit(id) {

        this.router.navigate(['edit', id], {relativeTo: this.route});
    }

    private _propagateChangeToDisplayedValue(): void {
        this.displayedColumns = [...this.originalDisplayedColumns.filter((_col) => _col.checked).map((_col) => _col.key)];
        this.tableComponent.reset(this.displayedColumns);
    }

    private _selectAll(size: string): void {
        this._getTable(size).forEach((row) => (row.checked = true));
    }

    private _deselectAll(size: string): void {
        this._getTable(size).forEach((row) => (row.checked = false));
    }

    private _allSelected(size: string): boolean {
        return !this._getTable(size).find((_row) => !_row.checked);
    }

    private _getTable(size: string): any[] {
        let table = this.tableData;
        return table;
    }

    private _setValue(size: string): void {
        if (size === 'cozy') {
            this.checkboxValue = this._allSelected(size);
        }
    }
}
