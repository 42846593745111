<div [perfectScrollbar] class="row row-no-padding row-col-separator-xl h-100">
    <div class="col">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <a class="card-title text-left fw-bolder text-dark font-size-h6 mb-4 text-hover-state-dark d-block"
                   href="#">
                    {{'NewSubscriptionAmount' | localize}}
                </a>
                <div class="text-left text-primary fw-bolder font-size-h2 mr-2">
                    <span *ngIf="!topStatsData">...</span>
                    <span (countoChange)="countoNewSubscriptionAmount = $event" *ngIf="topStatsData" [countFrom]="0"
                          [countTo]="topStatsData.newSubscriptionAmount" [duration]="5"
                          [step]="30"
                          counto>{{countoNewSubscriptionAmount | currency:'USD':'symbol':'1.0-0'}}</span>
                </div>
                <div class="progress progress-xs mt-7 bg-primary-o-60">
                    <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="100" class="progress-bar bg-primary"
                         class="width-100;" role="progressbar"></div>
                </div>
                <div class="text-left text-dark-75 mt-1 status-title">
                    {{selectedDateRange[0] | luxonFormat: 'D'}} -
                    {{selectedDateRange[1] | luxonFormat: 'D'}}
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <a class="card-title text-left fw-bolder text-dark font-size-h6 mb-4 text-hover-state-dark d-block"
                   href="#">
                    {{'NewTenants' | localize}}
                </a>
                <div class="text-left text-info fw-bolder font-size-h2 mr-2">
                    <span *ngIf="!topStatsData">...</span>
                    <span (countoChange)="countoNewTenantsCount = $event" *ngIf="topStatsData" [countFrom]="0"
                          [countTo]="topStatsData.newTenantsCount" [duration]="5"
                          [step]="10"
                          counto>{{countoNewTenantsCount | number:'1.0-0' }}</span>
                </div>
                <div class="progress progress-xs mt-7 bg-danger-o-60">
                    <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="100" class="progress-bar bg-info"
                         class="width-100" role="progressbar"></div>
                </div>
                <div class="text-left text-dark-75 mt-1 status-title">
                    {{selectedDateRange[0] | luxonFormat: 'D'}} -
                    {{selectedDateRange[1] | luxonFormat: 'D'}}
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <a class="card-title text-left fw-bolder text-dark font-size-h6 mb-4 text-hover-state-dark d-block"
                   href="#">
                    {{'DashboardSampleStatistics' | localize}}
                </a>
                <div class="text-left text-danger fw-bolder font-size-h2 mr-2">
                    <span *ngIf="!topStatsData">...</span>
                    <span (countoChange)="countoDashboardPlaceholder1 = $event" *ngIf="topStatsData" [countFrom]="0"
                          [countTo]="topStatsData.dashboardPlaceholder1" [duration]="5"
                          [step]="100"
                          counto>{{countoDashboardPlaceholder1 | number:'1.0-0' }}</span>
                </div>
                <div class="progress progress-xs mt-7 bg-danger-o-60">
                    <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="45" class="progress-bar bg-danger"
                         class="width-45;" role="progressbar"></div>
                </div>
                <div class="display-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm fw-bold">
                        {{'DashboardSampleStatisticsHelpText' |localize}}
                    </span>
                    <span class="text-muted font-size-sm fw-bold">
                        45%
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <a class="card-title text-left fw-bolder text-dark font-size-h6 mb-4 text-hover-state-dark d-block"
                   href="#">
                    {{'DashboardSampleStatistics' | localize}} 2
                </a>
                <div class="text-left text-success fw-bolder font-size-h2 mr-2">
                    <span *ngIf="!topStatsData">...</span>
                    <span (countoChange)="countoDashboardPlaceholder2 = $event" *ngIf="topStatsData" [countFrom]="0"
                          [countTo]="topStatsData.dashboardPlaceholder2" [duration]="5"
                          [step]="100"
                          counto>{{countoDashboardPlaceholder2 | number:'1.0-0' }}</span>
                </div>
                <div class="progress progress-xs mt-7 bg-success-o-60">
                    <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="65" class="progress-bar bg-success"
                         class="width-65;" role="progressbar"></div>
                </div>
                <div class="display-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm fw-bold">
                        {{'DashboardSampleStatisticsHelpText' |localize}}
                    </span>
                    <span class="text-muted font-size-sm fw-bold">
                        65%
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
