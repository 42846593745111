import {Component, Injector, OnInit} from '@angular/core';
import {DashboardRealDataGeneratorServiceProxy} from '@shared/service-proxies/service-proxies';
import {DashboardChartBase} from '../dashboard-chart-base';
import {WidgetComponentBaseComponent} from '../widget-component-base';


class TopFiveSalesPieChart extends DashboardChartBase {

    topFiveSales: any;

    chartData: any[] = [];
    scheme: any = {
        name: 'custom',
        selectable: true,
        group: 'Ordinal',
        domain: [
            '#00c5dc', '#ffb822', '#716aca'
        ]
    };

    constructor(private _dashboardService: DashboardRealDataGeneratorServiceProxy) {
        super();
    }

    init(data: any[]) {

        let formattedData = [];
        for (let i = 0; i < data.length; i++) {
            formattedData.push({
                'name': this.getChartItemName(i),
                'value': data[i]
            });
        }

        this.chartData = formattedData;
    }

    getChartItemName(index: number) {
        if (index === 0) {
            return 'Top Five Sales';
        }

        if (index === 1) {
            return 'Online Courses';
        }

        if (index === 2) {
            return 'Custom Development';
        }

        return 'Other';
    }

    reload() {
        this.showLoading();
        this._dashboardService
            .getDashboardSummary('2075/10/10', '2079/12/30')
            .subscribe(data => {
                // this.init(data.topFiveSales);
                this.hideLoading();
            });
    }
}

@Component({
    selector: 'app-widget-top-five-sales',
    templateUrl: './widget-top-five-sales.component.html',
    styleUrls: ['./widget-top-five-sales.component.css']
})
export class WidgetTopFiveSalesComponent extends WidgetComponentBaseComponent implements OnInit {

    topFiveSalesPieChart: TopFiveSalesPieChart;

    constructor(injector: Injector,
                private _dashboardService: DashboardRealDataGeneratorServiceProxy) {
        super(injector);
        this.topFiveSalesPieChart = new TopFiveSalesPieChart(this._dashboardService);
    }

    ngOnInit() {
        this.topFiveSalesPieChart.reload();
    }
}

