import {Component, Injector, OnInit} from '@angular/core';
import {TenantDashboardServiceProxy} from '@shared/service-proxies/service-proxies';
import {DashboardChartBase} from '../dashboard-chart-base';
import {WidgetComponentBaseComponent} from '../widget-component-base';

class OpportunitiesInleadStatusLineChart extends DashboardChartBase {


    chartData: any[];
    scheme: any = {
        name: 'green',
        selectable: true,
        group: 'Ordinal',
        domain: [
            '#34bfa3'
        ]
    };

    constructor(private _dashboardService: TenantDashboardServiceProxy) {
        super();
    }

    reload() {
        this.showLoading();
        this._dashboardService;
        // .getTodayPayment()
        //   .subscribe(result => {
        //     this.init(result.todayPayment);
        //     this.hideLoading();
        //   });
    }

    init(opportunitiesInleadStatus: any) {

    }

}

@Component({
    selector: 'app-widget-opportunities-inlead-status',
    templateUrl: './widget-opportunities-inlead-status.component.html',
    styleUrls: ['./widget-opportunities-inlead-status.component.css']
})
export class WidgetOpportunitiesInleadStatusComponent extends WidgetComponentBaseComponent implements OnInit {
    opportunitiesInleadStatusLineChart: OpportunitiesInleadStatusLineChart;


    constructor(injector: Injector,
                private _tenantdashboardService: TenantDashboardServiceProxy) {
        super(injector);
        this.opportunitiesInleadStatusLineChart = new OpportunitiesInleadStatusLineChart(this._tenantdashboardService);
    }


    ngOnInit(): void {
        this.opportunitiesInleadStatusLineChart.reload();
    }

}

