import {Component, Injector, OnInit} from '@angular/core';
import {TenantDashboardServiceProxy} from '@shared/service-proxies/service-proxies';
import {DashboardChartBase} from '../dashboard-chart-base';
import {WidgetComponentBaseComponent} from '../widget-component-base';

class SalesOrderAmountThisMonthLineChart extends DashboardChartBase {


    chartData: any[];
    scheme: any = {
        name: 'green',
        selectable: true,
        group: 'Ordinal',
        domain: [
            '#34bfa3'
        ]
    };

    constructor(private _dashboardService: TenantDashboardServiceProxy) {
        super();
    }

    reload() {
        this.showLoading();
        this._dashboardService;
        // .getTodayPayment()
        //   .subscribe(result => {
        //     this.init(result.todayPayment);
        //     this.hideLoading();
        //   });
    }

    init(salesOrderAmountThisMonth: any) {

    }

}


@Component({
    selector: 'app-widget-sales-order-amount-this-month',
    templateUrl: './widget-sales-order-amount-this-month.component.html',
    styleUrls: ['./widget-sales-order-amount-this-month.component.css']
})
export class WidgetSalesOrderAmountThisMonthComponent extends WidgetComponentBaseComponent implements OnInit {
    salesOrderAmountThisMonthLineChart: SalesOrderAmountThisMonthLineChart;


    constructor(injector: Injector,
                private _tenantdashboardService: TenantDashboardServiceProxy) {
        super(injector);
        this.salesOrderAmountThisMonthLineChart = new SalesOrderAmountThisMonthLineChart(this._tenantdashboardService);
    }


    ngOnInit(): void {
        this.salesOrderAmountThisMonthLineChart.reload();
    }

}


