<div
    #modal="bs-modal"
    (onShown)="shown()"
    [config]="{ backdrop: 'static' }"
    appBsModal
    aria-hidden="true"
    aria-labelledby="modal"
    class="modal fade"
    role="dialog"
    tabindex="-1"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 *ngIf="options" class="modal-title">
                    {{ ("ChangeLogs" | localize) + ":" + (options.entityTypeFullName | localize) + "(" + options.entityTypeDescription + ")" }}
                </h4>
                <button (click)="close()" [attr.aria-label]="l('Close')" class="close" type="button">
                    <i aria-hidden="true" class="ki ki-close"></i>
                </button>
            </div>
            <div class="modal-body">
                <!--<Primeng-TurboTable-Start>-->
                <div [busyIf]="primengTableHelper.isLoading" class="primeng-datatable-container">
                    <p-table
                        #dataTable
                        (onLazyLoad)="getRecordsIfNeeds($event)"
                        [lazy]="true"
                        [paginator]="false"
                        [responsive]="primengTableHelper.isResponsive"
                        [value]="primengTableHelper.records"
                        rows="{{ primengTableHelper.defaultRecordsCountPerPage }}"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-15">
                                    {{ "Select" | localize }}
                                </th>
                                <th>
                                    {{ "Action" | localize }}
                                </th>
                                <th>
                                    {{ "UserName" | localize }}
                                </th>
                                <th>
                                    {{ "Time" | localize }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template let-record="$implicit" pTemplate="body">
                            <tr>
                                <td>
                                    <button
                                            (click)="showEntityChangeDetails(record)"
                                            class="btn btn-outline-primary btn-sm btn-icon"
                                            title="{{ 'Select' | localize }}"
                                    >
                                        <i [attr.aria-label]="l('Select')" class="pi pi-arrow-circle-righ"></i>
                                    </button>
                                </td>
                                <td>
                                    <span class="p-column-title">{{ "Action" | localize }}</span>
                                    {{ record.changeTypeName }}
                                </td>
                                <td>
                                    <span class="p-column-title">{{ "UserName" | localize }}</span>
                                    {{ record.userName }}
                                </td>
                                <td>
                                    <span class="p-column-title">{{ "Time" | localize }}</span>
                                    {{ record.changeTime | luxonFormat: "F" }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div *ngIf="primengTableHelper.totalRecordsCount == 0" class="primeng-no-data">
                        {{ "NoData" | localize }}
                    </div>
                    <div class="primeng-paging-container">
                        <p-paginator
                            #paginator
                            (onPageChange)="getRecordsIfNeeds($event)"
                            [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                            [rows]="primengTableHelper.defaultRecordsCountPerPage"
                            [totalRecords]="primengTableHelper.totalRecordsCount"
                        >
                        </p-paginator>
                        <span class="total-records-count">
                            {{ "TotalRecordsCount" | localize: primengTableHelper.totalRecordsCount }}
                        </span>
                    </div>
                </div>
                <!--<Primeng-TurboTable-End>-->
            </div>

            <div class="modal-footer">
                <button (click)="close()" class="btn btn-light-primary fw-bold"
                        type="button">{{ "Close" | localize }}</button>
            </div>
        </div>
    </div>
</div>
<entityChangeDetailModal #entityChangeDetailModal></entityChangeDetailModal>
