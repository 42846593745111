import {Component, Injector, OnInit} from '@angular/core';
import {
    GetTotalValueOutput,
    ReportingServiceProxy,
    TenantDashboardServiceProxy
} from '@shared/service-proxies/service-proxies';
import {DashboardChartBase} from '../dashboard-chart-base';
import {WidgetComponentBaseComponent} from '../widget-component-base';
import {curveBasis} from 'd3-shape';

class DashboardTopStats extends DashboardChartBase {

    // colors = ['#00c5dc', '#f4516c','#34bfa3', '#ffb822'];
    // customColors = [
    //   { name: '1', value: '#00c5dc' },
    //   { name: '2', value: '#f4516c' },
    //   { name: '3', value: '#34bfa3' },
    //   { name: '4', value: '#ffb822' },
    //   { name: '5', value: '#00c5dc' }
    // ];

    // curve: any = curveBasis;

    totalPurchase: GetTotalValueOutput;
    totalPurchaseCounter = 0;
    totalCash: GetTotalValueOutput;
    totalCashCounter = 0;
    totalBank: GetTotalValueOutput;
    totalBankCounter = 0;
    totalSales: GetTotalValueOutput;
    totalSalesCounter = 0;
    expenses: GetTotalValueOutput;

    // totalPurchaseChange = 76; totalPurchaseChangeCounter = 0;
    // totalCashChange = 85; totalCashChangeCounter = 0;
    // totalBankChange = 45; totalBankChangeCounter = 0;
    // totalSalesChange = 57; totalSalesChangeCounter = 0;

    init(totalPurchase, totalCash, totalBank, totalSales, expenses) {
        this.totalPurchase = totalPurchase;
        this.totalCash = totalCash;
        this.totalBank = totalBank;
        this.totalSales = totalSales;
        this.expenses = expenses;
        this.hideLoading();

    }
}

@Component({
    selector: 'app-widget-top-stats',
    templateUrl: './widget-top-stats.component.html',
    styleUrls: ['./widget-top-stats.component.css']
})
export class WidgetTopStatsComponent extends WidgetComponentBaseComponent implements OnInit {

    dashboardTopStats: DashboardTopStats;
    totalPurchase: any[];
    change: any[];
    view: any[] = [700, 400];
    allData: any;
    purchaseData: any;
    cashData: any;
    salesData: any;
    bankData: any;
    expenseData: any;

    customColors = ['#00c5dc', '#f4516c', '#34bfa3', '#ffb822'];
    cardColor = '#232837';
    curve: any = curveBasis;

    constructor(injector: Injector,
                private _tenantDashboardServiceProxy: TenantDashboardServiceProxy, private _proxy: ReportingServiceProxy
    ) {
        super(injector);
        this.dashboardTopStats = new DashboardTopStats();
    }

    ngOnInit() {
        // this.loadTopStatsData();
        this._proxy.getFinancialYears().subscribe(data => {
            this._tenantDashboardServiceProxy.getRealDashboardData(
                data.toDate, data.fromDate
            ).subscribe(data => {

                this.allData = data.stats;
                this.purchaseData = data.stats.totalPurchase.name;
                this.cashData = data.stats.totalCash.name;
                this.bankData = data.stats.totalBank.name;
                this.salesData = data.stats.totalSales.name;
                this.expenseData = data.stats.expenses.name;
                this.dashboardTopStats.init(data.stats.totalPurchase, data.stats.totalCash, data.stats.totalBank, data.stats.totalSales, data.stats.expenses);
            });
        });

    }

    // loadTopStatsData() {
    //   this._tenantDashboardServiceProxy.getRealDashboardData(toDate,fromDate).subscribe((data) => {
    //     this.dashboardTopStats.init(data.totalPurchase, data.totalCash, data.totalBank, data.totalSales);
    //   });
    // }
}
