import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-uploader-expand',
    templateUrl: './uploader-expand.component.html',
    styleUrls: ['./uploader-expand.component.css']
})
export class UploaderExpandComponent implements OnInit {
    @Output() filterEvent = new EventEmitter<boolean>();
    expand: boolean;

    constructor() {
    }

    ngOnInit() {
    }

    // EXPAND ICON OPEN OR CLOSE ICON FOR FILE UPLOADER
    openFilter() {
        this.filterEvent.emit(true);
        this.expand = true;
    }

    closeFilter() {
        this.filterEvent.emit(false);
        this.expand = false;
    }

}
