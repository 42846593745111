<!-- BEGIN: Horizontal Menu -->
<div [class]="menuWrapperStyle" [options]="offcanvasOptions" id="kt_header_menu_wrapper" ktOffcanvas>
    <div [class]="containerClass">
        <div [options]="menuOptions" class="header-menu header-menu-left header-menu-mobile header-menu-layout-default"
             id="kt_header_menu" ktMenu>
            <!-- <div class="arrow-left float-left">
              <button (click)="scrollLeft()" class="button_left"><</button>
          </div> -->
            <ul class="menu-nav theme2-scroll" #widgetsContent>
                <li *ngIf="isMobileDevice()" aria-haspopup="true" class="menu-item menu-item-here menu-item-submenu">
                    <menu-search-bar></menu-search-bar>
                </li>

                <ng-container *ngFor="let item of menu.items">
                    <ng-container *ngIf="item.name" [ngTemplateOutletContext]="{ item: item, depth: 0 }"
                                  [ngTemplateOutlet]="kt_menu"></ng-container>
                </ng-container>
                <li *ngIf="!isMobileDevice()" aria-haspopup="true" class="menu-item menu-item-here menu-item-submenu">
                    <menu-search-bar></menu-search-bar>
                </li>
            </ul>
            <!-- <div class="arrow-right float-right">
               <button (click)="scrollRight()" class="button_right nav_arrowright">></button>
           </div> -->
        </div>
    </div>
</div>
<!-- END: Horizontal Menu -->

<ng-template #kt_menu let-depth="depth" let-item="item" let-parentItem="parentItem">
    <li
            *ngIf="showMenuItem(item)"
            aria-haspopup="true"
            class="{{ getItemCssClasses(item, parentItem, depth) }}"
            data-menu-toggle="click"
            routerLinkActive="menu-item-active"
    >
        <!-- if item has submenu -->
        <ng-container *ngIf="item.items.length">
            <a class="{{ getAnchorItemCssClasses(item, parentItem) }}" href="javascript:">
                <ng-container
                        [ngTemplateOutletContext]="{ item: item, parentItem: parentItem, depth: depth + 1 }"
                        [ngTemplateOutlet]="kt_menu_item_inner"
                >
                </ng-container>

                <i *ngIf="item.items.length && !parentItem"
                   class="fa-duotone fa-caret-down ml-1 mt-1 iconx"></i>
                <i *ngIf="item.items.length && parentItem" class="iconx menu-ver-arrow fa-duotone fa-caret-right"></i>
            </a>
        </ng-container>

        <!-- if item hasn't sumbenu -->
        <ng-container *ngIf="!item.items.length">
            <a *ngIf="!item.external" [queryParams]="item.parameters"
               [routerLink]="item.route"
               class="menu-link" routerLinkActive="link-active">
                <ng-container
                        [ngTemplateOutletContext]="{ item: item, parentItem: parentItem, depth: depth + 1 }"
                        [ngTemplateOutlet]="kt_menu_item_inner"
                >
                </ng-container>
            </a>
            <a *ngIf="item.external" [attr.href]="[item.route]" class="menu-link" target="_blank">
                <ng-container
                        [ngTemplateOutletContext]="{ item: item, parentItem: parentItem, depth: depth + 1 }"
                        [ngTemplateOutlet]="kt_menu_item_inner"
                >
                </ng-container>
            </a>
        </ng-container>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <ng-container *ngIf="item.items.length">
            <div class="{{ getSubmenuCssClasses(item, parentItem, depth) }} shadow-box">
                <span [ngClass]="{ 'menu-arrow-adjust': !parentItem }" class="menu-arrow"></span>
                <ul *ngIf="item.items.length" class="menu-subnav">
                    <ng-container *ngFor="let child of item.items">
                        <ng-container [ngTemplateOutletContext]="{ item: child, parentItem: item, depth: depth + 1 }"
                                      [ngTemplateOutlet]="kt_menu">
                        </ng-container>
                    </ng-container>
                </ul>
            </div>
        </ng-container>
    </li>
</ng-template>

<!-- item inner -->
<ng-template #kt_menu_item_inner let-item="item" let-parentItem="parentItem">
    <!-- if menu item has icon -->
    <!-- <i *ngIf="item.icon" class="menu-link-icon mr-2" [ngClass]="item.icon"></i> -->

    <ng-container>
        <span class="menu-item-here"></span>
        <!-- menu item title text -->
        <span class="menu-text">
            {{ item.name | localize }}
        </span>
    </ng-container>
</ng-template>
