<div class="form-group mb-2">
    <label>{{header}}</label>
    <div class="input-group">
        <input (change)="onKeyChange()" [(ngModel)]="addOrEditKey" class="form-control" placeholder="{{keyPlaceHolder}}"
               type="text">
        <input [(ngModel)]="addOrEditValue" class="form-control" placeholder="{{valuePlaceHolder}}" type="text">
        <div class="input-group-append">
            <button (click)="addOrEdit()" class="btn btn-primary" type="button">
                <span *ngIf="!isEdit">{{ 'Add' | localize }}</span>
                <span *ngIf="isEdit">{{ 'Edit' | localize }}</span>
            </button>
        </div>
    </div>
    <div *ngIf="items" class="key-value-items-list">
        <div *ngFor="let keyValueItem of items"
             [class.alert-warning]="addOrEditKey===keyValueItem.key"
             [class.alert-white]="addOrEditKey!==keyValueItem.key"
             class="alert alert-custom alert-bold m-1"
             role="alert">
            <div class="alert-text"> "{{keyValueItem.key}}" : "{{keyValueItem.value}}"</div>
            <div class="alert-close">
                <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                    <span (click)="openItemEdit(keyValueItem)" aria-hidden="true"><i
                            class="la la-edit fa-1x"></i></span>
                </button>
                <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                    <span (click)="removeItem(keyValueItem)" aria-hidden="true"><i class="la la-close fa-1x"></i></span>
                </button>
            </div>
        </div>
    </div>
</div>
