import {Component, Injector, OnInit, Input, AfterViewInit, ViewChild, ElementRef, Renderer2} from '@angular/core';
import {AppComponentBase} from '@shared/common/app-component-base';

@Component({
    selector: 'app-circular-progress-bar',
    templateUrl: './circular-progress-bar.component.html',
    styleUrls: ['./circular-progress-bar.component.css']
})
export class CircularProgressBarComponent extends AppComponentBase implements OnInit, AfterViewInit {
    @Input() endvalue: number;
    @Input() textname: string;
    @Input('gradientOne') gradientOne: string;
//   @Input('gradientTwo') gradientTwo:string
    @ViewChild('circularProgress', {read: ElementRef}) circularProgress: ElementRef;

    constructor(
        injector: Injector, private renderer: Renderer2
    ) {
        super(injector);
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        //progress circular bar
        let circularProgress: any = document.querySelector('.circular-progress');
        // let progressValue = document.querySelector(".progress-value");
        let progrssStartValue = 0;
        let progrssEndValue = this.endvalue;
        let speed = 100;

        let progressCircular = setInterval(() => {
            progrssStartValue++;
            // progressValue.textContent = `${progrssStartValue}%`
            this.renderer.setStyle(this.circularProgress.nativeElement, 'background', `conic-gradient(${this.gradientOne} ${progrssStartValue * 3.6}deg, #ededed 0deg)`);
            if (progrssStartValue === progrssEndValue) {
                clearInterval(progressCircular);
            }
        }, speed);

    }


}
