<div [ngClass]="{'hidden': !subscriptionStatusBarVisible()}"
     class="d-inline-block ml-3 glowing">
    <span *ngIf="appSession.tenant.isInTrialPeriod && !subscriptionIsExpiringSoon()"
          [innerHTML]="getTrialSubscriptionNotification()"></span>
    <a *ngIf="subscriptionIsExpiringSoon()" routerLink="/app/admin/subscription-management">
        <span *ngIf="subscriptionIsExpiringSoon() && appSession.tenant.isInTrialPeriod"><i
                class="pi pi-exclamation-circle"></i>
            {{getExpireNotification("TrialExpireNotification")}}</span>
        <span *ngIf="subscriptionIsExpiringSoon() && !appSession.tenant.isInTrialPeriod"><i
                class="pi pi-exclamation-circle"></i>
            {{getExpireNotification("SubscriptionExpireNotification")}}</span>
    </a>
</div>
