import {isPlatformBrowser} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ReportingServiceProxy} from '@shared/service-proxies/service-proxies';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DatepickerService {

    private data = {
        fromMiti: '',
        toMiti: ''
    };
    public mitiSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(this.data);
    public fromMitiSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(this.data.fromMiti);
    public toMitiSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(this.data.fromMiti);

    constructor(@Inject(PLATFORM_ID) private platformId: any, private proxy: ReportingServiceProxy) {
        if (isPlatformBrowser(this.platformId)) {
            this.load();
        }
    }

    get fromMiti(): string {
        return this.data.fromMiti;
    }

    get toMiti(): string {
        return this.data.toMiti;
    }


    private load(): void {
        const items = localStorage.getItem('financialYear');
        if (items == null) {
            this.proxy.getFinancialYears().subscribe((result) => {
                this.data = result;
                this.mitiSubject$.next(this.data);
                this.fromMitiSubject$.next(result.fromMiti);
                this.toMitiSubject$.next(result.toMiti);
                this.save();
            });
        } else {
            if (items) {
                this.data = JSON.parse(items);
                this.mitiSubject$.next(this.data);
                this.fromMitiSubject$.next(this.data.fromMiti);
                this.toMitiSubject$.next(this.data.toMiti);
            }
        }
    }

    private save(): void {
        localStorage.setItem('financialYear', JSON.stringify(this.data));
    }
}
