<div class="offcanvas offcanvas-right pt-5 pb-10" id="kt_quick_sidebar">
    <div class="quick-panel-actions">
        <a
                (click)="quickSideBarBackClick()"
                [hidden]="!selectedUser.friendUserId"
                class="btn btn-xs btn-icon btn-light btn-hover-primary quick-sidebar-back ml-2"
                class="float-left;"
                href="javascript:"
                id="kt_quick_sidebar_back"
        >
            <i aria-label="l('Back')" class="pi pi-arrow-alt-circle-left"></i>
        </a>
        <a (click)="reversePinned()" class="btn btn-xs btn-icon btn-light btn-hover-primary page-quick-sidebar-pinner"
           href="javascript:">
            <i [ngClass]="{ 'fa-rotate-90': !pinned }" aria-label="l('Pin')" class="bi bi-arrow-up-circle"></i>
        </a>
        <a class="btn btn-xs btn-icon btn-light btn-hover-primary" href="javascript:" id="kt_quick_sidebar_close">
            <i class="bi bi-x-circle"></i>
        </a>
    </div>
    <div class="flex-row-auto">
        <div [hidden]="selectedUser.friendUserId" class="card card-custom">
            <div class="card-body">
                <div class="input-group input-group-solid">
                    <input
                            [(ngModel)]="userNameFilter"
                            class="form-control input-sm"
                            id="ChatUserSearchUserName"
                            placeholder="{{ 'FilterOrAddUser' | localize }}"
                            type="text"
                    />
                    <div class="input-group-append">
                        <i
                                [popover]="popTemplate"
                                class="la la-info-circle"
                                data-html="true"
                                data-toggle="popover"
                                data-trigger="hover"
                                id="InterTenantChatHintIcon"
                                triggers="mouseenter:mouseleave"
                        ></i>
                        <ng-template #popTemplate>
                            <div [innerHtml]="chatUserSearchHint"></div>
                        </ng-template>
                    </div>
                </div>
                <button (click)="search()" *ngIf="userNameFilter.length" class="btn btn-block btn-success"
                        id="SearchChatUserButton" type="button">
                    {{ "AddFriend" | localize }}
                </button>
                <div class="mt-7 scroll scroll-pull chat-users">
                    <div *ngFor="let friend of getFilteredFriends(friendDtoState.Accepted, userNameFilter)"
                         id="friendListFriends">
                        <chat-friend-list-item (selectChatFriend)="selectFriend(friend)"
                                               [friend]="friend"></chat-friend-list-item>
                    </div>
                    <p *ngIf="getFilteredFriendsCount(friendDtoState.Accepted) == 0" id="EmptyFriendListInfo">
                        {{ "YouDontHaveAnyFriend" | localize }}
                    </p>
                </div>
                <div class="mt-20">
                    <h5>{{ "BlockedUsers" | localize }}</h5>
                    <div id="friendListBlockeds">
                        <div *ngFor="let friend of getFilteredFriends(friendDtoState.Blocked, userNameFilter)">
                            <chat-friend-list-item (selectChatFriend)="selectFriend(friend)"
                                                   [friend]="friend"></chat-friend-list-item>
                        </div>
                        <p *ngIf="getFilteredFriendsCount(friendDtoState.Blocked) == 0" id="EmptyBlockedFriendListInfo">
                            {{ "YouDontHaveAnyBlockedFriend" | localize }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div (window:resize)="onWindowResize($event)" [hidden]="!selectedUser.friendUserId" class="flex-row-fluid"
             id="kt_chat_content">
            <div class="card card-custom">
                <!--begin::Header-->
                <div class="card-header align-items-center px-4 py-3">
                    <div class="text-left flex-grow-1"></div>
                    <div class="text-center flex-grow-1">
                        <div class="text-dark-75 font-weight-bold font-size-h5" id="selectedChatUserName">
                            {{ getShownUserName(selectedUser.friendTenancyName, selectedUser.friendUserName) }}
                        </div>
                        <div id="selectedChatUserStatus">
                            <span
                                    [ngClass]="{ 'label-success': selectedUser.isOnline, 'label-secondary': !selectedUser.isOnline }"
                                    class="label label-dot mr-1"
                            ></span>
                            <span *ngIf="selectedUser.isOnline"
                                  class="font-weight-bold text-muted font-size-sm">{{ "Online" | localize }}</span>
                            <span *ngIf="!selectedUser.isOnline"
                                  class="font-weight-bold text-muted font-size-sm">{{ "Offline" | localize }}</span>
                        </div>
                    </div>
                    <div class="text-right flex-grow-1">
                        <div class="dropdown dropdown-inline" dropdown>
                            <button
                                    aria-expanded="false"
                                    aria-haspopup="true"
                                    class="btn btn-clean btn-sm btn-icon btn-icon-md"
                                    data-toggle="dropdown"
                                    dropdownToggle
                                    type="button"
                            >
                                <i class="flaticon-more-1"></i>
                            </button>
                            <div *dropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-md">
                                <ul class="navi navi-hover py-5">
                                    <li *ngIf="selectedUser.state !== friendDtoState.Blocked" class="navi-item"
                                        id="liBanChatUser">
                                        <a (click)="block(selectedUser)" class="navi-link" href="javascript:">
                                            <span class="navi-icon">
                                                <i class="pi pi-ban"></i>
                                            </span>
                                            <span class="navi-text">{{ "BlockUser" | localize }}</span>
                                        </a>
                                    </li>
                                    <li *ngIf="selectedUser.state === friendDtoState.Blocked" class="navi-item"
                                        id="liUnbanChatUser">
                                        <a (click)="unblock(selectedUser)" class="navi-link" href="javascript:">
                                            <span class="navi-icon">
                                                <i class="pi pi-check"></i>
                                            </span>
                                            <span class="navi-text">{{ "UnblockUser" | localize }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Header-->

                <!--begin::Body-->
                <div class="card-body">
                    <perfect-scrollbar
                        #chatScrollBar
                        (psYReachStart)="loadPreviousMesssagesOfSelectedUser()"
                        [config]="{ suppressScrollX: true }"
                        class="height-78vh;"
                    >
                        <!--begin::Scroll-->
                        <div class="scroll scroll-pull">
                            <!--begin::Messages-->
                            <div *ngFor="let message of selectedUser.messages" class="messages" id="UserChatMessages">
                                <div *ngIf="message.side === 1" class="d-flex flex-column mb-5 align-items-start">
                                    <div class="d-flex align-items-center">
                                        <div class="symbol symbol-circle symbol-40 mr-3">
                                            <img alt="image" src="{{ profilePicture }}"/>
                                        </div>
                                        <div>
                                            <a class="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
                                               href="#">{{
                                                currentUser.userName
                                                }}</a>
                                            <time class="text-muted font-size-sm timeago ml-2"
                                            >{{ getFixedMessageTime(message.creationTime) | luxonFromNow }}
                                            </time>
                                            <div [innerHTML]="getReadStateHtml(message)" class="d-inline"></div>
                                        </div>
                                    </div>
                                    <div
                                            class="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left w-100">
                                        <chat-message [message]="message"></chat-message>
                                    </div>
                                </div>
                                <div *ngIf="message.side !== 1" class="d-flex flex-column mb-5 align-items-end">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <time class="text-muted font-size-sm timeago ml-2">{{
                                                getFixedMessageTime(message.creationTime) | luxonFromNow
                                                }}</time>
                                            <div [innerHTML]="getReadStateHtml(message)" class="d-inline"></div>
                                            <a class="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
                                               href="#">
                                                {{ selectedUser.friendUserName }}
                                            </a>
                                        </div>
                                        <div class="symbol symbol-circle symbol-40 ml-3">
                                            <friend-profile-picture
                                                    [tenantId]="selectedUser.friendTenantId"
                                                    [userId]="selectedUser.friendUserId"
                                                    class="symbol symbol-circle symbol-40 ml-3"
                                            ></friend-profile-picture>
                                        </div>
                                    </div>
                                    <div
                                            class="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-100">
                                        <chat-message [message]="message"></chat-message>
                                    </div>
                                </div>
                            </div>
                            <!--end::Messages-->
                        </div>
                    </perfect-scrollbar>
                    <!--end::Scroll-->
                </div>
                <!--end::Body-->

                <!--begin::Footer-->
                <div class="card-footer align-items-center">
                    <!--begin::Compose-->
                    <form id="chatMessageForm" name="chatMessageForm" role="form">
                        <div class="files" id="files"></div>
                        <div *ngIf="selectedUser.state === friendDtoState.Blocked" class="col-md-12"
                             class="m-5px pr-25px;">
                            <button (click)="unblock(selectedUser)" class="btn btn-block btn-success"
                                    id="UnblockUserButton" type="button">
                                {{ "UnblockUser" | localize }}
                            </button>
                        </div>

                        <div *ngIf="progress > 0" class="col-md-12 chat-progress-bar">
                            <div class="progress" id="chatFileUploadProgress">
                                <div class="progress-bar progress-bar-success"></div>
                            </div>
                        </div>
                        <textarea
                            #ChatMessage
                            (keydown.Enter)="sendMessage($event)"
                            [(ngModel)]="chatMessage"
                            [disabled]="selectedUser.state == friendDtoState.Blocked"
                            class="form-control border-0 p-0"
                            id="ChatMessage"
                            maxlength="4096"
                            name="message"
                            placeholder="{{ 'TypeAMessageHere' | localize }}"
                            required
                            rows="2"
                        ></textarea>
                        <div class="d-flex align-items-center justify-content-between mt-5">
                            <div class="mr-3">
                                <a class="btn btn-clean btn-icon btn-md mr-1" href="javascript:">
                                    <span class="fileinput-button">
                                        <p-fileUpload
                                            #chatImageUpload
                                            (onBeforeSend)="onBeforeSend($event)"
                                            (uploadHandler)="uploadImage($event)"
                                            accept="image/*"
                                            auto="auto"
                                            chooseIcon="pi pi-photo-camera icon-lg"
                                            chooseLabel=" "
                                            customUpload="true"
                                            id="chatImageUpload"
                                            maxFileSize="10000000"
                                            name="ChatImageUpload"
                                        >
                                        </p-fileUpload>
                                    </span>
                                </a>
                                <!-- <a class="btn btn-clean btn-icon btn-md mr-1" href="javascript:">

                                    <span class="fileinput-button">
                                        <p-fileUpload
                                            #chatFileUpload
                                            (onBeforeSend)="onBeforeSend($event)"
                                            (uploadHandler)="uploadFile($event)"
                                            [url]="uploadUrl"
                                            auto="auto"
                                            chooseIcon="pi pi-file-1 icon-lg"
                                            chooseLabel=" "
                                            customUpload="true"
                                            id="chatFileUpload"
                                            maxFileSize="10000000"
                                            name="ChatFileUpload"
                                        >
                                        </p-fileUpload>
                                    </span>
                                </a> -->
                                <a class="btn btn-clean btn-icon btn-md" href="javascript:" id="btnLinkShare">
                                    <button
                                            (click)="shareCurrentLink()"
                                            class="p-fileupload-choose"
                                            icon="pi pi-website icon-lg"
                                            pButton
                                            type="button"
                                    ></button>
                                </a>
                            </div>
                            <div>
                                <button
                                        (click)="sendMessage($event)"
                                        class="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6"
                                        id="SendChatMessageButton"
                                        type="button"
                                >
                                    {{ "Reply" | localize }}
                                </button>
                            </div>
                        </div>
                    </form>
                    <!--begin::Compose-->
                </div>
                <!--end::Footer-->
            </div>
        </div>
    </div>
</div>
