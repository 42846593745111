<div class="input-group">
    <input (change)="onChange()" [(ngModel)]="data" [type]="isVisible ? 'text' : 'password'" class="form-control"/>
    <div class="input-group-append">
        <button (click)="toggleVisibility()" class="btn btn-primary" type="button">
            <i *ngIf="!isVisible" class="far fa-eye text-white"></i> <span
                *ngIf="!isVisible"> {{ "Show" | localize }}</span>
            <i *ngIf="isVisible" class="far fa-eye-slash text-white"></i> <span
                *ngIf="isVisible"> {{ "Hide" | localize }}</span>
        </button>
    </div>
</div>
