import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// import { BrowserModule } from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {ButtonModule, CalendarModule, FormModule, LayoutGridModule, TableModule} from '@fundamental-ngx/core';
import {NpDatepickerComponent} from './np-datepicker.component';
import {DualPickerComponent} from './dual-picker/dual-picker.component';
import {NepaliPickerComponent} from './nepali-picker/nepali-picker.component';
import {CalendarComponent} from './calendar/calendar.component';
import {ExamCalanderComponent} from './exam-calander/exam-calander.component';

@NgModule({
    declarations: [NpDatepickerComponent, DualPickerComponent, NepaliPickerComponent, CalendarComponent, ExamCalanderComponent],
    imports: [
        CommonModule,
        FormsModule,
        FormModule,
        ReactiveFormsModule,
        CalendarModule,
        ButtonModule,
        TableModule,
        LayoutGridModule
    ],
    exports: [
        NpDatepickerComponent,
        DualPickerComponent,
        NepaliPickerComponent,
        CalendarComponent,
        ExamCalanderComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class NpDatepickerModule {
}
