import {ChangeDetectorRef, Directive, ElementRef, HostListener, Renderer2, ViewChild} from '@angular/core';
import {BackSpaceService} from '@app/shared/back-space.service';
import {NgSelectComponent} from 'ng-select/lib/ng-select.component';

// import { NgSelectComponent } from '@ng-select/ng-select';
export enum KEY_CODE {
    RIGHT_ARROW = 39,
    LEFT_ARROW = 37,
    ENTER = 13,
    BACK_SPACE = 8,
    UP_ARROW = 38,
    TAB = 9
}

@Directive({
    selector: '[appSerialEnter]'
})
export class SerialEnterDirective {
    @ViewChild('focus4') select: NgSelectComponent;
    // @ContentChild(NgSelectComponent) select: NgSelectComponent;
    pValue: any;

    constructor(private _el: ElementRef, private backSpaceService: BackSpaceService, private cRef: ChangeDetectorRef, private renderer2: Renderer2) {
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: any) {
        if (event.keyCode === KEY_CODE.TAB) {
            event.preventDefault();
            this.backSpaceService.pValue$.subscribe(data => {

                this.pValue = data;
            });
            if (event.target.value === '' && this.pValue === '') {
                let elem = document.forms[1].elements;
                for (var i = 0; i < elem.length; i++) {
                    let prevInputId = elem[i].id;
                    let prvInputValue = prevInputId.charAt(5);
                    let prevInput = parseInt(prvInputValue);
                    let goToPrv = prevInput - 1;
                    const obj = event.srcElement || event.target;
                     if (obj === elem[i]) {
                        document.getElementById('focus' + goToPrv).focus();
                        break;
                    }
                }
            }
            this.backSpaceService.setPreviousValue(event.target.value);
            return false;
        }
        if (event.keyCode === KEY_CODE.ENTER) {
            event.preventDefault();
            // let elem = document.forms[1].elements;
            //
            var elem = document.querySelectorAll('input, select, ng-select, textarea');
            // console.log(pageElems)

            for (let i = 0; i < elem.length; i++) {
                let nextInputId = elem[i].id;
                let nxtInputValue = nextInputId.charAt(5);
                let nextInput = parseInt(nxtInputValue);
                let goToNxt = nextInput + 1;
                const obj = event.srcElement || event.target;

                if (obj === elem[i]) {
                    // document.getElementById('focus'+ goToNxt).focus();
                    // console.log(document.getElementById('focus'+ goToNxt));
                    let elq = document.getElementById('focus' + goToNxt);
                    const data = <HTMLInputElement>elq;
                    if (data.className.match('ng-select')) {
                        // this.focusNextField(data)
                        // data.open();
                        // elq.focus()
                        // data.onclick;
                        let nextElm = this.renderer2.selectRootElement('#' + 'focus' + goToNxt);
                        // nextElm.open();
                        // this.focusNextField('focus'+ goToNxt)
                        // console.log(nextElm)
                        //  nextElm.focusSearchInput();
                        //  nextElm.filterInput.nativeElement.focus();
                        //   let els = elq.focus()
                        //  let el = (this.elem[i].nativeElement as SelectComponent).element.nativeElement.querySelector('div.ui-select-container > input');
                        // console.log('sdasddsda')
                        // this.select.focus();

                    }
                    data.focus();
                    // console.log(data.className)
                    // console.log(data.)
                    break;
                }
            }
            return false;

        }

    }

    focusNextField(ngSelectElementId) {
        var element = document.getElementById(ngSelectElementId)
            .firstElementChild.firstElementChild.lastElementChild
            .getElementsByTagName('input');

        if (element.item(0)) {
            element.item(0).focus();
        }
    }
}
