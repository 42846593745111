<div #linkAccountModal="bs-modal" (onShown)="onShown()" [config]="{backdrop: 'static'}" appBsModal aria-hidden="true"
     aria-labelledby="createOrEditModal"
     class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog">

        <div class="modal-content">

            <form #linkAccountForm="ngForm" (ngSubmit)="save()" *ngIf="active" novalidate>
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"LinkNewAccount" | localize}}</span>
                    </h5>
                    <button (click)="close()" [attr.aria-label]="l('Close')" class="close" type="button">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="form-group">
                        <label for="TenancyName">{{"TenancyName" | localize}}</label>
                        <input #tenancyNameInput="ngModel" [(ngModel)]="linkUser.tenancyName"
                               [ngClass]="{'edited': linkUser.tenancyName}" class="form-control"
                               id="TenancyName" maxlength="64"
                               name="TenancyName" type="text">
                    </div>

                    <div class="form-group">
                        <label for="UserName">{{"UserName" | localize}} *</label>
                        <input #usernameOrEmailAddressInput="ngModel" [(ngModel)]="linkUser.usernameOrEmailAddress"
                               [ngClass]="{'edited': linkUser.usernameOrEmailAddress}" class="form-control"
                               id="UserName" maxlength="256"
                               name="UserName" required type="text">
                        <validation-messages [formCtrl]="usernameOrEmailAddressInput"></validation-messages>
                    </div>

                    <div class="form-group">
                        <label for="Password">{{"Password" | localize}} *</label>
                        <input #passwordInput="ngModel" [(ngModel)]="linkUser.password"
                               [ngClass]="{'edited': linkUser.password}"
                               class="form-control" id="Password" maxlength="128"
                               name="Password" required type="password">
                        <validation-messages [formCtrl]="passwordInput"></validation-messages>
                    </div>
                </div>

                <div class="modal-footer">
                    <button (click)="close()" [disabled]="saving" class="btn btn-light-primary font-weight-bold"
                            type="button">{{"Cancel" | localize}}</button>
                    <button [busyText]="l('SavingWithThreeDot')" [buttonBusy]="saving"
                            [disabled]="!linkAccountForm.form.valid" class="btn btn-primary font-weight-bold"
                            type="submit"><i class="pi pi-save"></i>
                        <span>{{"Save" | localize}}</span></button>
                </div>

            </form>

        </div>
    </div>
</div>
