<div class="header-mobile" id="kt_header_mobile">
    <a routerLink="/" target="_blank">
        <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" [src]="defaultLogo" alt="logo" height="28"/>
        <img
                *ngIf="appSession.tenant && appSession.tenant.logoId"
                [src]="
                remoteServiceBaseUrl +
                '/TenantCustomization/GetTenantLogo?skin=' +
                currentTheme.baseSettings.menu.asideSkin +
                '&tenantId=' +
                appSession.tenant.id +
                '&id=' +
                appSession.tenant.logoId
            "
                alt="logo"
                height="38"
        />
    </a>
    <div class="display-flex align-items-center">
        <button class="btn p-0 burger-icon burger-icon-left ml-4" id="kt_header_mobile_toggle">
            <span></span>
        </button>
        <button
                [options]="userMenuToggleOptions"
                class="btn btn-icon btn-hover-transparent-white p-0 ml-3"
                id="kt_header_mobile_topbar_toggle"
                ktToggle
        >
            <span class="svg-icon svg-icon-xl">
                <svg
                        height="24px"
                        version="1.1"
                        viewBox="0 0 24 24"
                        width="24px"
                        xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                                d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                opacity="0.3"
                        ></path>
                        <path
                                d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                        ></path>
                    </g>
                </svg>
            </span>
        </button>
    </div>
</div>

<div [@routerTransition] class="display-flex fx-column flex-root">
    <div class="display-flex flex-row fx-column-fluid page">
        <div class="display-flex fx-column flex-row-fluid wrapper" id="kt_wrapper">
            <div #ktHeader class="header header-fixed" data-ktheader-minimize="on" id="kt_header" ktHeader>
                <div [class]="containerClass + 'display-flex align-items-stretch justify-content-center'">
                    <div class="display-flex align-items-stretch">
                        <theme2-brand></theme2-brand>
                        <top-bar-menu class="topbarmenu"></top-bar-menu>
                        <div class="topbar corner_topbar show-desktop">
                            <div *ngIf="!isMobileDevice() && subscriptionStatusBarVisible()"
                                 class="topbar-item mobile-subscription-warning">
                                <subscription-notification-bar></subscription-notification-bar>
                            </div>
                            <active-delegated-users-combo
                                    [customStyle]="'btn btn-hover-transparent-white btn-dropdown btn-lg mr-1'">
                            </active-delegated-users-combo>
                            <quick-theme-selection
                                    [customStyle]="'btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1'">
                            </quick-theme-selection>
                            <language-switch-dropdown
                                    [customStyle]="'btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1'">
                            </language-switch-dropdown>
                            <header-notifications></header-notifications>
                            <chat-toggle-button
                                    [customStyle]="'btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1'"></chat-toggle-button>
                            <user-menu
                                    [togglerCssClass]="'btn btn-icon btn-hover-transparent-white display-flex align-items-center btn-lg px-md-2 w-md-auto'"
                            ></user-menu>
                        </div>
                    </div>
                    <div class="topbar corner_topbar show-mobile">
                        <div *ngIf="!isMobileDevice() && subscriptionStatusBarVisible()"
                             class="topbar-item mobile-subscription-warning">
                            <subscription-notification-bar></subscription-notification-bar>
                        </div>
                        <active-delegated-users-combo
                                [customStyle]="'btn btn-hover-transparent-white btn-dropdown btn-lg mr-1'">
                        </active-delegated-users-combo>
                        <quick-theme-selection
                                [customStyle]="'btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1'">
                        </quick-theme-selection>
                        <language-switch-dropdown
                                [customStyle]="'btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1'">
                        </language-switch-dropdown>
                        <header-notifications></header-notifications>
                        <chat-toggle-button
                                [customStyle]="'btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1'"></chat-toggle-button>
                        <user-menu
                                [togglerCssClass]="'btn btn-icon btn-hover-transparent-white display-flex align-items-center btn-lg px-md-2 w-md-auto'"
                        ></user-menu>
                    </div>
                </div>
                <div *ngIf="isMobileDevice() && subscriptionStatusBarVisible()"
                     class="p-3 mobile-subscription-warning d-print-none">
                    <subscription-notification-bar></subscription-notification-bar>
                </div>
            </div>

            <div class="flex-1 theme2-margin">
                <router-outlet></router-outlet>
            </div>
            <footer-bar class="theme2-footer"></footer-bar>
        </div>
    </div>
</div>
<style>
    @media (min-width: 1200px) {
        .container-xl,
        .container-lg,
        .container-md,
        .container-sm,
        .container {
            max-width: 1280px;
        }
    }
</style>
