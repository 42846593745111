<main class="calendar-contain">
    <section class="title-bar">
        <span class="title-bar__year">
            Today <i class="bi bi-caret-right"></i>
            <input
                    (blur)="onTouched ? onTouched() : ''"
                    (input)="onChange($event.target.value)"
                    (keyup)="selectDate($event, value)"
                    [(ngModel)]="value"
                    [disabled]="disabled"
                    [ngClass]="inputClass"
                    [ngStyle]="inputStyle"
                    [placeholder]="placeholder"
                    [value]="value"
                    autocomplete="off"
                    class="date__"
                    fd-form-control
                    id="npDatePicker"
                    type="text"
            />
        </span>
        <span class="title-bar__date_select">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-1">
                        <i (click)="decrementMonth()" class="bi bi-chevron-double-left"></i>
                    </div>
                    <div class="col-5 pr-0">
                        <select [(ngModel)]="selectedYear">
                            <option *ngFor="let year of arrayOne(100); let i = index"
                                    [value]="i + 2000">{{ i + 2000 }}</option>
                        </select>
                    </div>
                    <div class="col-5 pr-0">
                        <select [(ngModel)]="selectedMonthId">
                            <option *ngFor="let month of monthData" [value]="month.id">{{ month.abbr }}</option>
                        </select>
                    </div>
                    <div class="col-1">
                        <i (click)="incrementMonth()" class="bi bi-chevron-double-right"></i>
                    </div>
                </div>
            </div>
        </span>
        <div class="title-bar__controls">
            <span class="date_text">
                {{ replaceDate(currentYear) }} {{ currentMonth }} | {{ checkEngMonth(selectedYear, selectedMonthId) }}
                {{ engYear }}
            </span>
        </div>
    </section>

    <aside class="calendar__sidebar">
        <div class="sidebar__nav event_title">Selected Event (चयन गरिएका घटनाहरू)</div>
        <h2 class="sidebar__heading">
            {{ getDay(selectedYear, selectedMonthId, selectedDate.dayOfMonth) | date: "EEEE" }}
            ({{ convertNepWeek(getDay(selectedYear, selectedMonthId, selectedDate.dayOfMonth) | date: "EEEE") }})
        </h2>
        <ul class="sidebar__list">
            <li class="sidebar__list-item sidebar__list-item--complete"><span class="list-item__time">10.30</span> Team
                Meeting
            </li>
            <li class="sidebar__list-item sidebar__list-item--complete"><span class="list-item__time">1.00</span> Talk
                with Rupak
            </li>
            <li class="sidebar__list-item"><span class="list-item__time">2.30</span> Tiffin</li>
            <li class="sidebar__list-item"><span class="list-item__time">4.00</span> Get LOL</li>
        </ul>
        <!-- {{ selectedDate | json }} -->
    </aside>

    <section class="calendar__days">
        <section class="calendar__top-bar">
            <span *ngFor="let week of weekData" class="top-bar__days">
                <span class="nepali"> {{ week.name }} </span>
                <span class="english"> {{ week.englishName }} </span>
            </span>
        </section>
        <section class="calendar__week">
            <div
                    (click)="selectDay(item.day, item.dayOfWeek)"
                    *ngFor="let item of dayData | slice: 0:7"
                    [ngClass]="{
                    'calendar__date--weekend ': item.dayOfWeek == weekend,
                    'is-active':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString(),
                    'calendar__date--current':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString()
                }"
                    class="calendar__day"
            >
                <span class="calendar__date">{{ replaceDate(item.day) }}</span>
                <span class="calendar__task">{{ toEnglishDate(selectedYear, selectedMonthId, item.day) }}</span>
            </div>
        </section>
        <section class="calendar__week">
            <div
                    (click)="selectDay(item.day, item.dayOfWeek)"
                    *ngFor="let item of dayData | slice: 7:14"
                    [ngClass]="{
                    'calendar__date--weekend ': item.dayOfWeek == weekend,
                    'is-active':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString(),
                    'calendar__date--current':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString()
                }"
                    class="calendar__day"
            >
                <span class="calendar__date">{{ replaceDate(item.day) }}</span>
                <span class="calendar__task">{{ toEnglishDate(selectedYear, selectedMonthId, item.day) }}</span>
            </div>
        </section>
        <section class="calendar__week">
            <div
                    (click)="selectDay(item.day, item.dayOfWeek)"
                    *ngFor="let item of dayData | slice: 14:21"
                    [ngClass]="{
                    'calendar__date--weekend ': item.dayOfWeek == weekend,
                    'is-active':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString(),
                    'calendar__date--current':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString()
                }"
                    class="calendar__day"
            >
                <span class="calendar__date">{{ replaceDate(item.day) }}</span>
                <span class="calendar__task">{{ toEnglishDate(selectedYear, selectedMonthId, item.day) }}</span>
            </div>
        </section>
        <section class="calendar__week">
            <div
                    (click)="selectDay(item.day, item.dayOfWeek)"
                    *ngFor="let item of dayData | slice: 21:28"
                    [ngClass]="{
                    'calendar__date--weekend ': item.dayOfWeek == weekend,
                    'is-active':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString(),
                    'calendar__date--current':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString()
                }"
                    class="calendar__day"
            >
                <span class="calendar__date">{{ replaceDate(item.day) }}</span>
                <span class="calendar__task">{{ toEnglishDate(selectedYear, selectedMonthId, item.day) }}</span>
            </div>
        </section>
        <section class="calendar__week">
            <div
                    (click)="selectDay(item.day, item.dayOfWeek)"
                    *ngFor="let item of dayData | slice: 28:35"
                    [ngClass]="{
                    'calendar__date--weekend ': item.dayOfWeek == weekend,
                    'is-active':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString(),
                    'calendar__date--current':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString()
                }"
                    class="calendar__day"
            >
                <span class="calendar__date">{{ replaceDate(item.day) }}</span>
                <span class="calendar__task">{{ toEnglishDate(selectedYear, selectedMonthId, item.day) }}</span>
            </div>
        </section>
        <section *ngIf="dayData.length > 35" class="calendar__week">
            <div
                    (click)="selectDay(item.day, item.dayOfWeek)"
                    *ngFor="let item of dayData | slice: 35:42"
                    [ngClass]="{
                    'calendar__date--weekend ': item.dayOfWeek == weekend,
                    'is-active':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString(),
                    'calendar__date--current':
                        item.day == selectedDate.dayOfMonth && selectedMonth.id == selectedDate.month && selectedYear == selectedDate.year.toString()
                }"
                    class="calendar__day today"
            >
                <span class="calendar__date">{{ replaceDate(item.day) }}</span>
                <span class="calendar__task">{{ toEnglishDate(selectedYear, selectedMonthId, item.day) }}</span>
            </div>
        </section>
    </section>
</main>

<input
        (blur)="onTouched ? onTouched() : ''"
        (input)="onChange($event.target.value)"
        (keyup)="selectDate($event, value)"
        [(ngModel)]="value"
        [disabled]="disabled"
        [ngClass]="inputClass"
        [ngStyle]="inputStyle"
        [placeholder]="placeholder"
        [value]="value"
        autocomplete="off"
        fd-form-control
        id="npDatePicker"
        type="text"
/>
