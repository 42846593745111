import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BackSpaceService {
    private previousValue$: BehaviorSubject<any> = new BehaviorSubject('kjhj');
    readonly pValue$: Observable<boolean> = this.previousValue$.asObservable();

    constructor() {
    }

    public setPreviousValue(value: any) {
        this.previousValue$.next(value);
    }
}
