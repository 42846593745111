import {Component, Injector, Input} from '@angular/core';
import {Router} from '@angular/router';
import {AppComponentBase} from '@shared/common/app-component-base';
import {pdfDefaultOptions} from 'ngx-extended-pdf-viewer';


@Component({
    selector: 'pdf-viewer',
    templateUrl: './pdf-viewer.component.html'
})
export class PdfViewerComponent extends AppComponentBase {

    @Input() pdfdata: string;

    constructor(
        private _router: Router,
        injector: Injector
    ) {
        super(injector);
        pdfDefaultOptions.assetsFolder = 'bleeding-edge';
    }


}
