<p-autoComplete
        (completeMethod)="filter($event)"
        [(ngModel)]="selectedValues"
        [minLength]="1"
        [multiple]="true"
        [suggestions]="filteredValues"
        inputStyleClass="form-control"
        styleClass="width-percent-100"
>
</p-autoComplete>
