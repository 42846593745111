<div [@routerTransition] [class]="defaultContainer">
    <div class="content display-flex fx-column">
        <sub-header [description]="'DashboardHeaderInfo' | localize" [title]="'Dashboard' | localize">
            <div role="actions">
                <button
                        (click)="filterModal.show()"
                        *ngIf="!loading && userDashboard && userDashboard.filters && userDashboard.filters.length > 0"
                        class="bt btn-brand btn-icon btn-filter"
                        type="button"
                >
                    <i class="bi bi-filter"></i>
                </button>

                <span [class]="{ 'switch-primary': editModeEnabled }" class="switch m-0 p-0">
                    <label class="m-0 p-0" id="mbminus-10px">
                        <input (change)="changeEditMode()" [checked]="editModeEnabled" type="checkbox"/>
                        <span class="m-0 p-0"></span>
                    </label>
                </span>

                <strong class="p-2 pt-4 dashboard-header-text-edit-mode">
                    {{ "EditMode" | localize }}
                </strong>
            </div>
        </sub-header>

        <div *ngIf="!loading" [class]="containerClass">
            <div class="row">
                <div class="col-md-12 zindex-0">
                    <tabset #dashboardTabs [justified]="true" [ngClass]="{ 'nav-hidden': !moreThanOnePage() }">
                        <tab
                                (selectTab)="selectPageTab(page.id)"
                                *ngFor="let page of userDashboard?.pages || []; let i = index"
                                heading="{{ page.name }}"
                        >
                            <gridster
                                    [ngClass]="{ 'no-tab': !moreThanOnePage() }"
                                    [options]="options[i]"
                                    [perfectScrollbar]="{ wheelPropagation: false }"
                            >
                                <gridster-item *ngFor="let widget of page.widgets" [item]="widget.gridInformation">
                                    <ng-container *ngComponentOutlet="widget.component"></ng-container>
                                    <button
                                            (click)="removeItem(widget.gridInformation)"
                                            *ngIf="editModeEnabled"
                                            class="btn btn-sm text-white bg-danger deleteWidgetButton"
                                    >
                                        <i class="pi pi-times"></i>
                                    </button>
                                </gridster-item>
                            </gridster>
                        </tab>
                    </tabset>
                </div>
                <div *ngIf="editModeEnabled" class="div-dashboard-customization">
                    <div class="vr">
                        <button
                                (click)="openAddWidgetModal()"
                                [disabled]="selectedPage.id === ''"
                                class="btn btn-sm btn-warning btn-elevate-hover btn-pill"
                                id="AddWidgetButton"
                                type="button"
                        >
                            <i class="pi pi-save"></i>{{ "AddWidget" | localize }}
                        </button>
                        &nbsp;
                    </div>
                    <div class="vr">
                        <div
                            #dropdownAddPage="bs-dropdown"
                            (onShown)="addPageDropdownShown()"
                            [dropup]="true"
                            [insideClick]="true"
                            class="btn-group"
                            dropdown
                        >
                            <button
                                    aria-controls="dropdown-basic"
                                    class="btn btn-sm btn-primary dropdown-toggle btn-elevate-hover btn-pill"
                                    dropdownToggle
                                    type="button"
                            >
                                <i class="pi pi-save"></i> {{ "AddPage" | localize }}<span class="caret"></span>
                            </button>
                            <ul *dropdownMenu aria-labelledby="button-basic" class="dropdown-menu p-10" role="menu">
                                <li role="menuitem">
                                    <div class="form-group">
                                        <label for="PageNameInput">{{ "NewPageName" | localize }}</label>
                                        <input [(ngModel)]="addPageInput" class="form-control" placeholder=""
                                               type="text"/>
                                    </div>
                                    <button (click)="addNewPage(addPageInput)"
                                            class="btn btn-sm btn-block btn-info">{{ "Save" | localize }}</button>
                                </li>
                            </ul>
                        </div>
                        <div
                            #dropdownRenamePage="bs-dropdown"
                            (onShown)="renamePageDropdownShown()"
                            [dropup]="true"
                            [insideClick]="true"
                            [isDisabled]="selectedPage.id === ''"
                            class="btn-group"
                            dropdown
                        >
                            <button
                                    aria-controls="dropdown-basic"
                                    class="btn btn-sm btn-info btn-elevate-hover btn-pill dropdown-toggle"
                                    dropdownToggle
                                    type="button"
                            >
                                <i class="pi pi-edit"></i>{{ "RenamePage" | localize }}<span class="caret"></span>
                            </button>
                            <ul *dropdownMenu aria-labelledby="button-basic" class="dropdown-menu p-10" role="menu">
                                <li role="menuitem">
                                    <div class="form-group">
                                        <label for="PageNameInput">{{ "PageNewName" | localize }}</label>
                                        <input [(ngModel)]="renamePageInput" class="form-control"
                                               placeholder="{{ selectedPage.name }}" type="text"/>
                                    </div>
                                    <button (click)="renamePage(renamePageInput)" class="btn btn-sm btn-block btn-info">
                                        {{ "Save" | localize }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <button
                                (click)="deletePage()"
                                [disabled]="selectedPage.id === ''"
                                class="btn btn-sm btn-danger btn-elevate-hover btn-pill"
                                id="DeletePageButton"
                        >
                            <i class="pi pi-trash"></i>
                            <span *ngIf="userDashboard && userDashboard.pages.length > 1">{{ "DeletePage" | localize }}</span>
                            <span *ngIf="userDashboard && userDashboard.pages.length == 1">{{ "BackToDefaultPage" | localize }}</span>
                        </button>
                        &nbsp;
                    </div>
                    <button (click)="savePage()" class="btn btn-sm btn-success btn-elevate-hover btn-pill"
                            type="button">
                        <i class="pi pi-save"></i>{{ "Save" | localize }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <add-widget-modal #addWidgetModal (onClose)="addWidget($event)" *ngIf="dashboardName"></add-widget-modal>

    <div #filterModal="bs-modal" appBsModal aria-hidden="true" class="modal fade filterModal" role="dialog"
         tabindex="-1">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{ "DashboardFilters" | localize }}</span>
                    </h5>
                    <button (click)="close()" [attr.aria-label]="l('Close')" class="close" type="button">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div *ngIf="userDashboard" class="modal-body">
                    <div *ngFor="let filter of userDashboard.filters; let i = index">
                        <div class="row">
                            <h6 class="ml-10px">{{ filter.name | localize }}</h6>
                            <div class="col-md-12">
                                <ng-container *ngComponentOutlet="filter.component"></ng-container>
                            </div>
                        </div>
                        <hr *ngIf="i != userDashboard.filters.length - 1"/>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="close()" class="btn btn-light-primary fw-bold"
                            type="button">{{ "Close" | localize }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
