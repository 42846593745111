import {
    AbpMultiTenancyService,
    FeatureCheckerService,
    LocalizationService,
    MessageService,
    NotifyService,
    PermissionCheckerService,
    SettingService
} from 'abp-ng2-module';
import { Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';
import {
    BranchListDto,
    DateSelectsServiceProxy,
    ReportingServiceProxy,
    UiCustomizationSettingsDto
} from '@shared/service-proxies/service-proxies';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { ReportsService } from '@app/reports/reports.service';
import { BehaviorSubject, lastValueFrom, Observable } from '@node_modules/rxjs';
import { ISuktasPaginator, SuktasPaginator } from '@app/shared/common/paginator/paginator.component';
import { PaginatorService } from '@app/shared/common/paginator/paginator.service';
import { FormArray, FormGroup } from '@angular/forms';
// import { FileUpload } from "primeng/fileupload";

// @Component({
//   })
export abstract class AppComponentBase {
    // @ViewChild("ExcelFileUpload", { static: false }) excelFileUpload: FileUpload;

    isMobile = window.innerWidth <= 768;
    isDesktop = window.innerWidth > 768;
    isPdf = new BehaviorSubject<boolean>(true);
    branchDropdown$: Observable<BranchListDto[]>;
    qtyValidationType: string;
    godowns: boolean;
    units: boolean;
    isPrint: boolean;
    racks: boolean;
    batches: boolean;
    taxes: boolean;
    productcodes: boolean;
    getdatemiti: string;
    getbranchId: number;
    getfrommiti: string;
    gettomiti: string;

    //enter backspace
    textfilledbull: boolean;
    numberfillednull: boolean;
    ngfillednull: boolean;
    arrayfilledNumNull: boolean;
    arrayfilledTextnull: boolean;

    brands: boolean;
    discountAmount: boolean;
    discountPercent: boolean;
    salesRate: boolean;
    models: boolean;
    sizes: boolean;
    batch: boolean;

    autoLoginTenant: boolean = AppConsts.autoLogin;
    autoTenantId: number = AppConsts.tenantId;

    // formChangeSubject$ : BehaviorSubject<boolean>;
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
    localization: LocalizationService;
    // formSubject$ : BehaviorSubject<any>;
    appReporting: ReportingServiceProxy;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    primengTableHelper: PrimengTableHelper;
    ui: AppUiCustomizationService;
    appUrlService: AppUrlService;
    spinnerService: NgxSpinnerService;
    dateService: DateSelectsServiceProxy;
    reportingService: ReportingServiceProxy;
    excelService: ReportsService;
    paginatorService: PaginatorService;
    public suktasRpag: ISuktasPaginator = new SuktasPaginator();
    private _fromValue: any;
    private _isFormChanged = false;
    private ngxSpinnerTextService: NgxSpinnerTextService;

    constructor(injector: Injector) {
        this.appReporting = injector.get(ReportingServiceProxy);
        this.excelService = injector.get(ReportsService);
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
        this.spinnerService = injector.get(NgxSpinnerService);
        this.ngxSpinnerTextService = injector.get(NgxSpinnerTextService);
        this.dateService = injector.get(DateSelectsServiceProxy);
        this.paginatorService = injector.get(PaginatorService);
        //this.formSubject$ = new BehaviorSubject(this._fromValue);
        // this.formChangeSubject$ = new BehaviorSubject(this._isFormChanged)
    }

    get currentFinancialYear(): any {
        let date = this.dateService.getSelectDate();
        return date;
    }

    get currentTheme(): UiCustomizationSettingsDto {
        return this.appSession.theme;
    }

    get containerClass(): string {
        if (this.appSession.theme.baseSettings.layout.layoutType === 'fluid') {
            return 'container-fluid';
        }

        return 'container';
    }

    get defaultContainer(): string {
        if (this.currentTheme.baseSettings.theme === 'default') {
            return 'default-container';
        }
        return '';
    }

    get theme2TableMargin(): string {
        if (this.currentTheme.baseSettings.theme === 'theme2') {
            return 'theme2_main_div';
        }
        return '';
    }

    get theme2Width(): string {
        if (this.currentTheme.baseSettings.theme === 'theme2') {
            return 'theme2Width';
        }
        return '';
    }

    async getSetting() {
        const $tenantSetting = this.appReporting.getAllTenantSetting();
        const data = await lastValueFrom($tenantSetting);
        const $setting = this.dateService.getSelectDate();
        const setting = await lastValueFrom($setting);
        const $reporting = this.appReporting.getFinancialYears();
        const reporting = await lastValueFrom($reporting);
        this.branchDropdown$ = this.appReporting.getAllBranchSharedDropdown();

        this.getdatemiti = setting.selectedMiti;
        this.getbranchId = setting.branchId;
        this.getfrommiti = reporting.fromMiti;
        this.gettomiti = reporting.toMiti;

        // this.appReporting.getAllTenantSetting().subscribe(data => {
        //     this.qtyValidationType = data.negativeCashTranscation
        // });
    }

    flattenDeep(array: any[]) {
        return array.reduce((acc: string | any[], val: any) => (Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val)), []);
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, this.flattenDeep(args));
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    showMainSpinner(text?: string): void {
        this.ngxSpinnerTextService.setText(text);
        this.spinnerService.show();
    }

    hideMainSpinner(text?: string): void {
        this.spinnerService.hide();
    }

    public twoDec = (s): string => {
        return (Math.round(s * 100) / 100).toFixed(2);
    };
    public capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

    public exportAsXLSX(data): void {

        this.excelService.exportAsExcelFile(data, 'sample');
    }

    public filterItems(arr, query) {
        return arr.filter(function (el) {
            return el.checked == query;
        });
    }

    filterDate(item: any) {
        if (item.dayOfMonth === 0) {
            return {
                year: item.year,
                month: item.month,
                dayOfMonth: 1
            };
        } else if (item.dayOfMonth < 0) {
            return {
                year: item.year,
                month: item.month - 1,
                dayOfMonth: 29
            };
        } else {
            return item;
        }
    }

    // enum {string = number}
    public _prepareSelectOptions = (enums: any) => {
        return Object.keys(enums)
            .filter((f) => !isNaN(Number(f)))
            .map((k: string) => {
                return { id: parseInt(k), displayName: enums[parseInt(k)] };
            });
    };

    //for upper case
    titleCase(form: FormGroup, str: string, name: string) {
        let separateWord = str.split(' ');
        for (let i = 0; i < separateWord.length; i++) {
            separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
        }
        const final = separateWord.join(' ');
        form.get(name).setValue(final);
    }

    //enter and back spce
    checkTeaxtfilled(filename, form) {

        const data = form.get(filename).value;

        if (data == '') {
            this.textfilledbull = true;
        } else {
            this.textfilledbull = false;
        }
    }

    checkNumberfilled(filename, form) {
        const data = form.get(filename).value;
        if (data == null) {
            this.numberfillednull = true;
        } else {
            this.numberfillednull = false;
        }
    }

    checkngfilled(e, i?) {

        const data = e.target.value;
        if (data == '') {
            this.ngfillednull = true;
        } else {
            this.ngfillednull = false;
        }
    }

    checkArrayTextEmpty(filename, abcd) {
        const data = abcd.get(filename).value;
        if (data == '') {
            this.arrayfilledTextnull = true;
        } else {
            this.arrayfilledTextnull = false;
        }
    }

    checkarrayNum(filename, abcd) {
        const data = abcd.get(filename).value;
        if (data == null) {
            this.arrayfilledNumNull = true;
        } else {
            this.arrayfilledNumNull = false;
        }
    }

    checkAny(value: any) {
        if (value === null || value == undefined || value == '') {
            return false;
        } else {
            return true;
        }
    }

    checkFormArrdisableOnEdit(formArrName: FormArray, formGroupIndx: number) {
        if (formGroupIndx >= 0) {
            for (let i = 0; i < formArrName.length; i++) {
                formArrName.controls.at(i).disable();
            }
            formArrName.controls.at(formArrName.length - 1).enable();
        }
    }

    // importexcelfromAPI(data: { files: File }, uploadUrl){
    //     const formData: FormData = new FormData();
    //     const file = data.files[0];
    //     formData.append("file", file, file.name);
    //     this._httpClient
    //         .post<any>(uploadUrl, formData)
    //         .pipe(finalize(() => this.excelFileUpload.clear()))
    //         .subscribe((response) => {
    //             if (response.success) {
    //                 this.notify.success(this.l("ImportAccountGroupProcessStart"));
    //             } else if (response.error != null) {
    //                 this.notify.error(this.l("ImportAccountGroupUploadFailed"));
    //             }
    //     });
    // }
}
