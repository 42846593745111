<div *ngIf="languages.length > 1" [dropup]="isDropup" aria-haspopup="true" class="dropdown dropdown-language" dropdown>
    <div aria-expanded="false" class="topbar-item" data-offset="10px,0px" data-toggle="dropdown" dropdownToggle>
        <div [class]="customStyle">
            <i [class]="currentLanguage.icon"></i>
        </div>
    </div>
    <div *dropdownMenu [attr.aria-label]="l('Languages')"
         class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right">
        <perfect-scrollbar>
            <ul class="navi navi-hover py-4">
                <li *ngFor="let language of languages" class="navi-item">
                    <a (click)="changeLanguage(language.name)" class="navi-link" href="javascript:">
                    <span class="symbol symbol-20 mr-3">
                        <i [class]="language.icon"></i>
                    </span>
                        <span class="navi-text">
                        {{language.displayName}}
                    </span>
                    </a>
                </li>
            </ul>
        </perfect-scrollbar>
    </div>
</div>
