<div class="input-group">
    <input
        #dateRangePicker="bsDaterangepicker"
        (dateChange)="onChange()"
        [(date)]="selectedDateRange"
        bsDaterangepicker
        class="form-control date-range-filter"
        dateRangePickerLuxonModifier
        disabled="disabled"
        placement="bottom"
        type="text"
        value="{{ selectedDateRange[0] | luxonFormat: 'ff' }} - {{ selectedDateRange[1] | luxonFormat: 'ff' }}"
    />
    <div class="input-group-append">
        <button (click)="dateRangePicker.toggle()" class="btn btn-primary" type="button">
            <i aria-hidden="true" class="bi bi-calendar-day p-0" class="color-white"></i>
        </button>
    </div>
</div>
