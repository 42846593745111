export class NameValueOfString implements INameValueOfString {
    name!: string | undefined;
    value!: string | undefined;

    constructor(data?: INameValueOfString) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    static fromJS(data: any): NameValueOfString {
        data = typeof data === 'object' ? data : {};
        let result = new NameValueOfString();
        result.init(data);
        return result;
    }

    init(_data?: any) {
        if (_data) {
            this.name = _data['name'];
            this.value = _data['value'];
        }
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['name'] = this.name;
        data['value'] = this.value;
        return data;
    }
}

export interface INameValueOfString {
    name: string | undefined;
    value: string | undefined;
}
