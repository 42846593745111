import {Directive, ElementRef, HostBinding, Input} from '@angular/core';

@Directive({
    selector: '[appPadding]'
})

export class PaddingDirective {

    @Input() padding = 0;
    @Input() paddingr = 5;

    constructor(private el: ElementRef) {
    }

    @HostBinding('style.padding') get getPadding() {
        return `${this.padding}px ${this.paddingr}px`;
    }


}
