import {Component, HostListener, Injector, ViewChild} from '@angular/core';
import {AppComponentBase} from '@shared/common/app-component-base';
import {Router} from '@angular/router';
import {AppNavigationService} from '../nav/app-navigation.service';
import {NameValueOfString} from '@shared/helpers/NameValueOfString';

@Component({
    selector: 'topbar-search',
    templateUrl: './nepali-calender.component.html',

    styleUrls: ['./nepali-calender.component.css']
})
export class NepaliCalenderComponent extends AppComponentBase {
    @ViewChild('search', {static: false}) search: any;
    // form: FormGroup;
    // @Input() isDropup = false;
    // @Input() customStyle = 'btn btn-icon btn-dropdown btn-clean btn-lg mr-1';
    // today: any;
    // public constructor(
    //     injector: Injector,
    //     private _fb: FormBuilder,
    //     private _profileServiceProxy: ProfileServiceProxy,
    //     _dateTimeService: DateTimeService
    // ) {
    //     super(injector, _dateTimeService);
    // }

    // createForm(item: any = {}) {
    //     let date = new NepaliDate(new Date());
    //     this.today = date.format('YYYY/MM/DD');
    //     this.form = this._fb.group({
    //         selectDate: [item.selectDate ? item.selectDate : this.today],
    //     });

    // }
    // ngOnInit() {
    //     this.createForm();
    //     this.componentDidMount();
    // }
    // handleMenuClick(event) {
    //     event.stopPropagation()
    //     let menu_item = event.target
    //     //implement your logic here.
    //  }
    //  componentDidMount() {
    //      document.getElementsByClassName("dropdown-menu")[0].addEventListener(
    //          "click", this.handleMenuClick.bind(this), false)
    //     }

    // save(){

    // }
    allMenuItems: any[];

    searchMenuResults: NameValueOfString[];

    isMenuSearchActive = false;
    suggestionsIsOpen = true;

    selected: '';

    constructor(injector: Injector, private _appNavigationService: AppNavigationService, private router: Router) {
        super(injector);
        this.initializeMenuSearch();
    }

    showMenuItem(menuItem): boolean {
        return this._appNavigationService.showMenuItem(menuItem);
    }

    searchMenuItem(event) {
        this.searchMenuResults = this.allMenuItems
            .filter(
                (item) => item.name.toLowerCase().includes(event.query.toLowerCase()) || item.route.toLowerCase().includes(event.query.toLowerCase())
            )
            .map(
                (menuItem) =>
                    new NameValueOfString({
                        name: menuItem.name,
                        value: menuItem.route
                    })
            );
    }

    searchNg(): void {

        this.isMenuSearchActive = true;
        this.suggestionsIsOpen = true;

        let term = this.selected;

        this.searchMenuResults = this.allMenuItems
            .filter((item) => item.name.toLowerCase().includes(term.toLowerCase()) || item.route.toLowerCase().includes(term.toLowerCase()))
            .map(
                (menuItem) =>
                    new NameValueOfString({
                        name: menuItem.name,
                        value: menuItem.route
                    })
            );
    }

    selectMenuItem(event) {
        if (event && event.value) {
            this.router.navigate([event.value]).then((navigated) => {
                this.selected = '';
            });
        }
        //   this.search.clear();
        // this.isMenuSearchActive = false;
        //   this.suggestionsIsOpen = false;
    }

    hideMenu() {
        this.isMenuSearchActive = false;

        this.suggestionsIsOpen = false;
    }

    @HostListener('document:click', ['$event']) clickout(event) {
        if (event.target.id !== 'keyword') {
            // this.suggestionsIsOpen = false;
            this.isMenuSearchActive = false;

            this.suggestionsIsOpen = false;
        }
    }

    private getAllMenuItems() {
        return this._appNavigationService
            .getAllMenuItems()
            .filter((item) => this.showMenuItem(item) && item.route)
            .map((menuItem) => {
                return {
                    name: this.l(menuItem.name),
                    route: menuItem.route
                };
            });
    }

    private initializeMenuSearch() {
        this.isMenuSearchActive = false;
        // this.suggestionsIsOpen = true;
        let themeSettings = this.currentTheme.baseSettings;

        if (themeSettings && themeSettings.menu && themeSettings.menu.searchActive) {
            this.allMenuItems = this.getAllMenuItems();
            this.isMenuSearchActive = true;
            // this.suggestionsIsOpen = false;
        }
    }
}
