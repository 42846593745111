import {Component, Injector, OnInit} from '@angular/core';
import {TenantDashboardServiceProxy} from '@shared/service-proxies/service-proxies';
import {DashboardChartBase} from '../dashboard-chart-base';
import {WidgetComponentBaseComponent} from '../widget-component-base';

class TopVendorBySalesAmountLineChart extends DashboardChartBase {


    chartData: any[];
    scheme: any = {
        name: 'green',
        selectable: true,
        group: 'Ordinal',
        domain: [
            '#34bfa3'
        ]
    };

    constructor(private _dashboardService: TenantDashboardServiceProxy) {
        super();
    }

    reload() {
        this.showLoading();
        this._dashboardService;
        // .getTodayPayment()
        //   .subscribe(result => {
        //     this.init(result.todayPayment);
        //     this.hideLoading();
        //   });
    }

    init(topVendorBySalesAmount: any) {

    }

}


@Component({
    selector: 'app-widget-top-vendor-by-sales-amount',
    templateUrl: './widget-top-vendor-by-sales-amount.component.html',
    styleUrls: ['./widget-top-vendor-by-sales-amount.component.css']
})
export class WidgetTopVendorBySalesAmountComponent extends WidgetComponentBaseComponent implements OnInit {
    topVendorBySalesAmountLineChart: TopVendorBySalesAmountLineChart;


    constructor(injector: Injector,
                private _tenantdashboardService: TenantDashboardServiceProxy) {
        super(injector);
        this.topVendorBySalesAmountLineChart = new TopVendorBySalesAmountLineChart(this._tenantdashboardService);
    }


    ngOnInit(): void {
        this.topVendorBySalesAmountLineChart.reload();
    }

}


