<div *ngIf="isDesktop else isMobileScreen">
    <div class="suktasDataGrid table-responsive suktasDataGrid-default">
        <fd-toolbar [active]="true" [fdType]="'info'">
            <label fd-toolbar-label>
            </label>
            <fd-toolbar-spacer></fd-toolbar-spacer>


            <button (click)="openDialog()"
                    [compact]="true"
                    fd-button
                    fd-toolbar-item fdType="transparent">
                <i class="bi bi-list-check"></i>
            </button>


            <button [compact]="true"
                    [disabled]="!(dragFlag$|async) " [fdMenuTrigger]="menu"
                    fd-button fdType="transparent">
                <i class="bi bi-tools"></i>
            </button>

            <fd-menu #menu>
                <li fd-menu-item>
                    <a fd-menu-interactive>
                <span fd-menu-title>
                     <button (click)="deleteSelectedData()" [compact]="true"
                             fd-button
                             fd-menu-interactive
                             fd-toolbar-item
                             fdType="negative"
                             glyph="delete"
                             label="Delete Selected"></button></span>
                    </a>
                </li>
                <li fd-menu-item>
                    <a fd-menu-interactive>
            <span fd-menu-title> <button (click)="exportToExcel()" [compact]="true"
                                         fd-button
                                         fd-menu-interactive
                                         fd-toolbar-item
                                         fdType="attention"
                                         glyph="excel-attachment"
                                         label="Export to Excel"></button></span>
                    </a>
                </li>
            </fd-menu>
        </fd-toolbar>
        <cdk-virtual-scroll-viewport class="example-viewport" itemSize="50">
            <table [keys]="displayedColumns" class="table-striped" fd-table>
                <thead fd-table-header>


                <tr fd-table-row>

                    <th *ngFor="let column of displayedColumns" [key]="column" fd-table-cell>

                        <div *ngIf="column == 'check'; else elseBlock">
                            <fd-checkbox
                                    (ngModelChange)="selectMaster($event, 'cozy')"
                                    [ngModel]="checkboxValue"
                            ></fd-checkbox>
                        </div>
                        <ng-template #elseBlock>
                            {{column | uppercase}}
                        </ng-template>

                    </th>

                </tr>
                </thead>
                <tbody (cdkDropListDropped)="onDrop($event)" cdkDropList fd-table-body>
                <tr (click)="checkRow(i,row.checked,'cozy',$event)" *cdkVirtualFor="let row of tableData; let i = index"
                    [activable]="true" [attr.aria-selected]="row.checked"
                    [focusable]="true" [hoverable]="true" cdkDrag cdkDragLockAxis="y"
                    fd-table-row tabindex="0"
                >
                    <td [key]="'check'" class="text-center pl-10px" fd-table-cell>
                        <fd-checkbox
                                (ngModelChange)="select(i, $event, 'cozy')"
                                [ngModel]="row.checked"
                        ></fd-checkbox>
                    </td>
                    <td [key]="'drag'" fd-table-cell>
                        <div class="drag-handle">
                            <i class="bi bi-grid-3x3-gap-fill"></i>

                        </div>
                    </td>
                    <td *ngIf="row.sn" [key]="'sn'" fd-table-cell>
                        <a href="#">{{ i + 1}}</a>
                    </td>
                    <td *ngIf="row.branch" [key]="'branch'" fd-table-cell>{{ row.branch }}</td>
                    <td *ngIf="row.date" [key]="'date'" fd-table-cell>{{ row.date }}</td>
                    <td *ngIf="row.name" [key]="'name'" fd-table-cell>{{ row.name }}</td>
                    <td *ngIf="row.group" [key]="'group'" fd-table-cell>{{ row.group }}</td>
                    <td *ngIf="row.brand" [key]="'brand'" fd-table-cell>{{ row.brand }}</td>
                    <td *ngIf="row.rate" [key]="'rate'" fd-table-cell>{{ row.rate }}</td>
                    <td *ngIf="row.openingstock <= 0 || row.openingstock >= 0" [key]="'openingstock'"
                        fd-table-cell>{{ row.openingstock }}</td>
                    <td *ngIf="row.openingstockvalue <= 0 || row.openingstockvalue >= 0" [key]="'openingstockvalue'"
                        fd-table-cell>{{ row.openingstockvalue }}</td>
                    <td *ngIf="row.inwardqty" [key]="'inwardqty'" fd-table-cell>{{ row.inwardqty }}</td>
                    <td *ngIf="row.inwardvalue" [key]="'inwardvalue'" fd-table-cell>{{ row.inwardvalue }}</td>
                    <td *ngIf="row.outwardqty <= 0 || row.outwardqty >= 0" [key]="'outwardqty'"
                        fd-table-cell>{{ row.outwardqty }}</td>
                    <td *ngIf="row.outwardvalue <= 0 || row.outwardvalue >= 0" [key]="'outwardvalue'"
                        fd-table-cell>{{ row.outwardvalue }}</td>
                    <td *ngIf="row.closingqty <= 0 || row.closingqty >= 0" [key]="'closingqty'"
                        fd-table-cell>{{ row.closingqty }}</td>
                    <td *ngIf="row.closingvalue" [key]="'closingvalue'" fd-table-cell>{{ row.closingvalue }}</td>
                    <td *ngIf="row.voucher" [key]="'voucher'" fd-table-cell>{{ row.voucher }}</td>
                    <td *ngIf="row.ledger" [key]="'ledger'" fd-table-cell>{{ row.ledger }}</td>
                    <td [key]="'total'" fd-table-cell>
                        <fd-object-number [decimal]="0" [emphasized]="true" [number]="row.total" status="positive"
                                          unit="NRS"></fd-object-number>

                    </td>
                    <td *ngIf="row.pan" [key]="'pan'" fd-table-cell>{{ row.pan }}</td>
                    <td *ngIf="row.mobileNo" [key]="'mobileNo'" fd-table-cell>{{ row.mobileNo }}</td>

                    <td *ngIf="row.taxable" [key]="'taxable'" fd-table-cell>{{ row.taxable }}</td>
                    <td *ngIf="row.tax" [key]="'tax'" fd-table-cell>{{ row.tax }}</td>
                    <td *ngIf="row.updateUser" [key]="'updateUser'" fd-table-cell>{{ row.updateUser }}</td>
                    <td *ngIf="row.createUser" [key]="'createUser'" fd-table-cell>{{ row.createUser }}</td>

                    <td *ngIf="row.pdf" [key]="'pdf'" fd-table-cell>
                        <i class="fa-duotone fa-file-pdf"></i>
                    </td>
                    <td *ngIf="row.info" [key]="'info'" fd-table-cell>
                        <i class="bi bi-info-circle-fill text-info"></i>

                    </td>
                    <td *ngIf="row.edit" [key]="'edit'" fd-table-cell>
                        <i (click)="routeToEdit(row.masterId)" class="fa-duotone fa-pen-to-square text-edit"
                           routerLinkActive="router-link-active"></i>
                    </td>
                    <td *ngIf="row.delt" [key]="'delt'" fd-table-cell>
                        <i class="fa-duotone fa-trash"></i>
                    </td>
                </tr>
                </tbody>
            </table>
        </cdk-virtual-scroll-viewport>
        <!-- <fd-busy-indicator [loading]="loading" [block]="true">
            <table fd-table [keys]="displayedColumns" class="table-striped">
                <thead fd-table-header>


                <tr fd-table-row>

                    <th fd-table-cell *ngFor="let column of displayedColumns" [key]="column" >

                        <div *ngIf="column == 'check'; else elseBlock">
                                <fd-checkbox
                                [ngModel]="checkboxValue"
                                (ngModelChange)="selectMaster($event, 'cozy')"
                        ></fd-checkbox></div>
                        <ng-template #elseBlock>
                            {{column | uppercase}}
                        </ng-template>

                    </th>

                </tr>
                </thead>
                <tbody fd-table-body cdkDropList (cdkDropListDropped)="onDrop($event)">
                    <tr *ngFor="let row of tableData; let i = index" fd-table-row (click)="checkRow(i,row.checked,'cozy',$event)" [attr.aria-selected]="row.checked" [activable]="true" [hoverable]="true"  [focusable]="true" tabindex="0"
                    cdkDrag cdkDragLockAxis="y"
                    >
                        <td fd-table-cell  [key]="'check'" style="text-align: center;padding-left: 10px;">
                            <fd-checkbox
                                [ngModel]="row.checked"
                                (ngModelChange)="select(i, $event, 'cozy')"
                            ></fd-checkbox>
                        </td>
                        <td fd-table-cell [key]="'drag'">
                            <div class="drag-handle">
                                <i class="bi bi-grid-3x3-gap-fill"></i>

                            </div>
                        </td>
                        <td *ngIf="row.sn" fd-table-cell  [key]="'sn'">
                            <a href="#">{{ i + 1}}</a>
                        </td>
                        <td  *ngIf="row.branch" fd-table-cell [key]="'branch'">{{ row.branch }}</td>
                        <td  *ngIf="row.date" fd-table-cell [key]="'date'">{{ row.date }}</td>
                        <td  *ngIf="row.name" fd-table-cell [key]="'name'">{{ row.name }}</td>
                        <td  *ngIf="row.group" fd-table-cell [key]="'group'">{{ row.group }}</td>
                        <td  *ngIf="row.brand" fd-table-cell [key]="'brand'">{{ row.brand }}</td>
                        <td  *ngIf="row.rate" fd-table-cell [key]="'rate'">{{ row.rate }}</td>
                        <td  *ngIf="row.openingstock <= 0 || row.openingstock >= 0" fd-table-cell [key]="'openingstock'">{{ row.openingstock }}</td>
                        <td  *ngIf="row.openingstockvalue <= 0 || row.openingstockvalue >= 0" fd-table-cell [key]="'openingstockvalue'">{{ row.openingstockvalue }}</td>
                        <td  *ngIf="row.inwardqty" fd-table-cell [key]="'inwardqty'">{{ row.inwardqty }}</td>
                        <td  *ngIf="row.inwardvalue" fd-table-cell [key]="'inwardvalue'">{{ row.inwardvalue }}</td>
                        <td  *ngIf="row.outwardqty <= 0 || row.outwardqty >= 0" fd-table-cell [key]="'outwardqty'">{{ row.outwardqty }}</td>
                        <td  *ngIf="row.outwardvalue <= 0 || row.outwardvalue >= 0" fd-table-cell [key]="'outwardvalue'">{{ row.outwardvalue }}</td>
                        <td  *ngIf="row.closingqty <= 0 || row.closingqty >= 0" fd-table-cell [key]="'closingqty'">{{ row.closingqty }}</td>
                        <td  *ngIf="row.closingvalue" fd-table-cell [key]="'closingvalue'">{{ row.closingvalue }}</td>
                        <td  *ngIf="row.voucher" fd-table-cell [key]="'voucher'">{{ row.voucher }}</td>
                        <td  *ngIf="row.ledger" fd-table-cell [key]="'ledger'">{{ row.ledger }}</td>
                        <td fd-table-cell [key]="'total'">
                                        <fd-object-number [number]="row.total" unit="NRS" status="positive" [decimal]="0" [emphasized]="true"></fd-object-number>

                        </td>
                        <td  *ngIf="row.pan" fd-table-cell [key]="'pan'">{{ row.pan }}</td>
                        <td  *ngIf="row.mobileNo" fd-table-cell [key]="'mobileNo'">{{ row.mobileNo }}</td>

                        <td fd-table-cell *ngIf="row.taxable" [key]="'taxable'">{{ row.taxable }}</td>
                        <td fd-table-cell *ngIf="row.tax" [key]="'tax'">{{ row.tax }}</td>
                        <td fd-table-cell *ngIf="row.updateUser" [key]="'updateUser'">{{ row.updateUser }}</td>
                        <td fd-table-cell *ngIf="row.createUser" [key]="'createUser'">{{ row.createUser }}</td>

                        <td fd-table-cell *ngIf="row.pdf" [key]="'pdf'" >
                            <i  class="fa-duotone fa-file-pdf"></i>
                    </td>
                    <td fd-table-cell *ngIf="row.info" [key]="'info'">
                        <i class="bi bi-info-circle-fill text-info"></i>

                    </td>
                    <td fd-table-cell *ngIf="row.edit" [key]="'edit'">
                        <i routerLinkActive="router-link-active" (click)="routeToEdit(row.masterId)" class="fa-duotone fa-pen-to-square text-edit"></i>
                    </td>
                    <td fd-table-cell *ngIf="row.delt" [key]="'delt'">
                        <i  class="fa-duotone fa-trash"></i>
                    </td>
                    </tr>
                    </tbody>
            </table>
            </fd-busy-indicator> -->

    </div>
</div>
<ng-template #isMobileScreen>
    <!-- <div class="row align-items-center" style="overflow-y:auto;">
        <table fd-table [popIn]="true" [noBorderX]="true" [noBorderY]="true" [responsive]="true" class="table table-bordered" [keys]="displayedColumns">
            <tr >
              <th fd-table-cell *ngFor="let column of displayedColumns" [key]="column" scope="col">
                <div *ngIf="column == 'check'; else elseBlock">
                    <fd-checkbox
                    [ngModel]="checkboxValue"
                    (ngModelChange)="selectMaster($event, 'cozy')"
            ></fd-checkbox></div>
            <ng-template #elseBlock>
                {{column | uppercase}}
            </ng-template>

              </th>
            </tr>
            <tbody cdkDropList (cdkDropListDropped)="onDrop($event)">
                </tbody>
            <tr *ngFor="let row of tableData; let i = index" fd-table-row (click)="checkRow(i,row.checked,'cozy',$event)" [attr.aria-selected]="row.checked" [activable]="true" [hoverable]="true"  [focusable]="true" tabindex="0"
            cdkDrag cdkDragLockAxis="y">
                <th scope="row">{{i+1}}</th>
                <td>{{item.dateMiti}}</td>
                <td>
                    <fd-info-label *ngIf="item.voucherNo" type="numeric" color="{{item.voucherNo}}" label="{{item.voucherNo}}" ></fd-info-label>

                </td>
                <td>{{item.accountLedgerName}}</td>
                <td>{{item.panNo}}</td>
                <td>{{item.totalAmount}}</td>
                <td>0.000</td>
                <td></td>
                <td> {{item.billDiscount}}</td>
                <td>{{item.taxableAmount}}</td>
                <td>{{item.taxAmount}}</td>
                <td>{{item.createUser}}</td>
                <td>{{item.updateUser}}</td>
                <td style="text-align: center;">

                    <i  routerLink="/app/main/sales/pdf/3/{{item.id}}" class="fa-duotone fa-file-pdf"></i>
                  </td>
                  <td style="text-align: center;">
                    <i [routerLink]="['edit',item.id]" routerLinkActive="router-link-active" class="fa-duotone fa-pen-to-square text-edit"></i>
                  </td>
                  <td style="text-align: center;">
                    <i (click)="delete(item)" class="fa-duotone fa-trash"></i>
                  </td>
              </tr>
          </table>


    </div> -->
</ng-template>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
