import {Component, Injector, OnInit} from '@angular/core';
import {ReportingServiceProxy, TenantDashboardServiceProxy} from '@shared/service-proxies/service-proxies';
import {DashboardChartBase} from '../dashboard-chart-base';
import {WidgetComponentBaseComponent} from '../widget-component-base';

class TodayPaymentTable extends DashboardChartBase {

    todayPayment: any;

    constructor(private _dashboardService: TenantDashboardServiceProxy, private _proxy: ReportingServiceProxy) {
        super();
    }

    init() {
        this.reload();
    }

    reload() {
        this.showLoading();
        this._proxy.getFinancialYears().subscribe(data => {
            this._dashboardService.getRealDashboardData(
                data.toDate, data.fromDate
            ).subscribe(data => {

                this.todayPayment = data.todayPayment;
                this.hideLoading();
            });
        });


    }
}


@Component({
    selector: 'app-widget-today-payment',
    templateUrl: './widget-today-payment.component.html',
    styleUrls: ['./widget-today-payment.component.css']
})
export class WidgetTodayPaymentComponent extends WidgetComponentBaseComponent implements OnInit {


    todayPaymentTable: TodayPaymentTable;

    constructor(injector: Injector,
                private _dashboardService: TenantDashboardServiceProxy, private _proxy: ReportingServiceProxy) {
        super(injector);
        this.todayPaymentTable = new TodayPaymentTable(this._dashboardService, this._proxy);
    }

    ngOnInit() {
        this.todayPaymentTable.init();
    }
}
