import {Component, Injector, ViewChild} from '@angular/core';
import {AppConsts} from '@shared/AppConsts';
import {AppComponentBase} from '@shared/common/app-component-base';
import {PrimengTableHelper} from '@shared/helpers/PrimengTableHelper';
import {
    AbpLoginResultType,
    ProfileServiceProxy,
    UserLoginAttemptDto,
    UserLoginServiceProxy
} from '@shared/service-proxies/service-proxies';
import {DateTime} from 'luxon';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {LazyLoadEvent} from 'primeng/api';
import {Paginator} from 'primeng/paginator';
import {Table} from 'primeng/table';
import {DateTimeService} from '../common/timing/date-time.service';

@Component({
    selector: 'loginAttemptsModal',
    templateUrl: './login-attempts-modal.component.html'
})
export class LoginAttemptsModalComponent extends AppComponentBase {

    @ViewChild('loginAttemptsModal', {static: true}) modal: ModalDirective;
    @ViewChild('dataTable', {static: true}) dataTable: Table;
    @ViewChild('paginator', {static: true}) paginator: Paginator;

    userLoginAttempts: UserLoginAttemptDto[];
    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    defaultProfilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    public filter: string;
    public dateRange: DateTime[] = [this._dateTimeService.getStartOfDay(), this._dateTimeService.getEndOfDay()];
    public loginResultFilter: AbpLoginResultType;

    primengTableHelper = new PrimengTableHelper();

    constructor(
        injector: Injector,
        private _userLoginService: UserLoginServiceProxy,
        private _profileService: ProfileServiceProxy,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
    }

    getLoginAttempts(event?: LazyLoadEvent): void {
        this._userLoginService.getUserLoginAttempts(
            this.filter,
            this._dateTimeService.getStartOfDayForDate(this.dateRange[0]),
            this._dateTimeService.getEndOfDayForDate(this.dateRange[1]),
            this.loginResultFilter,
            this.primengTableHelper.getSorting(this.dataTable),
            this.primengTableHelper.getMaxResultCount(this.paginator, event),
            this.primengTableHelper.getSkipCount(this.paginator, event)
        ).subscribe(result => {
            this.userLoginAttempts = result.items;
            this._profileService.getProfilePicture().subscribe(result => {
                if (result && result.profilePicture) {
                    this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
                }
                this.modal.show();
            });
        });
    }

    close(): void {
        this.modal.hide();
    }

    setProfilePictureClass(userLoginAttemptResult: string): any {
        const classes = {
            label: true,
            'label-success': userLoginAttemptResult === 'Success',
            'label-danger': userLoginAttemptResult !== 'Success'
        };

        return classes;
    }

    getLoginAttemptTime(userLoginAttempt: UserLoginAttemptDto): string {
        return this._dateTimeService.fromNow(userLoginAttempt.creationTime) + ' (' + this._dateTimeService.formatDate(userLoginAttempt.creationTime, 'yyyy-LL-dd hh:mm:ss') + ')';
    }

    show(): void {
        this.modal.show();
    }


}
