import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomEnterDirective} from './custom-enter.directive';
import {SerialEnterDirective} from './serial-enter.directive';
import {PaddingDirective} from './padding.directive';


@NgModule({
    declarations: [CustomEnterDirective, SerialEnterDirective, PaddingDirective],
    imports: [CommonModule],
    exports: [CustomEnterDirective, SerialEnterDirective, PaddingDirective]
})
export class CustomDirectiveModule {
}
