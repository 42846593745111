<div class="header-mobile " id="kt_header_mobile">
    <a routerLink="/" target="_blank">
        <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" [src]="defaultLogo" alt="logo" height="28"/>
        <img *ngIf="appSession.tenant && appSession.tenant.logoId"
             [src]="remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin=' + currentTheme.baseSettings.menu.asideSkin + '&tenantId=' + appSession.tenant.id + '&id=' + appSession.tenant.logoId"
             alt="logo" height="38"/>
    </a>
    <div class="display-flex align-items-center">
        <button class="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
            <span></span>
        </button>
    </div>
</div>
<div [@routerTransition] class="display-flex fx-column flex-root">
    <div class="display-flex flex-row fx-column-fluid page">
        <div [options]="menuCanvasOptions" class="aside aside-left display-flex aside-fixed" id="kt_aside" ktOffcanvas>
            <div class="aside-primary display-flex fx-column align-items-center flex-row-auto">
                <div class="aside-brand display-flex fx-column align-items-center fx-column-auto py-5 py-lg-12"
                     class="height-100vh-minus350px">
                    <theme3-brand></theme3-brand>
                    <div class="aside-nav display-flex fx-column align-items-center fx-column-fluid py-5">
                    </div>
                </div>
                <div class="aside-footer display-flex fx-column align-items-center fx-column-auto py-4 py-lg-10">
                    <active-delegated-users-combo
                            [customStyle]="'btn btn-icon btn-clean btn-lg mb-1 position-relative'">
                    </active-delegated-users-combo>
                    <quick-theme-selection [customStyle]="'btn btn-icon btn-clean btn-lg mb-1 position-relative'">
                    </quick-theme-selection>
                    <language-switch-dropdown [customStyle]="'btn btn-icon btn-clean btn-lg mb-1 position-relative'"
                                              [isDropup]="true">
                    </language-switch-dropdown>
                    <header-notifications [customStyle]="'btn btn-icon btn-clean btn-lg mb-1 position-relative'"
                                          [isDropup]="true">
                    </header-notifications>
                    <chat-toggle-button [customStyle]="'btn btn-icon btn-clean btn-lg mb-1 position-relative'">
                    </chat-toggle-button>
                    <user-menu [iconOnly]="true"
                               [togglerCssClass]="'btn btn-icon btn-clean btn-lg mb-1 position-relative'"></user-menu>
                    <span (click)="triggerAsideToggleClickEvent()"
                          class="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                          data-boundary="window" data-container="body" data-original-title="Toggle Aside"
                          data-placement="right"
                          data-toggle="tooltip" id="kt_aside_toggle" title="">
                        <i class="ki ki-bold-arrow-back icon-sm"></i>
                    </span>
                </div>
            </div>
            <div class="aside-secondary display-flex flex-row-fluid">
                <div class="aside-workspace scroll scroll-push my-2">
                    <div class="tab-pane p-3 px-lg-7 py-lg-5 fade show active" id="kt_aside_tab_1">
                        <side-bar-menu></side-bar-menu>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="display-flex fx-column flex-row-fluid wrapper" id="kt_wrapper">
        <div *ngIf="subscriptionStatusBarVisible()" class="subscription-warning ml-5 mt-5 d-print-none">
            <subscription-notification-bar></subscription-notification-bar>
        </div>

        <div class="flex-1">
            <router-outlet></router-outlet>
        </div>
        <footer-bar></footer-bar>
    </div>
</div>
