<div class="card card-custom height-100">
    <div class="card-header align-items-center border-0">
        <h3 class="card-title align-items-start fx-column">
            <span class="fw-bolder text-dark"> CLASS WISE STUDENT </span>
        </h3>
    </div>

    <div [perfectScrollbar] class="card-body">
        <div class="row">
            <div class="col" class="max-height-200px">
                <ngx-charts-bar-vertical-2d
                        [animations]="animations"
                        [barPadding]="barPadding"
                        [gradient]="Salesgradient"
                        [legend]="showSalesLegend"
                        [results]="dailySales"
                        [showGridLines]="showGridLines"
                        [showXAxisLabel]="showSalesXAxisLabel"
                        [showYAxisLabel]="showSalesYAxisLabel"
                        [tooltipDisabled]="tooltipDisabled"
                        [view]="viewSales"
                        [xAxisLabel]="xAxisSalesLabel"
                        [xAxis]="showSalesXAxis"
                        [yAxisLabel]="yAxisSalesLabel"
                        [yAxis]="showSalesYAxis"
                ></ngx-charts-bar-vertical-2d>

                <!-- <ngx-charts-bar-vertical [view]="viewSales" [scheme]="salesColorScheme" [results]="dailySales"
                    [gradient]="Salesgradient" [xAxis]="showSalesXAxis" [yAxis]="showSalesYAxis"
                    [legend]="showSalesLegend" [showXAxisLabel]="showSalesXAxisLabel"
                    [showYAxisLabel]="showSalesYAxisLabel" [xAxisLabel]="xAxisSalesLabel"
                    [yAxisLabel]="yAxisSalesLabel">
                </ngx-charts-bar-vertical> -->

                <!-- <ngx-charts-bar-vertical [results]="dailySalesLineChart.dailySales"
                                         [scheme]="dailySalesLineChart.scheme">
                    <ng-template #tooltipTemplate let-model="model">
                        <span>Day {{model.name}}</span>
                        <br />
                        <span>{{model.value}}</span>
                    </ng-template>
                </ngx-charts-bar-vertical> -->
            </div>
        </div>
    </div>
</div>
