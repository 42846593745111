<div
        class="header-mobile align-items-center {{currentTheme.baseSettings.header.mobileFixedHeader ? 'header-mobile-fixed':''}}"
        id="kt_header_mobile">
    <a routerLink="/" target="_blank">
        <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" [src]="defaultLogo" alt="logo" height="28"/>
        <img *ngIf="appSession.tenant && appSession.tenant.logoId"
             [src]="remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin=' + currentTheme.baseSettings.menu.asideSkin + '&tenantId=' + appSession.tenant.id + '&id=' + appSession.tenant.logoId"
             alt="logo" height="38"/>
    </a>
    <div class="d-flex align-items-center">
        <button class="default_layout_button p-0" id="kt_aside_mobile_toggle">
            <i class="fa-duotone fa-bars"></i>
        </button>
        <button [options]="userMenuToggleOptions" class="default_layout_button btn-hover-text-primary p-0 ml-2"
                id="kt_header_mobile_topbar_toggle"
                ktToggle>
            <span class="svg-icon svg-icon-xl">
                <svg height="24px" version="1.1"
                     viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                                d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                        <path
                                d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                fill="#000000" fill-rule="nonzero"></path>
                    </g>
                </svg>
            </span>
        </button>
    </div>
</div>

<div [@routerTransition] class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
        <div [options]="menuCanvasOptions"
             class="aside aside-left aside-{{currentTheme.baseSettings.menu.fixedAside ? 'fixed':'static'}} d-flex flex-column flex-row-auto"
             id="kt_aside"
             ktOffcanvas>
            <default-brand></default-brand>
            <div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
                <side-bar-menu></side-bar-menu>
            </div>
        </div>
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <div
                    class="header header-{{currentTheme.baseSettings.header.desktopFixedHeader ? 'fixed':'static'}} head--skin-{{currentTheme.baseSettings.header}}"
                    id="kt_header">
                <div class="{{containerClass}} d-flex align-items-stretch justify-content-between">
                    <div class="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
                    </div>
                    <div class="topbar">
                        <div *ngIf="!isMobileDevice() && subscriptionStatusBarVisible()"
                             class="topbar-item subscription-warning d-print-none">
                            <subscription-notification-bar></subscription-notification-bar>
                        </div>
                        <active-delegated-users-combo *ngIf="!installationMode"></active-delegated-users-combo>
                        <!-- <div  class="topbar-item">
                            <div class="app-header">
                                <div class="app-header-left">
                                  <form class="search-wrapper" onsubmit="event.preventDefault();" role="search">
                                    <input class="search-input" type="search" placeholder="Search" autofocus required>
                                    <span class="hide__search_icon">
                                       <svg id="Layer_1" enable-background="new 0 0 512.392 512.392" height="18" viewBox="0 0 512.392 512.392" width="18" xmlns="http://www.w3.org/2000/svg"><g><path d="m211.196 422c-116.346 0-211-94.654-211-211s94.654-211 211-211 211 94.654 211 211-94.654 211-211 211zm0-382c-94.29 0-171 76.71-171 171s76.71 171 171 171 171-76.71 171-171-76.71-171-171-171zm295.143 466.534c7.81-7.811 7.81-20.475 0-28.285l-89.5-89.5c-7.811-7.811-20.475-7.811-28.285 0s-7.81 20.475 0 28.285l89.5 89.5c3.905 3.905 9.024 5.857 14.143 5.857s10.236-1.952 14.142-5.857z"/></g></svg>
                                    </span>
                                   </form>
                                </div>
                            </div>
                        </div> -->
                        <topbar-search *ngIf="currentTheme.baseSettings.menu.searchActive"></topbar-search>


                        <!-- <Nepali-Calender></Nepali-Calender> -->
                        <quick-theme-selection></quick-theme-selection>
                        <language-switch-dropdown></language-switch-dropdown>
                        <header-notifications></header-notifications>
                        <!-- <calendar-button></calendar-button> -->
                        <chat-toggle-button></chat-toggle-button>
                        <user-menu></user-menu>
                    </div>
                </div>

            </div>
            <div *ngIf="isMobileDevice() && subscriptionStatusBarVisible()"
                 class="p-3 mobile-subscription-warning d-print-none">
                <subscription-notification-bar></subscription-notification-bar>
            </div>
            <div class="flex-1">
                <router-outlet></router-outlet>
            </div>
            <footer-bar></footer-bar>
        </div>
    </div>
</div>
