<div class="card card-custom h-100">
    <div class="card-header align-items-center border-0">
        <div class="card-title">
            <h3 class="card-label text-dark">
                Top Five Sales
                <small class="text-muted">Top Five Sales between customers</small>
            </h3>
        </div>
    </div>
    <div [perfectScrollbar] class="card-body">
        <div>
            <div class="row">
                <div class="col-lg-6 col-md-12" class="max-height-300px">
                    <ngx-charts-pie-chart
                            [animations]="false"
                            [doughnut]="true"
                            [labels]="false"
                            [legend]="false"
                            [results]="topFiveSalesPieChart.chartData"
                            [scheme]="topFiveSalesPieChart.scheme"
                            [tooltipDisabled]="true"
                    >
                    </ngx-charts-pie-chart>
                </div>
                <div *ngIf="topFiveSalesPieChart.chartData.length" class="col-lg-6 col-md-12">
                    <div>
                        <span class="label label-dot label-lg label-success mr-3"></span>
                        <span ng-if="topFiveSalesPieChart.chartData">
                            {{ topFiveSalesPieChart.chartData[0].value }}
                        </span>
                    </div>
                    <div>
                        <span class="label label-dot label-lg label-warning mr-3"></span>
                        <span> 22% Online Courses </span>
                    </div>
                    <div>
                        <span class="label label-dot label-lg label-brand mr-3"></span>
                        <span> 33% Custom Development </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
