import {DMType, DMTypeNp} from './types';

// @dynamic
export class DateModel {
    public static dateLanguage: string = 'np';

    public static weekDataEn: DMType[] = [
        {
            id: 1,
            name: 'Sunday',
            abbr: 'Su'
        }, {
            id: 2,
            name: 'Monday',
            abbr: 'Mo'
        }, {
            id: 3,
            name: 'Tuesday',
            abbr: 'Tu'
        }, {
            id: 4,
            name: 'Wednesday',
            abbr: 'We'
        }, {
            id: 5,
            name: 'Thursday',
            abbr: 'Th'
        }, {
            id: 6,
            name: 'Friday',
            abbr: 'Fr'
        }, {
            id: 7,
            name: 'Saturday',
            abbr: 'Sa'
        }
    ];
    public static weekDataNp: DMTypeNp[] = [
        {
            id: 1,
            name: 'आइतवार',
            englishName: 'Sunday',
            shortName: 'आइत',
            abbr: 'आ'
        }, {
            id: 2,
            name: 'सोमवार',
            englishName: 'Monday',
            abbr: 'सो',
            shortName: 'सोम'
        }, {
            id: 3,
            name: 'मङ्गलवार',
            englishName: 'Tuesday',
            abbr: 'म',
            shortName: 'मङ्गल'
        }, {
            id: 4,
            name: 'बुधवार',
            englishName: 'Wednesday',
            abbr: 'बु',
            shortName: 'बुध'
        }, {
            id: 5,
            name: 'बिहिवार',
            englishName: 'Thursday',
            abbr: 'बि',
            shortName: 'बिहि'
        }, {
            id: 6,
            name: 'शुक्रवार',
            abbr: 'शु',
            englishName: 'Friday',
            shortName: 'शुक्र'
        }, {
            id: 7,
            name: 'शनिवार',
            englishName: 'Saturday',
            abbr: 'श',
            shortName: 'शनि'
        }
    ];

    public static monthDataEn: DMType[] = [
        {
            id: 1,
            name: 'January',
            abbr: 'Jan'
        }, {
            id: 2,
            name: 'February',
            abbr: 'Feb'
        }, {
            id: 3,
            name: 'March',
            abbr: 'Mar'
        }, {
            id: 4,
            name: 'April',
            abbr: 'Apr'
        }, {
            id: 5,
            name: 'May',
            abbr: 'May'
        }, {
            id: 6,
            name: 'June',
            abbr: 'Jun'
        }, {
            id: 7,
            name: 'July',
            abbr: 'Jul'
        }, {
            id: 8,
            name: 'August',
            abbr: 'Aug'
        }, {
            id: 9,
            name: 'September',
            abbr: 'Sep'
        }, {
            id: 10,
            name: 'Octuber',
            abbr: 'Oct'
        }, {
            id: 11,
            name: 'November',
            abbr: 'Nov'
        }, {
            id: 12,
            name: 'December',
            abbr: 'Dec'
        }
    ];
    public static monthDataNp: DMType[] = [
        {
            id: 1,
            name: 'बैशाख',
            abbr: 'बैशाख'
        }, {
            id: 2,
            name: 'जेठ',
            abbr: 'जेठ'
        }, {
            id: 3,
            name: 'आषाढ',
            abbr: 'आषाढ'
        }, {
            id: 4,
            name: 'साउन',
            abbr: 'साउन'
        }, {
            id: 5,
            name: 'भाद्र',
            abbr: 'भाद्र'
        }, {
            id: 6,
            name: 'आश्विन',
            abbr: 'आश्विन'
        }, {
            id: 7,
            name: 'कार्तिक',
            abbr: 'कार्तिक'
        }, {
            id: 8,
            name: 'मंसिर',
            abbr: 'मंसिर'
        }, {
            id: 9,
            name: 'पौष',
            abbr: 'पौष'
        }, {
            id: 10,
            name: 'माघ',
            abbr: 'माघ'
        }, {
            id: 11,
            name: 'फाल्गुण',
            abbr: 'फाल्गुण'
        }, {
            id: 12,
            name: 'चैत्र',
            abbr: 'चैत्र'
        }
    ];

    // public static initialDateEn: Date = new Date(1943, 3, 14);

    public static get initialDateEn(): Date {
        return new Date(1943, 3, 14); //First Param = 1943 => year, Second Param = 3(April) => monthIndex(index starts from 0), Third Param = 14 => day of month;
    }

    // public static initialDateNp : Date = new Date(2000, 1, 1); //First Param = 2000 => year, Second Param = 1(Baishak) => monthIndex(index starts from 0), Third Param = 14 => day of month

    private static _nepaliDateData: number[][] = [
        [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],  //2000
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],  //2001
        [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
        [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
        [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
        [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
        [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
        [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
        [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
        [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
        [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
        [30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
        [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
        [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
        [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
        [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
        [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
        [30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
        [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
        [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],  //2071
        [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],  //2072
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],  //2073
        [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],  //2075
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
        [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30], //78
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
        [31, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30],
        [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
        [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
        [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
        [31, 32, 31, 32, 30, 31, 30, 30, 29, 30, 30, 30],
        [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
        [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30],
        [30, 31, 32, 32, 30, 31, 30, 30, 29, 30, 30, 30],
        [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
        [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],  //2090
        [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30],
        [30, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30],
        [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
        [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
        [31, 31, 32, 31, 31, 31, 30, 29, 30, 30, 30, 30],
        [30, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
        [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
        [31, 31, 32, 31, 31, 31, 29, 30, 29, 30, 29, 31],
        [31, 31, 32, 31, 31, 31, 30, 29, 29, 30, 30, 30]   //2099
    ];
    public static get nepaliDateData(): number[][] {
        return this._nepaliDateData;
    }
}
