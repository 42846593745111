import {Component, Injector, OnInit} from '@angular/core';
import {TenantDashboardServiceProxy} from '@shared/service-proxies/service-proxies';
import {DashboardChartBase} from '../dashboard-chart-base';
import {WidgetComponentBaseComponent} from '../widget-component-base';

class LocationWidgetStockLineChart extends DashboardChartBase {


    chartData: any[];
    scheme: any = {
        name: 'green',
        selectable: true,
        group: 'Ordinal',
        domain: [
            '#34bfa3'
        ]
    };

    constructor(private _dashboardService: TenantDashboardServiceProxy) {
        super();
    }

    reload() {
        this.showLoading();
        this._dashboardService;
        // .getTodayPayment()
        //   .subscribe(result => {
        //     this.init(result.todayPayment);
        //     this.hideLoading();
        //   });
    }

    init(locationWidgetStock: any) {

    }

}


@Component({
    selector: 'app-widgets-location-widget-stock',
    templateUrl: './widgets-location-widget-stock.component.html',
    styleUrls: ['./widgets-location-widget-stock.component.css']
})
export class WidgetsLocationWidgetStockComponent extends WidgetComponentBaseComponent implements OnInit {

    locationWidgetStockLineChart: LocationWidgetStockLineChart;


    constructor(injector: Injector,
                private _tenantdashboardService: TenantDashboardServiceProxy) {
        super(injector);
        this.locationWidgetStockLineChart = new LocationWidgetStockLineChart(this._tenantdashboardService);
    }


    ngOnInit(): void {
        this.locationWidgetStockLineChart.reload();
    }

}
