<div class="card card-custom h-100">
    <div class="card-header align-items-center border-0">
        <h3 class="card-title align-items-start fx-column">
            <span class="fw-bolder text-dark">
                 General Stats
            </span>
        </h3>
        <div class="card-toolbar">
            <a (click)="generalStatsPieChart.reload()" class="btn btn-primary" href="javascript:">
                <i class="pi pi-redo"></i> Refresh
            </a>
        </div>
    </div>
    <div [perfectScrollbar] class="card-body">
        <div class="row" class="max-height-200px">
            <ngx-charts-pie-grid [results]="generalStatsPieChart.data">
            </ngx-charts-pie-grid>
        </div>
    </div>
</div>
