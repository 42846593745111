import {Component, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {DateTimeService} from '@app/shared/common/timing/date-time.service';
import {
    DATE_TIME_FORMATS,
    DatetimeAdapter,
    FD_DATETIME_FORMATS,
    FdDate,
    FdDatetimeAdapter
} from '@fundamental-ngx/core';
import {AppComponentBase} from '@shared/common/app-component-base';
import {CurrentFinancialYearDto, ReportingServiceProxy} from '@shared/service-proxies/service-proxies';
import {DateTime} from 'luxon';

const DATE = FdDate.getNow();

@Component({
    selector: 'app-sap-date-range-picker',
    templateUrl: './sap-date-range-picker.component.html',
    styleUrls: ['./sap-date-range-picker.component.css'],
    providers: [
        {
            provide: DatetimeAdapter,
            useClass: FdDatetimeAdapter
        },
        {
            provide: DATE_TIME_FORMATS,
            useValue: FD_DATETIME_FORMATS
        }
    ]
})
export class SapDateRangePickerComponent extends AppComponentBase implements OnInit {
    customForm = new FormGroup({
        dates: new FormControl({
            start: this.datetimeAdapter.addCalendarDays(DATE, -7),
            end: DATE
        })
    });
    selectedDateRange: DateTime[] = [this._dateTimeService.getStartOfDayMinusDays(7), this._dateTimeService.getEndOfDay()];
    financialYear!: CurrentFinancialYearDto;

    constructor(
        injector: Injector,
        private datetimeAdapter: DatetimeAdapter<FdDate>,
        private _allProxy: ReportingServiceProxy,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
        this._allProxy.getFinancialYears().subscribe(x => {
            this.financialYear = x;

        });
    }

    get FromFdDate(): FdDate {
        let y = this.financialYear?.fromDate?.year;
        let m = this.financialYear?.fromDate?.month;
        let d = this.financialYear?.fromDate?.day;
        return new FdDate(y, m, d);
    }

    get ToFdDate(): FdDate {
        let y = this.financialYear?.toDate?.year;
        let m = this.financialYear?.toDate?.month;
        let d = this.financialYear?.toDate?.day;
        return new FdDate(y, m, d);
    }

    ngOnInit(): void {
        this.customForm.get('dates').valueChanges.subscribe(x => {
            console.log(this._dateTimeService.getStartOfDayMinusDays(7));
            const start = x.start;
            const end = x.end;
            //   console.log(this._dateTimeService.createDate(start.))
            if (start === end) {

                abp.event.trigger('app.dashboardFilters.dateRangePicker.onDateChange', this.selectedDateRange);

            } else {
                abp.event.trigger('app.dashboardFilters.dateRangePicker.onDateChange', this.selectedDateRange);
            }
        });
    }

    isValid(): boolean {
        return this.customForm.get('dates').valid;
    }

// myDisableFunction2 = (date: FdDate): boolean => {
//     // const check = this.datetimeAdapter.isBetween(date,new FdDate(this.fromDate.year, this.fromDate.month, this.fromDate.day),
//     // new FdDate(this.toDate.year, this.toDate.month, this.toDate.day));
//     // return !check
// }

// disabledEndFunction = (fdDate: FdDate): boolean =>
//         this.datetimeAdapter.compareDate(DATE, fdDate) > 0 ||
//         this.datetimeAdapter.compareDate(fdDate, this._getFutureDate(DATE)) > 0;

//     disabledStartFunction = (fdDate: FdDate): boolean =>
//         this.datetimeAdapter.compareDate(DATE, fdDate) > 0 ||
//         this.datetimeAdapter.compareDate(fdDate, this._getFutureDate(DATE)) > 0;

    disabledStartFunction = (fdDate: FdDate): boolean =>
        this.datetimeAdapter.compareDate(DATE, fdDate) > 0
        ||
        this.datetimeAdapter.compareDate(fdDate, this.FromFdDate) > 0;

    disabledEndFunction = (fdDate: FdDate): boolean =>
        this.datetimeAdapter.compareDate(DATE, fdDate) > 0
        ||
        this.datetimeAdapter.compareDate(fdDate, this.ToFdDate) > 0;


    myDisableFunction2 = (date: FdDate): boolean => {
        const check = this.datetimeAdapter.isBetween(date, this.FromFdDate, this.ToFdDate);
        return !check;
    };

    fdDateChanges(e: any) {
        // let npDateNow = this.fdToLocale(e);
        // this.selectedYear = npDateNow.yearBs;
        // this.selectedMonth = npDateNow.monthBs;
        // this.selectDay(npDateNow.dayBs.day.toString(), npDateNow.dayBs.weekDay.id.toString());
        // this.selectedDate = {
        //     year: parseInt(this.selectedYear),
        //     month: this.selectedMonth.id,
        //     dayOfMonth: parseInt(this.selectedDay),
        // };
    }

    onChange(e) {
        console.log(e.toDateString());
        let fdate: FdDate = e.start;

    }

    submit() {

    }
}
