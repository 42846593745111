<div class="card card-custom h-100">
    <div class="card-header align-items-center border-0">
        <h3 class="card-title align-items-start fx-column">
            <span class="font-weight-bolder text-dark"> Today Service </span>
        </h3>
        <div class="card-toolbar">
            <button (click)="todayServiceTable.reload()" class="btn btn-primary"><i class="pi pi-refresh"></i> Refresh
            </button>
        </div>
    </div>
    <div [perfectScrollbar] class="card-body">
        <div class="table-scrollable table-scrollable-borderless">
            <table class="table table-hover table-light">
                <thead>
                <tr class="uppercase">
                    <th>BRANCH NAME</th>
                    <th>DATE MITI</th>
                    <th>VOUCHER NO</th>
                    <th>LEDGER NAME</th>
                    <th>MOBILE NO</th>
                </tr>
                </thead>
                <tr *ngFor="let m of todayServiceTable.todayService; let index = index" class="ml-2">
                    <td class="pl-0">
                        <div class="symbol symbol-50 symbol-light mt-2 mr-2">
                            {{ m.branchName }}
                        </div>
                    </td>
                    <td class="pl-0">
                        <a class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                           href="javascript:">{{ m.name }}</a>
                    </td>
                    <td>
                        {{ m.date | luxonFormat: "yyyy-LL-dd" }}
                    </td>
                    <td>
                        {{ m.voucherNo }}
                    </td>
                    <td>
                        {{ m.ledgerName }}
                    </td>
                    <td>
                        {{ m.mobileNo }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
