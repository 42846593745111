<div class="card card-custom h-100">
    <div class="card-header align-items-center border-0">
        <div class="card-title">
            <span class="card-icon">
                <i class="fas fa-user-plus text-primary"></i>
            </span>
            <h3 class="card-label text-primary">
                {{"RecentTenants" | localize}}
                <small *ngIf="!loading" class="text-muted">
                    {{"RecentTenantsHelpText" | localize:recentTenantsData.recentTenantsDayCount : recentTenantsData.maxRecentTenantsShownCount}}
                </small>
            </h3>
        </div>
    </div>
    <div [perfectScrollbar] class="card-body">
        <div [perfectScrollbar] class="scroller chart">
            <div *ngIf="!loading" [busyIf]="primengTableHelper.isLoading"
                 class="primeng-datatable-container recent-tenants-table">
                <p-table #RecentTenantsTable [lazy]="false"
                         [paginator]="false" [responsive]="primengTableHelper.isResponsive"
                         [value]="recentTenantsData.recentTenants"
                         rows="{{primengTableHelper.defaultRecordsCountPerPage}}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'TenantName' | localize}}</th>
                            <th>{{'CreationTime' | localize}}</th>
                        </tr>
                    </ng-template>
                    <ng-template let-record="$implicit" pTemplate="body">
                        <tr>
                            <td>
                                <span class="p-column-title">{{'TenantName' | localize}}</span>
                                {{record.name}}
                            </td>
                            <td>
                                <span
                                        class="p-column-title">{{'CreationTime' | localize}}</span>
                                {{ record.creationTime | luxonFormat:'F'}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div *ngIf="recentTenantsData?.recentTenants?.length == 0" class="primeng-no-data">
                    {{'NoData' | localize}}
                </div>
            </div>
        </div>
        <div class="scroller-footer">
            <div class="btn-arrow-link pull-right">
                <a (click)="gotoAllRecentTenants()" class="see-all-recent-tenants"
                   href="javascript:">{{"SeeAllRecords" | localize}}</a>
                <i class="icon-arrow-right"></i>
            </div>
        </div>
    </div>
</div>
