<div #addWidgetModal="bs-modal" [config]="{ backdrop: 'static' }" appBsModal aria-hidden="true" class="modal fade"
     role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #addWidgetModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{ "AddWidget" | localize }}</span>
                    </h5>
                    <button (click)="close()" [attr.aria-label]="l('Close')" class="close" type="button">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div *ngIf="widgets && widgets.length > 0" class="form-group">
                        <select [(ngModel)]="selectedWidgetId" autoFocus class="form-control" name="selectedWidget"
                                size="1">
                            <option *ngFor="let widget of widgets" [value]="widget.id">{{ widget.name }}</option>
                        </select>
                    </div>
                    <div *ngIf="!widgets || widgets.length == 0"
                         class="form-group">{{ "NoWidgetAvailableMessage" | localize }}</div>
                </div>
                <div class="modal-footer">
                    <button (click)="close()" [disabled]="saving" class="btn btn-light-primary fw-bold" type="button">
                        {{ "Cancel" | localize }}
                    </button>
                    <button [disabled]="saving" class="btn btn-primary fw-bold" type="submit">
                        <i class="pi pi-save"></i>
                        <span>{{ "Save" | localize }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
