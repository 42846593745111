<div #userDelegationsModal="bs-modal" (onShown)="onShown()" [config]="{keyboard: false}" appBsModal aria-hidden="true"
     aria-labelledby="myLargeModalLabel" class="modal fade"
     role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{"UserDelegations" | localize}}</span>
                </h4>
                <button (click)="manageUserDelegations()" class="btn btn-primary pull-right"><i
                        class="pi pi-save"></i>{{"DelegateNewUser" | localize}}</button>
            </div>
            <div class="modal-body">
                <!--<Primeng-TurboTable-Start>-->
                <div [busyIf]="primengTableHelper.isLoading"
                     class="primeng-datatable-container">
                    <p-table #dataTable
                             (onLazyLoad)="getUserDelegations($event)"
                             [lazy]="false"
                             [paginator]="false"
                             [responsive]="primengTableHelper.isResponsive"
                             [value]="primengTableHelper.records"
                             rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                             sortMode="multiple">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-30" pSortableColumn="userName">
                                    {{'UserName' | localize}}
                                    <p-sortIcon field="userName"></p-sortIcon>
                                </th>
                                <th class="width-20">
                                    {{'StartTime' | localize}}
                                </th>
                                <th class="width-20">
                                    {{'EndTime' | localize}}
                                </th>
                                <th class="width-10">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template let-record="$implicit" pTemplate="body">
                            <tr>
                                <td class="width-30">
                                    <span class="p-column-title"> {{'UserName' | localize}}</span>
                                    {{record.username}}
                                </td>
                                <td class="width-20">
                                    <span class="p-column-title"> {{'StartTime' | localize}}</span>
                                    {{record.startTime | luxonFormat:'F	'}}
                                </td>
                                <td class="width-20">
                                    <span class="p-column-title"> {{'EndTime' | localize}}</span>
                                    {{record.endTime | luxonFormat:'F	'}}
                                </td>
                                <td class="width-10">
                                    <button (click)="deleteUserDelegation(record)"
                                            class="btn btn-outline-danger btn-sm btn-icon">
                                        <i [attr.aria-label]="l('Delete')" class="pi pi-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div *ngIf="primengTableHelper.totalRecordsCount == 0" class="primeng-no-data">
                        {{'NoData' | localize}}
                    </div>
                    <div class="primeng-paging-container">
                        <p-paginator #paginator
                                     (onPageChange)="getUserDelegations($event)"
                                     [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                                     [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                     [totalRecords]="primengTableHelper.totalRecordsCount">
                        </p-paginator>
                        <span class="total-records-count">
                            {{'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount}}
                        </span>
                    </div>
                </div>
                <!--<Primeng-TurboTable-End>-->
            </div>
            <div class="modal-footer">
                <button (click)="close()" class="btn btn-light-primary font-weight-bold pull-right"
                        type="button">{{"Close" | localize}}</button>
            </div>
        </div>
    </div>
</div>
<createNewUserDelegation #createNewUserDelegation (modalSave)="getUserDelegations()"></createNewUserDelegation>
