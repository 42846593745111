<div class="card card-custom h-100">
    <div class="card-header align-items-center border-0">
        <h3 class="card-title align-items-start fx-column">
            <span class="fw-bolder text-dark"> Sales Summary </span>
        </h3>

    </div>
    <div [busyIf]="salesSummaryChart.loading" [perfectScrollbar] class="card-body">
        <div class="row list-separated text-center">
            <div class="col-md-3 col-sm-3 width-50">
                <h6>Total Sales</h6>
                <div>
                    <span
                            (countoChange)="salesSummaryChart.totalSalesCounter = $event"
                            [countFrom]="0"
                            [countTo]="salesSummaryChart.totalSales"
                            [duration]="1"
                            [step]="30"
                            class="text-danger font-weight-bolder"
                            counto
                    >
                        {{ salesSummaryChart.totalSalesCounter.toFixed(0) }}
                    </span>
                    <span class="text-danger font-weight-bolder">$</span>
                </div>
            </div>
            <div class="col-md-3 col-sm-3 width-50">
                <h6>Revenue</h6>
                <div class="uppercase font-hg font-green-haze">
                    <span
                            (countoChange)="salesSummaryChart.revenuesCounter = $event"
                            [countFrom]="0"
                            [countTo]="salesSummaryChart.revenue"
                            [duration]="1"
                            [step]="30"
                            class="text-warning font-weight-bolder"
                            counto
                    >
                        {{ salesSummaryChart.revenuesCounter.toFixed(0) }}
                    </span>
                    <span class="text-warning font-weight-bolder">$</span>
                </div>
            </div>
            <div class="col-md-3 col-sm-3 width-50">
                <h6>Expenses</h6>
                <div class="uppercase font-hg font-purple">
                    <span
                            (countoChange)="salesSummaryChart.expensesCounter = $event"
                            [countFrom]="0"
                            [countTo]="salesSummaryChart.expenses"
                            [duration]="1"
                            [step]="30"
                            class="text-info font-weight-bolder"
                            counto
                    >
                        {{ salesSummaryChart.expensesCounter.toFixed(0) }}
                    </span>
                    <span class="text-success font-weight-bolder">$</span>
                </div>
            </div>
            <div class="col-md-3 col-sm-3 width-50">
                <h6>Growth</h6>
                <div class="uppercase font-hg font-blue-sharp">
                    <span
                            (countoChange)="salesSummaryChart.growthCounter = $event"
                            [countFrom]="0"
                            [countTo]="salesSummaryChart.growth"
                            [duration]="1"
                            [step]="30"
                            class="text-info font-weight-bolder"
                            counto
                    >
                        {{ salesSummaryChart.growthCounter.toFixed(0) }}
                    </span>
                    <span class="text-info font-weight-bolder">$</span>
                </div>
            </div>
        </div>
        <div class="height-260px min-height-260px;">
            <ngx-charts-area-chart-stacked
                    [results]="salesSummaryChart.data"
                    [showGridLines]="false"
                    [showXAxisLabel]="true"
                    [tooltipDisabled]="false"
                    [xAxis]="true"
                    [yAxis]="true"
                    showYAxisLabel="true"
            >
            </ngx-charts-area-chart-stacked>
        </div>
    </div>
</div>
