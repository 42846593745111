<div class="pickerContainer" fd-form-item id="pickerContainer">
    <input (blur)="onTouched ? onTouched() : ''" (input)="onChange($event.target.value)"
           (keyup)="selectDate($event, value)" [(ngModel)]="value"
           [disabled]="disabled" [ngClass]="inputClass" [ngStyle]="inputStyle"
           [placeholder]="placeholder" [value]="value" autocomplete="off" fd-form-control id="npDatePicker"
           type="text">
    <!-- <ng-content></ng-content> -->
    <div [ngClass]="calenderClass" [ngStyle]="pickerCalenderStyle" class="pickerCalender" id="pickerCalender">
        <div class="dateNavigator">
            <a (click)="decrementMonth()" class="arrowButton" href="javascript:void(0)"><i
                    class="pi pi-chevron-left"></i></a>
            <div class="YMSection">
                <select [(ngModel)]="selectedYear">
                    <option *ngFor="let year of arrayOne(100); let i = index" [value]="i+2000">{{i + 2000}}</option>
                </select>

                <select [(ngModel)]="selectedMonthId">
                    <option *ngFor="let month of monthData" [value]="month.id">{{month.abbr}}</option>
                </select>
            </div>

            <a (click)="incrementMonth()" class="arrowButton" href="javascript:void(0)"><i
                    class="pi pi-chevron-right"></i></a>
        </div>
        <!-- <div class="selectedDateText">
            {{selectedMonth.name}}{{selectedMonth && selectedDay ? ",": ""}} {{selectedDay}} {{selectedYear}}
        </div> -->
        <div class="weekText">
            <div *ngFor="let week of weekData">
                {{week.abbr}}
            </div>
        </div>
        <div class="days">
            <div (click)="selectDay(day.day, day.dayOfWeek)"
                 *ngFor="let day of dayData"
                 [ngClass]="{'active':(day.day==selectedDate.dayOfMonth&&selectedMonth.id==selectedDate.month&&selectedYear==selectedDate.year.toString()), 'enabled':day.enabled}">
                {{day.day}}
            </div>
        </div>
        <!-- <button *ngIf="todayBtn" (click)="selectTodayDate()" class="todaybtn">Today</button> -->
    </div>
</div>
