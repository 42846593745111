export class DashboardCustomizationConst {
    static widgets = {
        tenant: {
            profitShare: 'Widgets_Tenant_ProfitShare',
            memberActivity: 'Widgets_Tenant_MemberActivity',
            regionalStats: 'Widgets_Tenant_RegionalStats',
            salesSummary: 'Widgets_Tenant_SalesSummary',
            companyFinance: 'Widgets_Tenant_CompanyFinance',
            meetingSchedule: 'Widgets_Tenant_MeetingSchedule',
            servicePending: 'Widgets_Tenant_ServicePending',
            softwareExpired: 'Widgets_Tenant_SoftwareExpired',
            stockMoment: 'Widgets_Tenant_StockMoment',
            topStats: 'Widgets_Tenant_TopStats',
            generalStats: 'Widgets_Tenant_GeneralStats',
            dailySales: 'Widgets_Tenant_DailySales',
            purchaseSummary: 'Widgets_Tenant_PurchaseSummary',
            todayReceive: 'Widgets_Tenant_TodayReceive',
            todayReceipt: 'Widgets_Tenant_TodayReceipt',
            todayService: 'Widgets_Tenant_TodayService',
            DeliveryNotePending: 'Widgets_Tenant_DeliveryNotePending',
            TodayTask: 'Widgets_Tenant_Task',

            topFiveSales: 'Widgets_Tenant_TopFiveSales',
            todayPayment: 'Widgets_Tenant_TodayPayment',
            interest: 'Widgets_Tenant_Interest',
            stockTransfer: 'Widgets_Tenant_ StockTransfer',
            productMovement: 'Widgets_Tenant_ ProductMovement',
            locationWidgetStock: 'Widgets_Tenant_LocationWidgetStock',
            profitProductStatistics: 'Widgets_Tenant_ProfitProductStatistics',
            topSallingItemBySalesAmount: 'Widgets_Tenant_TopSallingItemBySalesAmount',
            topCustomerBySalesAmount: 'Widgets_Tenant_TopCustomerBySalesAmount',
            opportunitiesInLeadStatus: 'Widgets_Tenant_OpportunitiesInLeadStatus',
            inventoryValue: 'Widgets_Tenant_InventoryValue',
            payableOverdue: 'Widgets_Tenant_PayableOverdue',
            topVendorBySalesAmount: 'Widgets_Tenant_TopVendorBySalesAmount',
            salesOrderAmountThisMonth: 'Widgets_Tenant_SalesOrderAmountThisMonth'


        },
        host: {
            topStats: 'Widgets_Host_TopStats',
            incomeStatistics: 'Widgets_Host_IncomeStatistics',
            editionStatistics: 'Widgets_Host_EditionStatistics',
            subscriptionExpiringTenants: 'Widgets_Host_SubscriptionExpiringTenants',
            recentTenants: 'Widgets_Host_RecentTenants'
        }
    };
    static filters = {
        filterDateRangePicker: 'Filters_DateRangePicker'
    };
    static dashboardNames = {
        defaultTenantDashboard: 'TenantDashboard',
        defaultHostDashboard: 'HostDashboard'
    };
    static Applications = {
        Angular: 'Angular'
    };
}
