import {Component, Inject, Injector, OnInit, ViewChild} from '@angular/core';
import {appModuleAnimation} from '@shared/animations/routerTransition';
import {ThemesLayoutBaseComponent} from '@app/shared/layout/themes/themes-layout-base.component';
import {UrlHelper} from '@shared/helpers/UrlHelper';
import {DOCUMENT, Location} from '@angular/common';
import {OffcanvasOptions} from '@metronic/app/core/_base/layout/directives/offcanvas.directive';
import {AppConsts} from '@shared/AppConsts';
import {ToggleOptions} from '@metronic/app/core/_base/layout/directives/toggle.directive';
import {DateTimeService} from '@app/shared/common/timing/date-time.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AbpSessionService} from 'abp-ng2-module';

@Component({
    templateUrl: './default-layout.component.html',
    selector: 'default-layout',
    animations: [appModuleAnimation()],
    styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent extends ThemesLayoutBaseComponent implements OnInit {
    isHost = false;
    @ViewChild('filterModal', {static: true}) modal: ModalDirective;
    menuCanvasOptions: OffcanvasOptions = {
        baseClass: 'aside',
        overlay: true,
        closeBy: 'kt_aside_close_btn',
        toggleBy: 'kt_aside_mobile_toggle'
    };

    userMenuToggleOptions: ToggleOptions = {
        target: this.document.body,
        targetState: 'topbar-mobile-on',
        toggleState: 'active'
    };

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    form: FormGroup;
    today: any;

    constructor(
        injector: Injector,
        private _location: Location,
        private _abpSessionService: AbpSessionService,
        private _fb: FormBuilder,
        @Inject(DOCUMENT) private document: Document,
        _dateTimeService: DateTimeService
    ) {
        super(injector, _dateTimeService);
    }

    ngOnInit() {
        this.isHost = !this._abpSessionService.tenantId;
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    }
}
