import {Component, Injector, OnInit} from '@angular/core';
import {DashboardRealDataGeneratorServiceProxy} from '@shared/service-proxies/service-proxies';
import {DashboardChartBase} from '../dashboard-chart-base';
import {WidgetComponentBaseComponent} from '../widget-component-base';

class SoftwareExpiredTable extends DashboardChartBase {

    softwareExpired: any;

    constructor(private _dashboardService: DashboardRealDataGeneratorServiceProxy) {
        super();
    }

    reload() {
        this.showLoading();
        this._dashboardService
            .getDashboardSummary('2075/10/10', '2079/12/30')
            .subscribe(result => {
                this.softwareExpired = result.softwareExpired;
                this.hideLoading();
            });
    }

    init() {
        this.reload();
    }

}


@Component({
    selector: 'app-widget-software-expired',
    templateUrl: './widget-software-expired.component.html',
    styleUrls: ['./widget-software-expired.component.css']
})
export class WidgetSoftwareExpiredComponent extends WidgetComponentBaseComponent implements OnInit {
    softwareExpiredTable: SoftwareExpiredTable;

    constructor(injector: Injector,
                private _tenantdashboardService: DashboardRealDataGeneratorServiceProxy) {
        super(injector);
        this.softwareExpiredTable = new SoftwareExpiredTable(this._tenantdashboardService);
    }

    ngOnInit(): void {
        this.softwareExpiredTable.init();
    }


}
