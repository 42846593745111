<div class="pickerContainer" fd-form-item id="pickerContainer">
    <input
            (blur)="onTouched ? onTouched() : ''"
            (input)="onChange($event.target.value)"
            (keyup)="selectDate($event, value)"
            [(ngModel)]="value"
            [disabled]="disabled"
            [ngClass]="inputClass"
            [ngStyle]="inputStyle"
            [placeholder]="placeholder"
            [readonly]="dateReadonly"
            [value]="value"
            autocomplete="off"
            fd-form-control
            [id]="this.npDatePickerId"
            type="text"
    />
    <div *ngIf="!dateReadonly" [ngClass]="calenderClass" [ngStyle]="pickerCalenderStyle" class="dualCalender"
         id="dualCalender">
        <!-- <div class="npCalendar" id="npCalendar">
            <div class="dateNavigator">
                <a (click)="decrementMonth()" class="arrowButton" href="javascript:void(0)"><i
                    class="pi pi-chevron-left"></i></a>
                <div class="YMSection">


                    <select [(ngModel)]="selectedMonthId">
                        <option *ngFor="let month of monthData" [value]="month.id">{{ month.abbr }}</option>
                    </select>
                    <select [(ngModel)]="selectedYear">
                        <option *ngFor="let year of arrayOne(100); let i = index"
                                [value]="i + 2000">{{ i + 2000 }}</option>
                    </select>
                </div>

                <a (click)="incrementMonth()" class="arrowButton" href="javascript:void(0)"><i
                    class="pi pi-chevron-right"></i></a>
            </div>
            <div class="weekText">
                <div *ngFor="let week of weekData">
                    {{ week.abbr }}
                </div>
            </div>
            <div class="days">
                <div
                    (click)="selectDay(day.day, day.dayOfWeek)"
                    *ngFor="let day of dayData"
                    [attr.aria-disabled]="!day.enabled"
                    [ngClass]="{
                        active:
                            day.day == selectedDate.dayOfMonth &&
                            selectedMonth.id == selectedDate.month &&
                            selectedYear == selectedDate.year.toString(),
                        enabled: day.enabled,
                        disbled: !day.enabled
                    }"
                >
                    {{ day.day }}
                </div>
            </div>
        </div> -->

        <div [ngClass]="calenderClass" [ngStyle]="pickerCalenderStyle" class="pickerCalender" id="pickerCalender">
            <div class="fd-calendar fd-has-display-block fd-calendar--compact" id="fd-calendar-0"
                 [ngStyle]="{'display':this.toDisplay == 'Day' ? 'block':'none'}">
                <div id="fd-calendar-0-header">
                    <header class="fd-calendar__header">
                        <div class="fd-calendar__navigation">
                            <div class="fd-calendar__action fd-calendar__action--arrow-left">
                                <button (click)="decrementMonth()" [compact]="true" aria-label="Previous month"
                                        class="fd-button fd-button--transparent"
                                        fd-button fdtype="transparent"
                                        glyph="slim-arrow-left" id="fd-calendar-0-header-left-arrow"
                                        title="Previous month" type="button">
                                </button>
                            </div>
                            <div class="fd-calendar__action">
                                <button (click)="openMonthPicker()" [attr.data-fd-calendar-month]="currentMonthById"
                                        [compact]="true" [label]="currentMonth"
                                        aria-labelledby="fd-calendar-0-header-month-label fd-calendar-0-header-select-month-aria-label"
                                        class="fd-button fd-button--transparent"

                                        fd-button fdtype="transparent"
                                        type="button">

                                </button>
                            </div>
                            <div class="fd-calendar__action">
                                <button
                                        (click)="openYearPicker()"
                                        [attr.data-fd-calendar-year]="currentYear" [compact]="true"
                                        [label]="currentYear"
                                        aria-labelledby="fd-calendar-0-header-year-label fd-calendar-0-header-select-year-aria-label"
                                        class="fd-button fd-button--transparent"
                                        fd-button fdtype="transparent"
                                        type="button">
                                </button>
                            </div>
                            <div class="fd-calendar__action fd-calendar__action--arrow-right">
                                <button (click)="incrementMonth()" [compact]="true" aria-label="Next month"
                                        class="fd-button fd-button--transparent"
                                        fd-button fdtype="transparent"
                                        glyph="slim-arrow-right" id="fd-calendar-0-header-right-arrow"
                                        title="Next month" type="button">
                                </button>
                            </div>
                        </div>
                    </header>
                </div>
                <div class="fd-calendar__content fd-calendar__content--dates dates">
                    <div class="fd-calendar__dates" id="fd-calendar-0-day-view">
                        <table [compact]="true" aria-roledescription="Calendar" class="fd-calendar__table" fd-table
                               role="grid">
                            <thead class="fd-calendar__row" role="row">
                            <th *ngFor="let week of weekData" [attr.aria-label]="week.name"
                                class="fd-calendar__item fd-calendar__item--side-helper" role="columnheader">
                                <span class="fd-calendar__text">{{week.abbr}}</span>
                            </th>
                            </thead>
                            <tbody class="fd-calendar__group" role="rowgroup">
                            <tr
                                    class="fd-calendar__row" role="row">
                                <td (click)="selectDay(row.day, row.dayOfWeek)"
                                    *ngFor="let row of dayData|slice:0:7; let i = index"
                                    [attr.aria-disabled]="!row.enabled"
                                    [attr.aria-selected]="row.enabled"
                                    [id]="'fd-calendar-0-day-view-day-'+i"
                                    [ngClass]="{
                                        'fd-calendar__item--weekend ':
                                            row.dayOfWeek == 7,
                                            'is-active': row.day == selectedDate.dayOfMonth &&
                                            selectedMonth.id == selectedDate.month &&
                                            selectedYear == selectedDate.year.toString(),
                                            'fd-calendar__item--current': row.day == selectedDate.dayOfMonth &&
                                            selectedMonth.id == selectedDate.month &&
                                            selectedYear == selectedDate.year.toString()
                                    }"

                                    class="fd-calendar__item"
                                    role="gridcell"
                                    tabindex="-1">
                                    <span
                                            aria-labelledby="fd-calendar-0-day-view-day-0-aria-label fd-calendar-0-day-view-day-in-past-label"
                                            class="fd-calendar__text"
                                            role="button">{{row.day}}</span>
                                </td>
                            </tr>
                            <tr

                                    class="fd-calendar__row" role="row">
                                <td (click)="selectDay(row.day, row.dayOfWeek)"
                                    *ngFor="let row of dayData|slice:7:14; let i = index"
                                    [attr.aria-disabled]="!row.enabled"
                                    [attr.aria-selected]="!row.enabled"
                                    [id]="'fd-calendar-0-day-view-day-'+(7+i)"
                                    [ngClass]="{
                                        'fd-calendar__item--weekend ':
                                            row.dayOfWeek == 7,
                                            'is-active': row.day == selectedDate.dayOfMonth &&
                                            selectedMonth.id == selectedDate.month &&
                                            selectedYear == selectedDate.year.toString(),
                                            'fd-calendar__item--current':row.day ==  selectedDate.dayOfMonth &&
                                            selectedMonth.id == selectedDate.month &&
                                            selectedYear == selectedDate.year.toString()
                                    }"
                                    class="fd-calendar__item"
                                    role="gridcell"
                                    tabindex="-1">
                                    <span
                                            aria-labelledby="fd-calendar-0-day-view-day-0-aria-label fd-calendar-0-day-view-day-in-past-label"
                                            class="fd-calendar__text"
                                            role="button">{{row.day}}</span>
                                </td>
                            </tr>
                            <tr

                                    class="fd-calendar__row" role="row">
                                <td (click)="selectDay(row.day, row.dayOfWeek)"
                                    *ngFor="let row of dayData|slice:14:21; let i = index"
                                    [attr.aria-disabled]="!row.enabled"
                                    [attr.aria-selected]="!row.enabled"
                                    [id]="'fd-calendar-0-day-view-day-'+(7+i)"
                                    [ngClass]="{
                                        'fd-calendar__item--weekend ':
                                            row.dayOfWeek == 7,
                                            'is-active': row.day == selectedDate.dayOfMonth &&
                                            selectedMonth.id == selectedDate.month &&
                                            selectedYear == selectedDate.year.toString(),
                                            'fd-calendar__item--current': row.day == selectedDate.dayOfMonth &&
                                            selectedMonth.id == selectedDate.month &&
                                            selectedYear == selectedDate.year.toString()
                                    }"
                                    class="fd-calendar__item"
                                    role="gridcell"
                                    tabindex="-1">
                                    <span
                                            aria-labelledby="fd-calendar-0-day-view-day-0-aria-label fd-calendar-0-day-view-day-in-past-label"
                                            class="fd-calendar__text"
                                            role="button">{{row.day}}</span>
                                </td>
                            </tr>
                            <tr

                                    class="fd-calendar__row" role="row">
                                <td (click)="selectDay(row.day, row.dayOfWeek)"
                                    *ngFor="let row of dayData|slice:21:28; let i = index"
                                    [attr.aria-disabled]="!row.enabled"
                                    [attr.aria-selected]="!row.enabled"
                                    [id]="'fd-calendar-0-day-view-day-'+(7+i)"
                                    [ngClass]="{
                                        'fd-calendar__item--weekend ':
                                            row.dayOfWeek == 7,
                                            'is-active': row.day == selectedDate.dayOfMonth &&
                                            selectedMonth.id == selectedDate.month &&
                                            selectedYear == selectedDate.year.toString(),
                                            'fd-calendar__item--current': row.day == selectedDate.dayOfMonth &&
                                            selectedMonth.id == selectedDate.month &&
                                            selectedYear == selectedDate.year.toString()
                                    }"
                                    class="fd-calendar__item"
                                    role="gridcell"
                                    tabindex="-1">
                                    <span
                                            aria-labelledby="fd-calendar-0-day-view-day-0-aria-label fd-calendar-0-day-view-day-in-past-label"
                                            class="fd-calendar__text"
                                            role="button">{{row.day}}</span>
                                </td>
                            </tr>

                            <tr

                                    class="fd-calendar__row" role="row">
                                <td (click)="selectDay(row.day, row.dayOfWeek)"
                                    *ngFor="let row of dayData|slice:28:35; let i = index"
                                    [attr.aria-disabled]="!row.enabled"
                                    [attr.aria-selected]="!row.enabled"
                                    [id]="'fd-calendar-0-day-view-day-'+(7+i)"
                                    [ngClass]="{
                                        'fd-calendar__item--weekend ':
                                            row.dayOfWeek == 7,
                                            'is-active': row.day == selectedDate.dayOfMonth &&
                                            selectedMonth.id == selectedDate.month &&
                                            selectedYear == selectedDate.year.toString(),
                                            'fd-calendar__item--current': row.day == selectedDate.dayOfMonth &&
                                            selectedMonth.id == selectedDate.month &&
                                            selectedYear == selectedDate.year.toString()
                                    }"
                                    class="fd-calendar__item"
                                    role="gridcell"
                                    tabindex="-1">
                                    <span
                                            aria-labelledby="fd-calendar-0-day-view-day-0-aria-label fd-calendar-0-day-view-day-in-past-label"
                                            class="fd-calendar__text"
                                            role="button">{{row.day}}</span>
                                </td>
                            </tr>
                            <tr

                                    class="fd-calendar__row" role="row">
                                <td (click)="selectDay(row.day, row.dayOfWeek)"
                                    *ngFor="let row of dayData|slice:35:42; let i = index"
                                    [attr.aria-disabled]="!row.enabled"
                                    [attr.aria-selected]="!row.enabled"
                                    [id]="'fd-calendar-0-day-view-day-'+(7+i)"
                                    [ngClass]="{
                                        'fd-calendar__item--weekend ':
                                            row.dayOfWeek == 7,
                                            'is-active': row.day == selectedDate.dayOfMonth &&
                                            selectedMonth.id == selectedDate.month &&
                                            selectedYear == selectedDate.year.toString(),
                                            'fd-calendar__item--current': row.day == selectedDate.dayOfMonth &&
                                            selectedMonth.id == selectedDate.month &&
                                            selectedYear == selectedDate.year.toString()
                                    }"
                                    class="fd-calendar__item"
                                    role="gridcell"
                                    tabindex="-1">
                                    <span
                                            aria-labelledby="fd-calendar-0-day-view-day-0-aria-label fd-calendar-0-day-view-day-in-past-label"
                                            class="fd-calendar__text"
                                            role="button">{{row.day}}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="fd-calendar fd-has-display-block fd-calendar--compact"
                 [ngStyle]="{'display':this.toDisplay == 'Year' ? 'block':'none'}" id="fd-calendar-1">
                <div id="fd-calendar-1-header">
                    <header class="fd-calendar__header">
                        <div class="fd-calendar__navigation">
                            <div class="fd-calendar__action fd-calendar__action--arrow-left">
                                <button [aria-disabled]="false" [compact]="true" aria-label="Previous year"
                                        class="fd-button fd-button--transparent"
                                        fd-button fdtype="transparent"
                                        glyph="slim-arrow-left" id="fd-calendar-0-header-left-arrow"
                                        title="Previous year" type="button">
                                </button>
                            </div>
                            <div class="fd-calendar__action">
                                <button [compact]="true" [label]="fromYear+' - '+ toYear"
                                        class="fd-button fd-button--transparent" fd-button
                                        fdtype="transparent" type="button">

                                </button>
                            </div>

                            <div class="fd-calendar__action fd-calendar__action--arrow-right">
                                <button [aria-disabled]="false" [compact]="true" aria-label="Next year"
                                        class="fd-button fd-button--transparent"
                                        fd-button
                                        fdtype="transparent" glyph="slim-arrow-right"
                                        id="fd-calendar-0-header-right-arrow"
                                        title="Next year" type="button">
                                </button>
                            </div>
                        </div>
                    </header>
                </div>
                <div class="fd-calendar__content fd-calendar__content--years dates">
<!-- year -->
                    <fd-layout-grid>
                        <div [fdLayoutGridCol]="6">
                            <div
                                    [attr.aria-selected]="false"
                                    [attr.data-fd-calendar-year]="fromYear-1"
                                    [attr.data-fd-calendar-year]="fromYear-1"
                                    aria-disabled="true"
                                    class="year-item"
                                    style="opacity: 0.5;pointer-events: none;" tabindex="-1">
                        <span class="fd-calendar__text" role="button">
                          {{fromYear - 1}}
                      </span>
                            </div>
                        </div>
                        <div [fdLayoutGridCol]="6">
                            <div
                                    (click)="changeByYearView(fromYear)"
                                    [attr.aria-selected]="currentFinancialyYearByFromYear"
                                    [attr.data-fd-calendar-year]="fromYear"
                                    [attr.data-fd-calendar-year]="fromYear"
                                    [ngClass]="{
                          'fd-calendar__item--current': currentFinancialyYearByFromYear,
                              'is-active': currentFinancialyYearByFromYear
                      }"
                                    [tabindex]="currentFinancialyYearByFromYear ? '0' : '-1'" class="year-item">
                      <span class="fd-calendar__text" role="button">
                        {{fromYear}}
                    </span>
                            </div>
                        </div>
                        <div [fdLayoutGridCol]="6">
                            <div
                                    (click)="changeByYearView(toYear)"
                                    [attr.aria-selected]="currentFinancialyYearByToYear"
                                    [attr.data-fd-calendar-year]="toYear"
                                    [attr.data-fd-calendar-year]="toYear"
                                    [ngClass]="{
                            'fd-calendar__item--current': currentFinancialyYearByToYear,
                                'is-active': currentFinancialyYearByToYear
                        }"
                                    [tabindex]="currentFinancialyYearByToYear ? '0' : '-1'" class="year-item">
                        <span class="fd-calendar__text" role="button">
                          {{toYear}}
                      </span>
                            </div>
                        </div>
                        <div [fdLayoutGridCol]="6">
                            <div
                                    [attr.aria-disabled]="true"
                                    [attr.aria-selected]="false"
                                    [attr.data-fd-calendar-year]="toYear+1"
                                    [attr.data-fd-calendar-year]="toYear+1"
                                    class="year-item"
                                    style="opacity: 0.5;pointer-events: none;" tabindex="-1">
                        <span class="fd-calendar__text" role="button">
                          {{toYear + 1}}
                      </span>
                            </div>
                        </div>
                        <div [fdLayoutGridCol]="12">
                        <span (click)="openDatePicker()" class="fd-calendar__text back_button" role="button">
                            back
                         </span>
                        </div>

                    </fd-layout-grid>
                </div>
            </div>
            <div class="fd-calendar fd-has-display-block fd-calendar-2 fd-calendar--compact"
                 [ngStyle]="{'display':this.toDisplay == 'Month' ? 'block':'none'}" id="fd-calendar-2">
                <div id="fd-calendar-2-header">
                    <header class="fd-calendar__header">
                        <div class="fd-calendar__navigation">
                            <div class="fd-calendar__action fd-calendar__action--arrow-left">
                                <button [aria-disabled]="true" [compact]="true" aria-label="Previous year"
                                        class="fd-button fd-button--transparent"
                                        fd-button fdtype="transparent"
                                        glyph="slim-arrow-left" id="fd-calendar-0-header-left-arrow"
                                        title="Previous month" type="button">
                                </button>
                            </div>
                            <div class="fd-calendar__action">
                                <button (click)="openDatePicker()"
                                        [compact]="true"
                                        [label]="currentMonth" class="fd-button fd-button--transparent" fd-button
                                        fdtype="transparent" type="button">

                                </button>
                            </div>
                            <div class="fd-calendar__action">
                                <button (click)="openYearPicker()"
                                        [compact]="true" [label]="currentYear" class="fd-button fd-button--transparent"
                                        fd-button
                                        fdtype="transparent" type="button">

                                </button>
                            </div>

                            <div class="fd-calendar__action fd-calendar__action--arrow-right">
                                <button (click)="openDatePicker()" [aria-disabled]="true" [compact]="true"
                                        aria-label="Next year"
                                        class="fd-button fd-button--transparent"
                                        fd-button fdtype="transparent"
                                        glyph="slim-arrow-right"
                                        id="fd-calendar-0-header-right-arrow"
                                        title="Next year" type="button">
                                </button>
                            </div>
                        </div>
                    </header>
                </div>
                <fd-layout-grid class="dates">
                    <div *ngFor="let item of monthData" [fdLayoutGridCol]="4">
                        <div
                                (click)="changeByMonth(item.id)"
                                [attr.aria-selected]="item.id==12"
                                [attr.data-fd-calendar-year]="fromYear"
                                [id]="'fd-calendar-0-month-view-month-'+ item.id"
                                [ngClass]="{
                            'fd-calendar__item--current': item.id == selectedMonthId,
                                'is-active': item.id ===selectedMonthId
                        }" class=" month_item"
                        >
                        <span class="fd-calendar__text" role="button">
                            {{item.name}}
                        </span>

                        </div>
                    </div>
                    <div [fdLayoutGridCol]="4">
                    </div>
                    <div [fdLayoutGridCol]="4">
                        <span (click)="openDatePicker()" class="fd-calendar__text back_button" role="button">
                            back
                         </span>
                    </div>
                </fd-layout-grid>
            </div>
        </div>


        <div class="enCalendar" id="npCalendar">
            <fd-calendar (ngModelChange)="fdDateChanges($event)" [(ngModel)]="date" [compact]="true"
                         [disableFunction]="myDisableFunction2" calType="single"></fd-calendar>
        </div>
    </div>
</div>
