import {Injectable} from '@angular/core';
import {WidgetFilterViewDefinition, WidgetViewDefinition} from './definitions';
import {DashboardCustomizationConst} from './DashboardCustomizationConsts';
import {WidgetGeneralStatsComponent} from './widgets/widget-general-stats/widget-general-stats.component';
import {WidgetDailySalesComponent} from './widgets/widget-daily-sales/widget-daily-sales.component';
import {WidgetProfitShareComponent} from './widgets/widget-profit-share/widget-profit-share.component';
import {WidgetMemberActivityComponent} from './widgets/widget-member-activity/widget-member-activity.component';
import {WidgetRegionalStatsComponent} from './widgets/widget-regional-stats/widget-regional-stats.component';
import {WidgetSalesSummaryComponent} from './widgets/widget-sales-summary/widget-sales-summary.component';
import {WidgetIncomeStatisticsComponent} from './widgets/widget-income-statistics/widget-income-statistics.component';
import {WidgetRecentTenantsComponent} from './widgets/widget-recent-tenants/widget-recent-tenants.component';
import {
    WidgetEditionStatisticsComponent
} from './widgets/widget-edition-statistics/widget-edition-statistics.component';
import {
    WidgetSubscriptionExpiringTenantsComponent
} from './widgets/widget-subscription-expiring-tenants/widget-subscription-expiring-tenants.component';
import {WidgetHostTopStatsComponent} from './widgets/widget-host-top-stats/widget-host-top-stats.component';
import {WidgetTopStatsComponent} from './widgets/widget-top-stats/widget-top-stats.component';
import {WidgetTodayPaymentComponent} from './widgets/widget-today-payment/widget-today-payment.component';
import {WidgetsInterestComponent} from './widgets/widgets-interest/widgets-interest.component';
import {
    WidgetsLocationWidgetStockComponent
} from './widgets/widgets-location-widget-stock/widgets-location-widget-stock.component';
import {WidgetsProductMovementComponent} from './widgets/widgets-product-movement/widgets-product-movement.component';
import {
    WidgetsProfitProductStatisticsComponent
} from './widgets/widgets-profit-product-statistics/widgets-profit-product-statistics.component';
import {WidgetsStockTransferComponent} from './widgets/widgets-stock-transfer/widgets-stock-transfer.component';
import {
    WidgetTopSallingItemsBySalesAmountComponent
} from './widgets/widget-top-salling-items-by-sales-amount/widget-top-salling-items-by-sales-amount.component';
import {WidgetInventoryValueComponent} from './widgets/widget-inventory-value/widget-inventory-value.component';
import {
    WidgetOpportunitiesInleadStatusComponent
} from './widgets/widget-opportunities-inlead-status/widget-opportunities-inlead-status.component';
import {WidgetPayableOverdueComponent} from './widgets/widget-payable-overdue/widget-payable-overdue.component';
import {
    WidgetTopCustomerBySalesAmountComponent
} from './widgets/widget-top-customer-by-sales-amount/widget-top-customer-by-sales-amount.component';
import {
    WidgetSalesOrderAmountThisMonthComponent
} from './widgets/widget-sales-order-amount-this-month/widget-sales-order-amount-this-month.component';
import {
    WidgetTopVendorBySalesAmountComponent
} from './widgets/widget-top-vendor-by-sales-amount/widget-top-vendor-by-sales-amount.component';
import {WidgetTodayReceiveComponent} from './widgets/widget-today-receive/widget-today-receive.component';
import {WidgetPurchaseSummaryComponent} from './widgets/widget-purchase-summary/widget-purchase-summary.component';
import {WidgetCompanyFinanceComponent} from './widgets/widget-company-finance/widget-company-finance.component';
import {WidgetMeetingScheduleComponent} from './widgets/widget-meeting-schedule/widget-meeting-schedule.component';
import {WidgetServicePendingComponent} from './widgets/widget-service-pending/widget-service-pending.component';
import {WidgetSoftwareExpiredComponent} from './widgets/widget-software-expired/widget-software-expired.component';
import {WidgetStockMomentComponent} from './widgets/widget-stock-moment/widget-stock-moment.component';
import {WidgetTopFiveSalesComponent} from './widgets/widget-top-five-sales/widget-top-five-sales.component';
import {WidgetTodayReceiptComponent} from './widgets/widget-today-receipt/widget-today-receipt.component';
import {SapDateRangePickerComponent} from './filters/sap-date-range-picker/sap-date-range-picker.component';
import {WidgetTodayServiceComponent} from './widgets/widget-today-service/widget-today-service.component';
import {
    WidgetTodayDeliverynoteComponent
} from './widgets/widget-today-deliverynote/widget-today-deliverynote.component';
import {WidgetTodayTaskComponent} from './widgets/widget-today-task/widget-today-task.component';

@Injectable({
    providedIn: 'root'
})
export class DashboardViewConfigurationService {
    public WidgetViewDefinitions: WidgetViewDefinition[] = [];
    public widgetFilterDefinitions: WidgetFilterViewDefinition[] = [];

    constructor() {
        this.initializeConfiguration();
    }

    private initializeConfiguration() {
        let filterDateRangePicker = new WidgetFilterViewDefinition(
            DashboardCustomizationConst.filters.filterDateRangePicker,
            SapDateRangePickerComponent
        );
        //add your filters here
        this.widgetFilterDefinitions.push(filterDateRangePicker);

        let generalStats = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.generalStats,
            WidgetGeneralStatsComponent
        );

        let dailySales = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.dailySales,
            WidgetDailySalesComponent
        );


        let todayPayment = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.todayPayment,
            WidgetTodayPaymentComponent
        );

        let interest = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.interest,
            WidgetsInterestComponent
        );

        let purchaseSummary = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.purchaseSummary,
            WidgetPurchaseSummaryComponent
        );


        let todayReceive = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.todayReceive,
            WidgetTodayReceiveComponent
        );

        let topFiveSales = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.topFiveSales,
            WidgetTopFiveSalesComponent
        );


        let todayReceipt = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.todayReceipt,
            WidgetTodayReceiptComponent
        );

        let todayService = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.todayService,
            WidgetTodayServiceComponent
        );
        let todayDeliverynote = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.DeliveryNotePending,
            WidgetTodayDeliverynoteComponent
        );
        let todayTask = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.TodayTask,
            WidgetTodayTaskComponent
        );

        let stockTransfer = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.stockTransfer,
            WidgetsStockTransferComponent
        );
        let productMovement = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.productMovement,
            WidgetsProductMovementComponent
        );
        let locationWidgetStock = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.locationWidgetStock,
            WidgetsLocationWidgetStockComponent
        );
        let profitProductStatistics = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.profitProductStatistics,
            WidgetsProfitProductStatisticsComponent
        );
        let topSallingItemBySalesAmount = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.topSallingItemBySalesAmount,
            WidgetTopSallingItemsBySalesAmountComponent
        );
        let topCustomerBySalesAmount = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.topCustomerBySalesAmount,
            WidgetTopCustomerBySalesAmountComponent
        );

        let opportunitiesInLeadStatus = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.opportunitiesInLeadStatus,
            WidgetOpportunitiesInleadStatusComponent
        );
        let inventoryValue = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.inventoryValue,
            WidgetInventoryValueComponent
        );
        let payableOverdue = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.payableOverdue,
            WidgetPayableOverdueComponent
        );
        let topVendorBySalesAmount = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.topVendorBySalesAmount,
            WidgetTopVendorBySalesAmountComponent
        );
        let salesOrderAmountThisMonth = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.salesOrderAmountThisMonth,
            WidgetSalesOrderAmountThisMonthComponent
        );

        let profitShare = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.profitShare,
            WidgetProfitShareComponent
        );

        let memberActivity = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.memberActivity,
            WidgetMemberActivityComponent
        );

        let regionalStats = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.regionalStats,
            WidgetRegionalStatsComponent
        );

        let salesSummary = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.salesSummary,
            WidgetSalesSummaryComponent
        );

        let companyFinance = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.companyFinance,
            WidgetCompanyFinanceComponent
        );

        let meetingSchedule = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.meetingSchedule,
            WidgetMeetingScheduleComponent
        );

        let servicePending = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.servicePending,
            WidgetServicePendingComponent
        );

        let softwareExpired = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.softwareExpired,
            WidgetSoftwareExpiredComponent
        );

        let stockMoment = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.stockMoment,
            WidgetStockMomentComponent
        );

        let topStats = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.topStats,
            WidgetTopStatsComponent
        );
        //add your tenant side widgets here

        let incomeStatistics = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.incomeStatistics,
            WidgetIncomeStatisticsComponent
        );

        let editionStatistics = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.editionStatistics,
            WidgetEditionStatisticsComponent
        );

        let recentTenants = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.recentTenants,
            WidgetRecentTenantsComponent
        );

        let subscriptionExpiringTenants = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.subscriptionExpiringTenants,
            WidgetSubscriptionExpiringTenantsComponent
        );

        let hostTopStats = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.topStats,
            WidgetHostTopStatsComponent
        );
        //add your host side widgets here

        this.WidgetViewDefinitions.push(todayPayment);
        this.WidgetViewDefinitions.push(todayReceipt);
        this.WidgetViewDefinitions.push(todayService);
        this.WidgetViewDefinitions.push(todayDeliverynote);
        this.WidgetViewDefinitions.push(todayTask);
        this.WidgetViewDefinitions.push(topFiveSales);
        this.WidgetViewDefinitions.push(generalStats);
        this.WidgetViewDefinitions.push(locationWidgetStock);
        this.WidgetViewDefinitions.push(interest);
        this.WidgetViewDefinitions.push(companyFinance);
        this.WidgetViewDefinitions.push(meetingSchedule);
        this.WidgetViewDefinitions.push(servicePending);
        this.WidgetViewDefinitions.push(softwareExpired);
        this.WidgetViewDefinitions.push(stockMoment);
        this.WidgetViewDefinitions.push(todayReceive);
        this.WidgetViewDefinitions.push(purchaseSummary);
        this.WidgetViewDefinitions.push(stockTransfer);
        this.WidgetViewDefinitions.push(productMovement);
        this.WidgetViewDefinitions.push(profitProductStatistics);
        this.WidgetViewDefinitions.push(topSallingItemBySalesAmount);
        this.WidgetViewDefinitions.push(topCustomerBySalesAmount);
        this.WidgetViewDefinitions.push(opportunitiesInLeadStatus);
        this.WidgetViewDefinitions.push(inventoryValue);
        this.WidgetViewDefinitions.push(payableOverdue);
        this.WidgetViewDefinitions.push(topVendorBySalesAmount);
        this.WidgetViewDefinitions.push(salesOrderAmountThisMonth);
        this.WidgetViewDefinitions.push(dailySales);
        this.WidgetViewDefinitions.push(profitShare);
        this.WidgetViewDefinitions.push(memberActivity);
        this.WidgetViewDefinitions.push(regionalStats);
        this.WidgetViewDefinitions.push(salesSummary);
        this.WidgetViewDefinitions.push(topStats);
        this.WidgetViewDefinitions.push(incomeStatistics);
        this.WidgetViewDefinitions.push(editionStatistics);
        this.WidgetViewDefinitions.push(recentTenants);
        this.WidgetViewDefinitions.push(subscriptionExpiringTenants);
        this.WidgetViewDefinitions.push(hostTopStats);
    }
}


